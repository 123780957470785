import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import { useParams } from "react-router-dom";

const useGetSingleTask = () => {
    const dispatch = useAppDispatch();
    const { taskId } = useParams();

    const {
        fetchingSingleTask,
        fetchSingleTaskSuccess,
        fetchSingleTaskFailure,
        // approveTaskStepSuccess
        // updateTaskSalesOrderSuccess,
        // retryTaskStepSuccess,
    } = useAppSelector((state) => state.workforce);

    const [taskDetail, setTaskDetail] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        dispatch(workforceActions.getSingleTask(taskId));
    }, [
        dispatch,
        taskId,
        // updateTaskSalesOrderSuccess,
        // approveTaskStepSuccess
        // retryTaskStepSuccess,
    ]);

    useEffect(() => {
        if (Boolean(fetchSingleTaskSuccess)) {
            setTaskDetail(fetchSingleTaskSuccess);
        }
    }, [fetchSingleTaskSuccess]);

    return {
        isFetching: fetchingSingleTask,
        error: fetchSingleTaskFailure,
        data: taskDetail,
        setTaskDetail,
    };
};

export default useGetSingleTask;
