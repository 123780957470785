/* eslint-disable */
import { Editor } from "@tinymce/tinymce-react";
import Button from "components/common/Button";
import EmailInput from "components/common/InputField/EmailInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import ModalContainer from "components/common/ModalContainer";
import PurchaseOrderDetails from "components/views/Ldb/Orders/PurchaseOrderDetails";
import { displayFileIcon, formatMoney, getFromStorage, hasHTMLTag } from "helpers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { inventoryActions, orderActions, vendorActions } from "redux/Ldb/actions";
import { firebaseService } from "services/firebaseService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce, truncate } from "lodash";
import { toast } from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import VendorAvatar from "components/common/VendorAvatar";
import CustomTable from "components/common/CustomTable";
import config from "config/config";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import Loader from "components/common/Loader";
import PageFrame from "components/layout/PageFrame";
import useGetSingleInventoryIncidents from "hooks/inventoryHooks/useGetSingleInventoryIncidents";
import { useNav } from "components/common/Navbar/NavContext";
import { Tooltip } from "react-tooltip";

interface File {
    fileName: string;
    fileUrl: string;
    size?: string;
}
const CreateOrder = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [responses, setResponses] = useState([]);
    const [vendorResponses, setVendorResponses] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [showCancelSign, setShowCancelSign] = useState(false);
    const [showSendNow, setShowSendNow] = useState(false);
    const [uploadingPdf, setUploadingPdf] = useState(false);
    const [showEditor, setShowEditor] = useState(true);
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(true);
    const [addShippingVia, setAddShippingVia] = useState(false);
    const [addPaymentOption, setAddPaymentOption] = useState(false);
    const [addShippingTerms, setAddShippingTerms] = useState(false);
    const [emails, setEmails] = useState([]);
    const [success, setSuccess] = useState(false);
    const [saveBlob, setSaveBlob] = useState<any>([]);
    const [productList, setProductList] = useState([]);
    const [showSuccessPO, setShowSuccessPO] = useState(false);
    const [uploadingDoc, setUploadingDoc] = useState(false);
    const [quoteTaId, setQuoteTaId] = useState("");
    const [quoteVendor, setQuoteVendor] = useState<any>({});
    const [purchaseOrderId, setPurchaseOrderId] = useState("");
    const [productSearch, setProductSearch] = useState("");
    const [pdfUrl, setPdfUrl] = useState("");
    const [inventoryData, setInventoryData] = useState<any>({});
    const [filesAttached, setFilesAttached] = useState<File[]>([]);
    const [newShippingOption, setNewShippingOption] = useState("");
    const [newPaymentOption, setNewPaymentOption] = useState("");
    const [newShippingTermsOption, setNewShippingTermsOption] = useState("");
    const [selectedItems, setSelectedItems] = useState<{ [key: string]: any }[]>([]);
    const [productItems, setProductItems] = useState<{ [key: string]: any }[]>([
        {
            productName: "",
            inventoryId: "",
            quantity: "",
            unitCostAmount: "",
            unitCostCurrency: "",
            subtotal: {
                amount: "",
            },
            deliveryDate: "",
            leadTime: "",
        },
    ]);

    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });
    const [vendorSearch, setVendorSearch] = useState("");
    const [settingsDetails, setSettingsDetails] = useState<any>([]);
    const [customerDetails, setCustomerDetails] = useState<{ [key: string]: any }>({
        issuedDate: "",
        dueDate: "",
        postalCode: "",
        country: "",
        city: "",
        source: "supplier",
        tax: "",
        shippingAndHandling: "",
        termsAndCondition: "",
    });
    const [emailDetails, setEmailDetails] = useState({
        sender: userProfile?.user?.email,
        title: "",
    });
    const [containers, setContainers] = useState([]);
    const [selectedInventories, setSelectedInventories] = useState([]);
    const [shippingData, setShippingData] = useState([
        "Fed EX",
        "UPS",
        "LTL",
        "FTL",
        "Will Call",
        "USPS",
        "Amazon",
    ]);
    const [shippingTermsData, setShippingTermsData] = useState([
        "Freight collect",
        "Pre-paid",
        "No charge",
    ]);
    const [paymentTermsData, setPaymentTermsData] = useState([
        "Due on receipt",
        "Net 10 days",
        "Net 15 days",
        "Net 30 days",
        "Net 60 days",
        "prepay",
        "50% down 50% on shipping",
    ]);
    const {
        createPurchaseOrder,
        createPurchaseOrderSuccess,
        sendPurchaseOrder,
        fetchedApprovedResponsesSuccess,
        fetchingApprovedResponses,
        sendPurchaseOrderSuccess,
        createPurchaseOrderFailure,
    } = useAppSelector((state) => state.order);

    const { fetchedPOSettingsSuccess, fetchingPOSettings } = useAppSelector((state) => state.order);
    const { fetchedLdbVendorsSuccess, fetchingLdbVendor } = useAppSelector((state) => state.vendor);
    const {
        fetchedInventoryListSuccess,
        fetchingInventoryList,
        fetchedSingleInventorySuccess,
        fetchingSingleInventory,
    } = useAppSelector((state) => state.inventory);
    const [param] = useSearchParams();
    const incidentId = param.get("incidentId");
    const inventoryId = param.get("inventory");
    const { setNavName, setOnClick } = useNav();

    useEffect(() => {
        setNavName("Create Purchase");
        setOnClick(() => () => {
            navigate(-1);
        });
    }, [setNavName, setOnClick]);

    const { data, isFetching, pagination, isLoadingMore } =
        useGetSingleInventoryIncidents(incidentId);

    useEffect(() => {
        dispatch(orderActions.getPOSettings());
    }, [dispatch]);

    useEffect(() => {
        dispatch(vendorActions.getLdbVendors(20, vendorSearch, "", "offline"));
    }, [dispatch, vendorSearch]);
    useEffect(() => {
        if (inventoryId) {
            dispatch(inventoryActions.fetchSingleInventory(inventoryId));
        }
    }, [dispatch, inventoryId]);

    useEffect(() => {
        dispatch(inventoryActions.fetchInventoryList(20, 1, debouncedSearch?.search));
    }, [dispatch, debouncedSearch?.search]);

    // useEffect(() => {
    //     dispatch(orderActions.getApprovedResponses(20, debouncedSearch?.search));
    // }, [dispatch, debouncedSearch?.search]);

    useEffect(() => {
        if (Boolean(fetchedSingleInventorySuccess && inventoryId)) {
            setInventoryData(fetchedSingleInventorySuccess?.inventory);
        }
    }, [fetchedSingleInventorySuccess, inventoryId]);

    useEffect(() => {
        if (Boolean(fetchedApprovedResponsesSuccess)) {
            setResponses(fetchedApprovedResponsesSuccess?.quoteResponses);
        }
    }, [fetchedApprovedResponsesSuccess]);

    useEffect(() => {
        if (Boolean(fetchedInventoryListSuccess)) {
            setProductList(fetchedInventoryListSuccess?.inventory?.data);
        }
    }, [fetchedInventoryListSuccess]);

    useEffect(() => {
        if (Boolean(fetchedPOSettingsSuccess)) {
            setSettingsDetails(fetchedPOSettingsSuccess?.setings);
        }
    }, [fetchedPOSettingsSuccess]);
    useEffect(() => {
        if (Boolean(fetchedLdbVendorsSuccess)) {
            setVendorResponses(fetchedLdbVendorsSuccess);
        }
    }, [fetchedLdbVendorsSuccess]);
    const todayDate = new Date();

    const today = new Date().toISOString().slice(0, 10);
    document.getElementsByName("dueDate")[0]?.setAttribute("min", today);
    document.getElementsByName("issuedDate")[0]?.setAttribute("min", today);
    document.getElementsByName("deliveryDate")[0]?.setAttribute("min", today);

    function handleMultipleItemChange(name: string, value: any, idx: number) {
        const newArr: { [key: string]: any }[] = [...containers];
        if (name === "deliveryDate") {
            const delivery = new Date(value);
            const differenceInMilliseconds = delivery.getTime() - todayDate.getTime();
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            newArr[idx].leadTime = `${differenceInDays + 1} days`;
        }
        if (name === "unitCost") {
            const qty = parseFloat(newArr[idx].quantity || "0");
            const amount: any = value === "" ? "" : parseFloat(value);
            newArr[idx].unitCostAmount = amount || "";
            newArr[idx].subtotal.amount = qty * amount || 0;
            // const qty = parseFloat(newArr[idx].quantity);
            // const price = parseFloat(value);
            // newArr[idx].unitCost.amount = String(price);
            // newArr[idx].subtotal.amount = qty * price;
        }
        if (name === "quantity") {
            const price = parseFloat(newArr[idx].unitCostAmount);
            const quantity = parseFloat(value);
            newArr[idx].quantity = String(value);
            newArr[idx].subtotal.amount = Number(quantity) * Number(price);
        } else {
            newArr[idx][name] = value;
        }
        setContainers(newArr);
    }
    function handleItemChange(name: string, value: any, idx: number) {
        const newArr: { [key: string]: any }[] = [...productItems];
        if (name === "deliveryDate") {
            const delivery = new Date(value);
            const differenceInMilliseconds = delivery.getTime() - todayDate.getTime();
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            newArr[idx].leadTime = `${differenceInDays + 1} days`;
        }
        if (name === "unitCostAmount") {
            const qty = parseFloat(newArr[idx].quantity || "0");
            const amount: any = value === "" ? "" : parseFloat(value);
            newArr[idx].unitCostAmount = amount || "";
            newArr[idx].subtotal.amount = qty * amount || 0;
        }
        if (name === "quantity") {
            const price = parseFloat(newArr[idx].unitCostAmount);
            const quantity = parseFloat(value);
            newArr[idx].quantity = String(value);
            newArr[idx].subtotal.amount = Number(quantity) * Number(price);
        } else {
            newArr[idx][name] = value;
        }
        setProductItems(newArr);
    }
    useEffect(() => {
        setProductItems([
            {
                productName: inventoryData?.productName,
                sku: inventoryData?.sku,
                inventoryId: inventoryData?._id,
                quantity: (
                    inventoryData?.replenishment || inventoryData?.inventoryIncident?.qty
                )?.toFixed(0),
                unitCostAmount: inventoryData?.unitCost?.amount,
                unitCostCurrency: inventoryData?.unitCost?.currency,
                subtotal: {
                    amount: "",
                },
                deliveryDate: "",
                leadTime: "",
            },
        ]);
    }, [inventoryData]);

    useEffect(() => {
        if (data) {
            setProductItems([
                {
                    productName: data?.inventory?.productName,
                    sku: data?.inventory?.sku,
                    inventoryId: data?.inventory?._id,
                    quantity: data?.qty?.toFixed(0),
                    unitCostAmount: data?.inventory?.unitCost?.amount?.toFixed(0),
                    unitCostCurrency: data?.inventory?.unitCost?.currency,
                    subtotal: {
                        amount:
                            data?.qty?.toFixed(0) * data?.inventory?.unitCost?.amount?.toFixed(0),
                    },
                    deliveryDate: "",
                    leadTime: "",
                },
            ]);
        }
    }, [dispatch, data]);

    function handleDelete(index: number) {
        setProductItems((prevProductItems) => {
            const updatedItems = prevProductItems.filter((_, idx) => idx !== index);
            if (updatedItems.length === 0) {
                return [
                    {
                        productName: "",
                        inventoryId: "",
                        quantity: "",
                        unitCost: {
                            amount: "",
                            currency: "",
                        },
                        subtotal: {
                            amount: "",
                        },
                        deliveryDate: "",
                        leadTime: "",
                    },
                ];
            }

            return updatedItems;
        });
    }

    // function handleDeleteProduct(index: number) {
    //     setContainers((prevContainers) => {
    //         const updatedContainers = prevContainers.filter((_, idx) => idx !== index);
    //         if (updatedContainers.length === 0) {
    //             return [{
    //                 productName: "",
    //                 inventoryId: "",
    //                 quantity: "",
    //                 unitCost: {
    //                     amount: "",
    //                     currency: "",
    //                 },
    //                 subtotal: {
    //                     amount: "",
    //                 },
    //                 deliveryDate: "",
    //                 leadTime: "",
    //             }];
    //         }

    //         return updatedContainers;
    //     });
    // }
    const quantities = selectedItems.map((item) => Number(item.quantity));

    const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0);

    const productQuntities = productItems?.map((item) => Number(item.quantity));

    const totalProductQuantity = productQuntities.reduce((acc, curr) => acc + curr, 0);

    useEffect(() => {
        if (selectedInventories) {
            const mappedContainers = selectedInventories.map((inventory) => ({
                inventoryId: inventory?.inventory?._id,
                productName: inventory?.inventory?.productName,
                description: inventory?.description,
                sku: inventory?.inventory?.sku,
                quantity: inventory?.quantity,
                unitCostAmount: inventory?.amount?.amount,
                unitCostCurrency: inventory?.amount?.currency,
                subtotal: {
                    amount: inventory?.amount?.amount * inventory?.quantity,
                    currency: inventory?.amount?.currency,
                },
                deliveryDate: "",
                leadTime: "",
            }));
            setContainers(mappedContainers);
        } else {
            setContainers([]);
        }
    }, [selectedInventories]);

    const handleSelectAllItems = (deselect?: boolean) => {
        setSelectedItems(() => (deselect ? [] : containers));
    };

    const handleSelectEachItem = (itemSelected: { [key: string]: any }) => {
        const selectedItemsCopy = [...selectedItems];
        const idx = selectedItemsCopy?.findIndex(
            (item) => item?.inventoryId === itemSelected?.inventoryId
        );

        if (idx !== -1) {
            selectedItemsCopy.splice(idx, 1);
        } else {
            selectedItemsCopy.push(itemSelected);
        }
        setSelectedItems(selectedItemsCopy);
    };

    const tableHeader = [
        {
            title: "No",
            widthClass: "w-[10%]",
            onSelectAll: () => {
                handleSelectAllItems(selectedItems?.length > 0);
            },
            isAllChecked: selectedItems?.length === containers?.length,
        },
        { title: "Product name", widthClass: "w-[40%]" },
        { title: "Description", widthClass: "w-[30%]" },
        { title: "Qty", widthClass: "w-[11.6%]" },
        { title: "Currency", widthClass: "w-[16.5%]" },
        { title: "Unit cost", widthClass: "w-[16.5%]" },
        { title: "Sub total", widthClass: "w-[11.6%]" },
        { title: "Delivery Date", widthClass: "w-[14%]" },
        { title: "Lead time", widthClass: "w-[11.6%]" },
    ];
    const notQuoteTableHeader = [
        {
            title: "No",
            widthClass: "w-[6%]",
        },
        { title: "Product name", widthClass: "w-[40%]" },
        { title: "SKU", widthClass: "w-[13%]" },
        { title: "Qty", widthClass: "w-[20.6%]" },
        { title: "Currency", widthClass: "w-[14.5%]" },
        { title: "Unit cost", widthClass: "w-[16.5%]" },
        { title: "Sub total", widthClass: "w-[20.6%]" },
        { title: "Delivery Date", widthClass: "w-[17%]" },
        { title: "Lead time", widthClass: "w-[11.6%]" },
        { title: "", widthClass: "w-[6%]" },
    ];

    const handleAddProduct = () => {
        const newProductItem = {
            inventoryId: "",
            quantity: "",
            unitCost: {
                amount: "",
                currency: "",
            },
            subtotal: {
                amount: "",
            },
            deliveryDate: "",
            leadTime: "",
        };
        setProductItems((prevState) => [...prevState, newProductItem]);
    };

    const notQuotetableBody = productItems?.map((item, idx) => {
        return [
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `} key={idx}>
                        <p>{idx + 1}</p>
                    </div>
                ),
                tableDataDivClass: "!px-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                    >
                        <div className="font-rocGroteskMedium pl-3  items-center flex space-x-2">
                            <SelectInput
                                value={item?.productName || ""}
                                name="inventoryId"
                                inputPlaceholder={"Select"}
                                handleChange={(name, value) => {
                                    if (value) {
                                        setProductItems((prevSelectedItems) => {
                                            const updatedItems = [...prevSelectedItems];
                                            const selectedItem = productList.find(
                                                (item) => item._id === value
                                            );

                                            if (selectedItem) {
                                                updatedItems[idx] = {
                                                    ...updatedItems[idx],
                                                    unitCostAmount: selectedItem?.unitCost?.amount,
                                                    unitCostCurrency:
                                                        selectedItem?.unitCost?.currency,
                                                    sku: selectedItem.sku,
                                                    productName: selectedItem.productName,
                                                    inventoryId: selectedItem._id,
                                                    inventoryCategory:
                                                        selectedItem?.inventoryCategory,
                                                };
                                            }
                                            return updatedItems;
                                        });
                                    }
                                }}
                                handleInputChange={debounce((evt) => {
                                    setDebouncedSearch({
                                        search: evt.target.value,
                                    });
                                }, 800)}
                                isRequired={true}
                                idx={idx}
                                className="!border-0 !rounded-none"
                                inputContainerClassName="!mb-0"
                                searchLoading={fetchingInventoryList}
                                clearValue={true}
                                dropdownOptions={productList?.map((item) => ({
                                    label: (
                                        <div className="flex gap-3 items-center">
                                            <div className="flex gap flex-col">
                                                <p className="font-rocGroteskMedium">
                                                    {item?.productName}
                                                </p>
                                            </div>
                                        </div>
                                    ),
                                    value: `${item?._id}`,
                                }))}
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-[0]",
            },

            {
                content: (
                    <div className={`w-full !pl-2 border-slate-100 text-sm text-slate-700 `}>
                        <div>
                            <a id={`sku-needs-column-${idx}`}>
                                <span>{truncate(item?.sku, { length: 10 })}</span>
                            </a>
                            <Tooltip
                                anchorSelect={`#sku-needs-column-${idx}`}
                                place={"top"}
                                content={item?.sku}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-1",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.quantity ?? ""}
                            name={"quantity"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            onChange={(e) => {
                                handleItemChange(e.target.name, e.target.value, idx);
                            }}
                            inputPlaceholder="0"
                            step={
                                item?.inventoryCategory?.toLowerCase()?.startsWith("raw material")
                                    ? "any"
                                    : ""
                            }
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.unitCostCurrency ?? "USD"}
                            name={"currency"}
                            type={"text"}
                            required={true}
                            onChange={(e) => {}}
                            disabled={true}
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.unitCostAmount ?? 0}
                            name={"unitCostAmount"}
                            type={"number"}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            inputPlaceholder="0"
                            onChange={(e) => {
                                handleItemChange(e.target.name, e.target.value, idx);
                            }}
                            required
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <p>{formatMoney().format(item?.subtotal?.amount || 0)}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.deliveryDate}
                            name={"deliveryDate"}
                            type={"date"}
                            required={true}
                            onChange={(e) => {
                                handleItemChange(e.target.name, e.target.value, idx);
                            }}
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <p>{item?.leadTime}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-slate-700 relative`}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(idx);
                        }}
                    >
                        <i className="ri-delete-bin-line text-slate-500"></i>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });

    const tableBody = containers?.map((item, idx) => {
        const isChecked = selectedItems?.find((data) => data?.inventoryId === item.inventoryId);
        return [
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `} key={idx}>
                        <p>{idx + 1}</p>
                    </div>
                ),
                isItemChecked: Boolean(isChecked),
                onCheckBoxClick: () => {
                    handleSelectEachItem(item);
                },
                tableDataDivClass: "!px-3",
            },
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full    border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                    >
                        <div className="font-rocGroteskMedium pl-3  items-center flex space-x-2">
                            <VendorAvatar
                                imageSrc={item.prodcutImageDetails?.avatar}
                                size={24}
                                containerClassname="!rounded-[4px]"
                                name={item.productName}
                            />

                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-gm-50 ">{item.productName}</p>
                                <p className="text-slate-500 ">{item.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-[0]",
            },

            {
                content: (
                    <div className={`w-full !pl-2 border-slate-100 text-sm text-slate-700 `}>
                        <p>{truncate(item?.description, { length: 29 })}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-1",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.quantity ?? ""}
                            name={"quantity"}
                            type={"number"}
                            disabled={!isChecked}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            onChange={(e) => {
                                handleMultipleItemChange(e.target.name, e.target.value, idx);
                            }}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.unitCostCurrency ?? ""}
                            name={"currency"}
                            type={"text"}
                            required={true}
                            onChange={(e) => {}}
                            disabled={true}
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.unitCostAmount ?? ""}
                            name={"unitCost"}
                            type={"number"}
                            inputPlaceholder="0"
                            onChange={(e) => {
                                handleMultipleItemChange(e.target.name, e.target.value, idx);
                            }}
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.subtotal?.amount ?? ""}
                            name={"subtotal"}
                            type={"number"}
                            required={true}
                            onChange={(e) => {
                                if (idx > selectedInventories?.length - 1) {
                                    handleMultipleItemChange(e.target.name, e.target.value, idx);
                                } else {
                                    console.log("");
                                }
                            }}
                            disabled={idx <= selectedInventories?.length - 1}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.deliveryDate}
                            name={"deliveryDate"}
                            type={"date"}
                            required={true}
                            disabled={!isChecked}
                            onChange={(e) => {
                                handleMultipleItemChange(e.target.name, e.target.value, idx);
                            }}
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <p>{item?.leadTime}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-slate-700 relative`}
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleDeleteProduct(idx)
                        }}
                    >
                        <i className="ri-delete-bin-line text-slate-500"></i>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleUpload = (fileName, file) => {
        setUploadingDoc(true);
        firebaseService.uploadFile(
            `tradeally/${process.env.REACT_APP_STAGE}/purchase-order-docs/${fileName}`,
            file,
            (url) => {
                setFilesAttached((prevFiles) => [
                    ...prevFiles,
                    {
                        fileName: fileName,
                        fileUrl: url,
                    },
                ]);
                setUploadingDoc(false);
            }
        );
    };

    const calculateTotals = () => {
        let totalSubtotals = 0;
        if (customerDetails?.source?.toLowerCase() === "quote") {
            for (const container of selectedItems) {
                const subtotal = parseFloat(container?.subtotal?.amount);
                totalSubtotals += subtotal;
            }
        } else {
            for (const container of productItems) {
                const subtotal = parseFloat(container?.subtotal?.amount);
                totalSubtotals += subtotal;
            }
        }
        const taxPercentage = parseFloat(customerDetails.tax);
        const taxAmount = (taxPercentage / 100) * totalSubtotals;
        return {
            totalSubtotals,
            taxAmount,
        };
    };

    const { totalSubtotals, taxAmount } = calculateTotals();

    const handleUploadPdf = (fileName, file) => {
        setUploadingPdf(true);
        firebaseService.uploadFile(`tradeally/staging/document/${fileName}`, file, (url) => {
            setPdfUrl(url);
        });
    };

    useEffect(() => {
        if (pdfUrl !== "") {
            handleSubmit();
        }
    }, [pdfUrl]);
    useEffect(() => {
        if (saveBlob?.name?.toLowerCase() === "purchase-order" && showPreview) {
            handleUploadPdf("purchase-order", saveBlob);
        }
    }, [saveBlob, showPreview]);

    const handleSubmit = () => {
        const body: { [key: string]: any } = {
            issuedDate: moment(customerDetails.issuedDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
            dueDate: moment(customerDetails.dueDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
            fobPoint: customerDetails?.fobPoint,
            shippingMethod: customerDetails?.shippingVia,
            shippingTerms: customerDetails.shippingTerms,
            paymentTerms: customerDetails?.paymentTerms,
            documents: [
                {
                    filename: "purchase-order-doc",
                    path: pdfUrl,
                    documentType: "pdf",
                },
            ],
            attachments: filesAttached?.map((doc) => ({
                filename: doc?.fileName,
                path: doc?.fileUrl,
                documentType: "pdf",
            })),
            source: incidentId ? "incident" : customerDetails?.source?.toLowerCase(),
            incoterm: customerDetails.incoterm,
            vendorId: customerDetails?.supplier,
            itemsOrServices: productItems?.map((data) => ({
                name: data?.productName,
                qty: Number(data?.quantity)?.toFixed(2),
                price: {
                    amount: Number(data?.unitCostAmount),
                    currency: data?.unitCostCurrency,
                },
                subtotal: {
                    amount: Number(data?.subtotal?.amount),
                    currency: data?.unitCostCurrency,
                },
                inventoryId: data?.inventoryId,
                deliveryDate: moment(data?.deliveryDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
                leadTime: data?.leadTime,
            })),
        };
        if (incidentId) {
            body.incidentId = incidentId;
        }
        // if (customerDetails?.source?.toLowerCase() === "quote") {
        //     body.itemsOrServices = selectedItems?.map((data) => ({
        //         name: data?.productName,
        //         qty: data?.quantity,
        //         price: {
        //             amount: Number(data?.unitCostAmount),
        //             currency: data?.unitCostCurrency,
        //         },
        //         subtotal: {
        //             amount: Number(data?.subtotal?.amount),
        //             currency: data?.unitCostCurrency,
        //         },

        //         inventoryId: data?.inventoryId,
        //         deliveryDate: moment(data?.deliveryDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
        //         leadTime: data?.leadTime,
        //     }));
        // }
        // if (customerDetails?.source?.toLowerCase() === "quote") {
        //     body.quoteResponseId = quoteResponseId;
        // }
        // if (customerDetails?.source?.toLowerCase() === "supplier") {
        //     body.vendorId = customerDetails?.supplier;
        // }
        // if (customerDetails?.source?.toLowerCase() === "contract") {
        //     body.contractId = customerDetails?.contractId;
        // }
        // if (customerDetails?.source?.toLowerCase() !== "quote") {
        //     body.itemsOrServices = productItems?.map((data) => ({
        //         name: data?.productName,
        //         qty: data?.quantity,
        //         price: {
        //             amount: Number(data?.unitCostAmount),
        //             currency: data?.unitCostCurrency,
        //         },
        //         subtotal: {
        //             amount: Number(data?.subtotal?.amount),
        //             currency: data?.unitCostCurrency,
        //         },
        //         inventoryId: data?.inventoryId,
        //         deliveryDate: moment(data?.deliveryDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
        //         leadTime: data?.leadTime,
        //     }));
        // }
        if (customerDetails?.termsAndCondition) {
            body.termsAndConditions = customerDetails?.termsAndCondition;
        }
        if (customerDetails?.additionalInfo) {
            body.additionalComment = customerDetails?.additionalInfo;
        }
        if (customerDetails?.tax) {
            body.tax = {
                amount: taxAmount.toFixed(2),
                percentage: customerDetails?.tax,
                currency: productItems?.[0]?.unitCostCurrency,
                // customerDetails?.source?.toLowerCase() !== "quote"
                //     ? productItems?.[0]?.unitCostCurrency
                //     : selectedItems?.[0]?.unitCostCurrency,
            };
        }

        if (customerDetails?.shippingAndHandling) {
            body.shippingAndHandling = {
                amount: customerDetails?.shippingAndHandling,
                currency: productItems?.[0]?.unitCostCurrency,
                // customerDetails?.source?.toLowerCase() !== "quote"
                //     ? productItems?.[0]?.unitCostCurrency
                //     : selectedItems?.[0]?.unitCostCurrency,
            };
        }

        dispatch(orderActions.createPurchaseOrder(body));
    };

    useEffect(() => {
        if (Boolean(createPurchaseOrderSuccess)) {
            setPurchaseOrderId(createPurchaseOrderSuccess?.po?._id);
            setSuccess(true);
            setShowPreview(false);
            setUploadingPdf(false);
            setShowSuccessPO(true);
        }
    }, [createPurchaseOrderSuccess]);

    useEffect(() => {
        if (createPurchaseOrderFailure !== "") {
            setUploadingPdf(false);
        }
    }, [createPurchaseOrderFailure]);

    const onCancel = () => {
        setCustomerDetails({
            issuedDate: "",
            dueDate: "",
            postalCode: "",
            country: "",
            city: "",
            tax: "",
            shippingAndHandling: "",
            termsAndCondition: "",
        });

        navigate("/dashboard/purchase?curTab=1");
    };

    const handleChangeTwo = (e) => {
        const { name, value } = e.target;
        setEmailDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onSendNow = () => {
        const body = {
            attachment: [
                {
                    filename: "purchase-order-doc",
                    path: pdfUrl,
                },
            ],
            comment: emailDetails.title,
            toEmail: emailDetails.sender,
            bcc: emails,
        };

        dispatch(orderActions.sendPurchaseOrder(purchaseOrderId, body));
    };

    useEffect(() => {
        if (Boolean(sendPurchaseOrderSuccess)) {
            navigate("/dashboard/purchase?curTab=1");
            setSuccess(false);
            setShowPreview(false);
            setCustomerDetails({
                image: "",
                issuedDate: "",
                dueDate: "",
                postalCode: "",
                country: "",
                rfqCategory: "",
                city: "",
                tax: "",
                shipping: "",
                orderDescription: "",
            });
            setEmails([]);
            setEmailDetails({
                title: "",
                sender: "",
            });

            dispatch(orderActions.resetSendPurchaseOrderSuccess());
            dispatch(orderActions.resetCreatePurchaseOrderSuccess());
        }
    }, [sendPurchaseOrderSuccess]);

    const handleShowPreview = (e) => {
        e.preventDefault();
        setShowPreview(true);
    };

    const deleteAttachment = (idx) => {
        const copyData = [...filesAttached];
        copyData?.splice(idx, 1);
        setFilesAttached(copyData);
    };

    return (
        <PageFrame containerClassName={""}>
            {!showPreview && !success && (
                <form onSubmit={handleShowPreview} className="relative max-lg:pb-24">
                    <div className="bg-white rounded-lg  ">
                        <div className="max-lg:hidden flex  items-center shadow-faintShadow border-b justify-between px-8 py-5 border-slate-100 top-[-20px] sticky  z-[10000] bg-white">
                            <div className="flex items-center gap-2">
                                <button className="" onClick={() => navigate(-1)}>
                                    <i className="ri-arrow-left-line text-gm-50"></i>
                                </button>
                                <p className="text-[20px] leading-[33px] font-rocGroteskMedium">
                                    Create Purchase Request
                                </p>
                            </div>

                            <div className="flex items-center gap-3">
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname="!py-2 border md:border-none border-n-20  !bg-transparent md:w-full !w-fit  !text-gm-50 !font-rocGroteskMedium !px-4"
                                    onClick={() => setShowCancelSign(true)}
                                />
                                <Button
                                    btnText={"Preview"}
                                    type={"submit"}
                                    btnClassname="!py-2 md:w-full !w-fit !font-rocGroteskMedium !px-4"
                                />
                            </div>
                        </div>
                        <div className="border-b pt-10 px-4 md:px-0  rounded  max-lg:border-r-0 border-r max-lg:border-l-0 border-l border-slate-100">
                            <div className="flex md:px-8  justify-between items-center">
                                <div className="flex sm:flex-row flex-col sm:space-y-0 w-full  space-y-3 md:space-x-6 mb-6">
                                    <div className="border border-dashed rounded-[4px] md:block hidden  border-[n-40] w-[128px] h-[128px]">
                                        <VendorAvatar
                                            containerClassname={"!rounded-[4px]"}
                                            size={127}
                                            imageSrc={userProfile?.user?.profile?.avatar}
                                            name={userProfile?.user?.profile?.businessName}
                                        />
                                    </div>
                                    <div className="border border-dashed rounded-[4px] md:hidden block border-[n-40] w-[100px] h-[100px]">
                                        <VendorAvatar
                                            containerClassname={"!rounded-[4px]"}
                                            size={100}
                                            imageSrc={userProfile?.user?.profile?.avatar}
                                            name={userProfile?.user?.profile?.businessName}
                                        />
                                    </div>
                                </div>
                                <div className="bg-[#FFF7ED] whitespace-nowrap px-4 py-1 rounded-[4px]">
                                    <p className="text-[#EA580C] text-sm">Not saved</p>
                                </div>
                            </div>
                            <div className="md:px-8 w-full space-y-2 flex md:flex-row flex-col mb-6 justify-between">
                                <div>
                                    <p className="text-base font-rocGroteskMedium mb-2">From:</p>
                                    <div className="text-sm font-rocGroteskMedium text-slate-500">
                                        <p className="text-gm-50">
                                            {truncate(userProfile?.user?.profile?.businessName, {
                                                length: 60,
                                            })}
                                        </p>
                                        <p>{truncate(userProfile?.user?.email, { length: 60 })}</p>
                                        <p>
                                            {truncate(
                                                userProfile?.user?.profile?.address?.address,
                                                { length: 60 }
                                            )}
                                        </p>
                                        <p>{truncate(userProfile?.user?.mobile, { length: 60 })}</p>
                                    </div>
                                </div>
                                <div className="md:text-right">
                                    <p className="text-base font-rocGroteskMedium mb-2">To:</p>
                                    <div className="text-sm font-rocGroteskMedium text-slate-500">
                                        <p className="text-gm-50">
                                            {truncate(quoteVendor?.profile?.businessName, {
                                                length: 60,
                                            })}
                                        </p>
                                        <p>{truncate(quoteVendor?.email, { length: 60 })}</p>
                                        <p>
                                            {truncate(quoteVendor?.profile?.address?.address, {
                                                length: 60,
                                            })}
                                        </p>
                                        <p>{truncate(quoteVendor?.mobile, { length: 60 })}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex  mt-6  md:px-8  pb-2  space-x-4 md:space-x-6 w-full">
                                <div className="w-full ">
                                    <TextInput
                                        name={"issuedDate"}
                                        type={"date"}
                                        value={
                                            inventoryId
                                                ? customerDetails.issuedDate ||
                                                  moment().format("YYYY-MM-DD")
                                                : customerDetails.issuedDate
                                        }
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            handleChange(e);
                                        }}
                                        placeholder={"Issued date"}
                                        required={true}
                                    />
                                </div>
                                <div className="w-full">
                                    <TextInput
                                        name={"dueDate"}
                                        type={"date"}
                                        value={customerDetails.dueDate}
                                        onChange={handleChange}
                                        placeholder={"Due date"}
                                        required={true}
                                        containerClassname="mb-4"
                                    />
                                </div>
                            </div>
                            <div className="md:px-8   pb-8">
                                <p className="text-[14px] md:block hidden mb-4 text-gm-50 font-rocGroteskMedium">
                                    Supplier
                                </p>
                                <div className="flex md:flex-row flex-col gap-4  md:gap-6 w-full">
                                    {/* <SelectInput
                                        value={customerDetails.source}
                                        name="source"
                                        placeholder={"Select source"}
                                        handleChange={(name, value) =>
                                            handleChange({ target: { name, value } })
                                        }
                                        isRequired={true}
                                        dropdownOptions={[
                                            {
                                                label: "Quotes",
                                                value: "Quote",
                                            },
                                            // {
                                            //     label: "Contracts",
                                            //     value: "Contract",
                                            // },
                                            {
                                                label: "Suppliers",
                                                value: "Supplier",
                                            },
                                        ]}
                                    /> */}
                                    {/* {customerDetails?.source?.toLowerCase() === "quote" && (
                                        <div className="w-full">
                                            <SelectInput
                                                value={customerDetails?.quotes ?? ""}
                                                name="quotes"
                                                placeholder={"Select Quotes"}
                                                handleInputChange={debounce((evt) => {
                                                    setDebouncedSearch({
                                                        search: evt.target.value,
                                                    });
                                                }, 800)}
                                                searchLoading={fetchingApprovedResponses}
                                                handleChange={(name, value) => {
                                                    if (value) {
                                                        setSelectedItems([]);
                                                        const filteredData = responses.filter(
                                                            (item) => item._id === value
                                                        );
                                                        setQuoteResponseId(filteredData?.[0]?._id);
                                                        setQuoteTaId(filteredData?.[0]?.taId);
                                                        setQuoteVendor(filteredData?.[0]?.vendor);
                                                        setSelectedInventories(
                                                            filteredData?.[0]?.charges
                                                        );
                                                    }
                                                    handleChange({
                                                        target: {
                                                            name,
                                                            value,
                                                        },
                                                    });
                                                }}
                                                isRequired={true}
                                                dropdownOptions={responses?.map((res) => ({
                                                    label: (
                                                        <div
                                                            className="font-rocGroteskMedium -mt-2"
                                                            key={res?._id}
                                                        >
                                                            <p className="text-slate-700 ">
                                                                {res?.taId}
                                                            </p>
                                                            <p className="text-slate-500 "></p>
                                                        </div>
                                                    ),
                                                    value: res?._id,
                                                }))}
                                            />
                                        </div>
                                    )} */}
                                    {/* {customerDetails?.source?.toLowerCase() === "supplier" && ( */}
                                    <SelectInput
                                        value={
                                            inventoryId
                                                ? customerDetails.supplier ||
                                                  inventoryData?.vendorIds?.[0]
                                                : customerDetails.supplier
                                        }
                                        name="supplier"
                                        placeholder={`Select supplier`}
                                        handleChange={(name, value) => {
                                            if (value) {
                                                const filteredData = vendorResponses.filter(
                                                    (item) => item.vendorId === value
                                                );
                                                setQuoteVendor(filteredData?.[0]?.vendorData);
                                            }
                                            handleChange({ target: { name, value } });
                                        }}
                                        isRequired={true}
                                        dropdownOptions={vendorResponses?.map((res) => ({
                                            label: (
                                                <div
                                                    className="font-rocGroteskMedium -mt-2"
                                                    key={res?._id}
                                                >
                                                    <p className="text-slate-700 ">
                                                        {res?.vendorData?.profile?.businessName}
                                                    </p>
                                                </div>
                                            ),
                                            value: res?.vendorId,
                                        }))}
                                    />
                                    {/* )} */}
                                    {customerDetails?.source?.toLowerCase() === "contract" && (
                                        <SelectInput
                                            value={customerDetails.contract ?? ""}
                                            name="contract"
                                            placeholder={`Select contract`}
                                            handleChange={(name, value) =>
                                                handleChange({ target: { name, value } })
                                            }
                                            isRequired={true}
                                            dropdownOptions={[
                                                {
                                                    label: "Quotes",
                                                    value: "quotes",
                                                },
                                                {
                                                    label: "Contracts",
                                                    value: "contracts",
                                                },
                                                {
                                                    label: "Suppliers",
                                                    value: "suppliers",
                                                },
                                            ]}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="md:px-8   pb-8   ">
                                <p className="text-[14px] mb-4 text-gm-50 font-rocGroteskMedium">
                                    Shipping information
                                </p>

                                <div className="flex md:flex-row flex-col  gap-4  md:gap-6 w-full">
                                    <div className="w-full">
                                        <SelectInput
                                            value={customerDetails.fobPoint}
                                            name="fobPoint"
                                            placeholder={"FOB point"}
                                            handleChange={(name, value) =>
                                                handleChange({ target: { name, value } })
                                            }
                                            isRequired={true}
                                            dropdownOptions={[
                                                {
                                                    label: (
                                                        <div>
                                                            <p className="font-rocGroteskMedium text-sm text-[#64748B]">
                                                                <span className="text-gm-50">
                                                                    Origin
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ),
                                                    value: "origin",
                                                },
                                                {
                                                    label: (
                                                        <div>
                                                            <p className="font-rocGroteskMedium text-sm text-[#64748B]">
                                                                <span className="text-gm-50">
                                                                    Destination
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ),
                                                    value: "destination",
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <SelectInput
                                            value={customerDetails?.shippingVia}
                                            name="shippingVia"
                                            placeholder={"Shipping via"}
                                            handleChange={(name, value) =>
                                                handleChange({ target: { name, value } })
                                            }
                                            handleInputChange={() => {}}
                                            isRequired={true}
                                            customRightDropdownContainer={
                                                <div
                                                    onClick={() => setAddShippingVia(true)}
                                                    className="pl-3 py-1 cursor-pointer "
                                                >
                                                    <p className="text-xs text-[#0046A2] flex items-center gap-1">
                                                        <i className="ri-add-fill text-[#0046A2] text-sm"></i>
                                                        <span className="underline font-rocGroteskMedium">
                                                            Add more options
                                                        </span>
                                                    </p>
                                                </div>
                                            }
                                            dropdownOptions={shippingData?.map((data) => ({
                                                label: data,
                                                value: data,
                                            }))}
                                        />
                                    </div>
                                </div>
                                <div className="flex md:flex-row flex-col gap-4 mt-4 md:gap-6 w-full">
                                    <div className="md:w-[49%]">
                                        <SelectInput
                                            value={customerDetails.shippingTerms}
                                            name="shippingTerms"
                                            placeholder={"Shipping terms"}
                                            handleChange={(name, value) =>
                                                handleChange({ target: { name, value } })
                                            }
                                            isRequired={true}
                                            customRightDropdownContainer={
                                                <div
                                                    onClick={() => setAddShippingTerms(true)}
                                                    className="pl-3 py-1 cursor-pointer "
                                                >
                                                    <p className="text-xs text-[#0046A2] flex items-center gap-1">
                                                        <i className="ri-add-fill text-[#0046A2] text-sm"></i>
                                                        <span className="underline font-rocGroteskMedium">
                                                            Add more options
                                                        </span>
                                                    </p>
                                                </div>
                                            }
                                            dropdownOptions={shippingTermsData?.map((data) => ({
                                                label: data,
                                                value: data,
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            {productItems?.map((item, idx) => (
                                <div className="mt-5 md:hidden block">
                                    <div className="flex mb-4 justify-between items-center">
                                        <p className="text-sm font-rocGroteskMedium text-[#142837] ">
                                            Product {idx + 1}
                                        </p>
                                        <i
                                            className="ri-delete-bin-line cursor-pointer text-[#64748B]"
                                            onClick={() => handleDelete(idx)}
                                        ></i>
                                    </div>
                                    <div className="space-y-3">
                                        <SelectInput
                                            value={item?.inventoryId || ""}
                                            name="inventoryId"
                                            inputPlaceholder={"Select product"}
                                            handleChange={(name, value) => {
                                                if (value) {
                                                    setProductItems((prevSelectedItems) => {
                                                        const updatedItems = [...prevSelectedItems];
                                                        const selectedItem = productList.find(
                                                            (item) => item._id === value
                                                        );
                                                        if (selectedItem) {
                                                            updatedItems[idx] = {
                                                                ...updatedItems[idx],
                                                                unitCostAmount:
                                                                    selectedItem?.unitCost?.amount,
                                                                unitCostCurrency:
                                                                    selectedItem?.unitCost
                                                                        ?.currency,
                                                                sku: selectedItem.sku,
                                                                productName:
                                                                    selectedItem.productName,
                                                                inventoryId: selectedItem._id,
                                                            };
                                                        }
                                                        return updatedItems;
                                                    });
                                                }
                                            }}
                                            handleInputChange={debounce((evt) => {
                                                setDebouncedSearch({
                                                    search: evt.target.value,
                                                });
                                            }, 800)}
                                            isRequired={true}
                                            className="!rounded-[4px]"
                                            inputContainerClassName="!mb-0"
                                            searchLoading={fetchingInventoryList}
                                            clearValue={true}
                                            dropdownOptions={productList?.map((item) => ({
                                                label: (
                                                    <div className="flex gap-3 items-center">
                                                        <div className="flex gap flex-col">
                                                            <p className="font-rocGroteskMedium">
                                                                {item?.productName}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ),
                                                value: `${item?._id}`,
                                            }))}
                                        />
                                        <div className="flex items-center space-x-3">
                                            <div className="border w-full py-[16px] !rounded-[4px] h-[50px] px-[13px]">
                                                <div
                                                    className={`w-full border-slate-100 text-sm text-slate-700 `}
                                                >
                                                    {item?.sku ? (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: hasHTMLTag(
                                                                    truncate(item?.sku, {
                                                                        length: 29,
                                                                    })
                                                                )
                                                                    ? truncate(item?.sku, {
                                                                          length: 29,
                                                                      })
                                                                    : truncate(item?.sku, {
                                                                          length: 29,
                                                                      }),
                                                            }}
                                                        />
                                                    ) : (
                                                        <p className="text-slate-500">SKU</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className={`w-full !border !border-[#D0D4D7] !rounded-[4px]   text-sm text-slate-700 `}
                                            >
                                                <TextInput
                                                    value={item?.unitCostCurrency ?? "USD"}
                                                    name={"currency"}
                                                    type={"text"}
                                                    required={true}
                                                    onChange={(e) => {}}
                                                    disabled={true}
                                                    containerClassname=""
                                                    inputContainerClassname={
                                                        "!rounded-[4px] !border-0"
                                                    }
                                                    inputClassName="w-full disabled:!bg-white"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`w-full border-slate-100 text-sm text-slate-700 `}
                                        >
                                            <TextInput
                                                value={item?.quantity ?? ""}
                                                name={"quantity"}
                                                type={"number"}
                                                onWheel={(e: any) => e.target.blur()}
                                                onKeyDown={(evt) =>
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                        "ArrowUp",
                                                        "ArrowDown",
                                                    ].includes(evt.key) && evt.preventDefault()
                                                }
                                                required={true}
                                                onChange={(e) => {
                                                    handleItemChange(
                                                        e.target.name,
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                inputPlaceholder="Qty"
                                                containerClassname=""
                                                inputContainerClassname={"!rounded-[4px] "}
                                                inputClassName="w-full disabled:!bg-white"
                                            />
                                        </div>
                                        <div
                                            className={`w-full border-[#D0D4D7] text-sm text-slate-700 `}
                                        >
                                            <TextInput
                                                value={item?.unitCostAmount ?? 0}
                                                name={"unitCostAmount"}
                                                type={"number"}
                                                onKeyDown={(evt) =>
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                        "ArrowUp",
                                                        "ArrowDown",
                                                    ].includes(evt.key) && evt.preventDefault()
                                                }
                                                inputPlaceholder="Unit cost"
                                                onChange={(e) => {
                                                    handleItemChange(
                                                        e.target.name,
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                required
                                                containerClassname=""
                                                inputContainerClassname={
                                                    "!border  !border-[#D0D4D7] !rounded-[4px] "
                                                }
                                                inputClassName="w-full disabled:!bg-white"
                                            />
                                        </div>
                                        <div
                                            className={`w-full border !rounded-[4px] h-[50px] py-[16px] px-[13px] border-[#D0D4D7] text-sm text-slate-700 `}
                                        >
                                            <p>
                                                {formatMoney().format(item?.subtotal?.amount || 0)}
                                            </p>
                                        </div>
                                        <div className="flex space-x-2 justify-between items-center">
                                            <div className={`w-full  text-sm text-slate-700 `}>
                                                <TextInput
                                                    value={item?.deliveryDate}
                                                    name={"deliveryDate"}
                                                    type={"date"}
                                                    required={true}
                                                    onChange={(e) => {
                                                        handleItemChange(
                                                            e.target.name,
                                                            e.target.value,
                                                            idx
                                                        );
                                                    }}
                                                    containerClassname=""
                                                    inputContainerClassname={
                                                        "!border !border-[#D0D4D7] !rounded-[4px] "
                                                    }
                                                    inputClassName="w-full disabled:!bg-white"
                                                />
                                            </div>
                                            <div
                                                className={`w-full h-[50px] border  py-[16px] px-[13px] rounded-[4px] border-[#D0D4D7] text-sm text-slate-700 `}
                                            >
                                                <p>
                                                    {item?.leadTime !== ""
                                                        ? item?.leadTime
                                                        : "Lead time"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="flex items-center md:hidden  justify-between">
                                <div
                                    className="flex space-x-1 items-center mt-4  w-fit cursor-pointer"
                                    onClick={handleAddProduct}
                                >
                                    <i className="ri-add-circle-line text-[#6562E5]"></i>
                                    <p className="text-sm underline text-[#6562E5] font-rocGroteskMedium">
                                        Add product
                                    </p>
                                </div>
                                <div className="mt-6 text-sm  text-slate-500 font-rocGroteskMedium">
                                    <p>
                                        Total Quantity:{" "}
                                        <span className="ml-1">
                                            {" "}
                                            {totalProductQuantity
                                                ? formatMoney().format(totalProductQuantity)
                                                : 0}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5  md:block hidden">
                                {customerDetails?.source.toLowerCase() === "quote" && (
                                    <div className="mt-4 px-8">
                                        <CustomTable
                                            tableBody={tableBody}
                                            tableHeader={tableHeader}
                                            isAllSelectable={true}
                                            isCellSelectable={false}
                                            headerContainerClass="!bg-[#F8FAFC] !border-slate-200"
                                            isScrollable={false}
                                            isCellBordered={true}
                                            bodyItemClass={"hover:!bg-transparent"}
                                        />
                                        <div className="mt-4 text-sm text-slate-500 font-rocGroteskMedium">
                                            <p>
                                                Total Quantity:
                                                {formatMoney().format(totalQuantity)}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {customerDetails?.source.toLowerCase() !== "quote" && (
                                    <div className="mt-4 px-8">
                                        <CustomTable
                                            tableBody={notQuotetableBody}
                                            tableHeader={notQuoteTableHeader}
                                            headerContainerClass="!bg-[#F8FAFC] !border-slate-200"
                                            isScrollable={false}
                                            isCellBordered={true}
                                            scrollWithin={false}
                                            bodyItemClass={"hover:!bg-transparent"}
                                        />
                                        <div
                                            className="flex space-x-1 items-center mt-4  w-fit cursor-pointer"
                                            onClick={handleAddProduct}
                                        >
                                            <i className="ri-add-line"></i>
                                            <p className="text-sm underline font-rocGroteskMedium">
                                                Add product
                                            </p>
                                        </div>
                                        <div className="mt-6 text-sm  text-slate-500 font-rocGroteskMedium">
                                            <p>
                                                Total Quantity:{" "}
                                                <span className="ml-1">
                                                    {" "}
                                                    {totalProductQuantity
                                                        ? formatMoney().format(totalProductQuantity)
                                                        : 0}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="md:px-8  mt-5">
                                <p className="text-[14px] mb-4 text-gm-50 font-rocGroteskMedium">
                                    Taxes and Charges
                                </p>

                                <div className="w-full mt-5 grid grid-cols-2 gap-4 pb-3 ">
                                    <TextInput
                                        name={"tax"}
                                        type={"number"}
                                        onWheel={(e) => (e.target as any).blur()}
                                        value={customerDetails.tax}
                                        onChange={handleChange}
                                        placeholder={"Tax"}
                                        required={false}
                                        rightIcon="%"
                                    />

                                    <TextInput
                                        name={"shippingAndHandling"}
                                        type={"number"}
                                        onWheel={(e) => (e.target as any).blur()}
                                        value={customerDetails.shippingAndHandling}
                                        onChange={handleChange}
                                        placeholder={"Shipping and Handling"}
                                        required={false}
                                    />
                                    <div className="md:block hidden">
                                        <TextInput
                                            name={"incoterm"}
                                            type={"text"}
                                            value={customerDetails.incoterm}
                                            onChange={handleChange}
                                            placeholder={"Incoterm"}
                                            required={false}
                                        />
                                    </div>
                                </div>
                                <div className="md:hidden block">
                                    <TextInput
                                        name={"incoterm"}
                                        type={"text"}
                                        value={customerDetails.incoterm}
                                        onChange={handleChange}
                                        placeholder={"Incoterm"}
                                        required={false}
                                    />
                                </div>
                            </div>
                            <div className="md:px-8  mt-3 ">
                                <p className="text-[14px] mb-4 text-gm-50 font-rocGroteskMedium">
                                    Payment Terms
                                </p>
                                <div className="w-full mt-5 md:w-[49%] gap-6 pb-3 ">
                                    <SelectInput
                                        value={customerDetails?.paymentTerms}
                                        name={"paymentTerms"}
                                        placeholder={"Select payment terms"}
                                        handleInputChange={debounce((evt) => {}, 800)}
                                        searchLoading={false}
                                        handleChange={(name, value) => {
                                            handleChange({ target: { name, value } });
                                        }}
                                        isRequired={true}
                                        customRightDropdownContainer={
                                            <div
                                                onClick={() => setAddPaymentOption(true)}
                                                className="pl-3 py-1 cursor-pointer "
                                            >
                                                <p className="text-xs text-[#0046A2] flex items-center gap-1">
                                                    <i className="ri-add-fill text-[#0046A2] text-sm"></i>
                                                    <span className="underline font-rocGroteskMedium">
                                                        Add more options
                                                    </span>
                                                </p>
                                            </div>
                                        }
                                        dropdownOptions={paymentTermsData?.map((data) => ({
                                            label: data,
                                            value: data,
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className="mt-4 md:px-8  w-full">
                                <p className="text-base mb-6 font-rocGroteskMedium text-black">
                                    Attachments
                                </p>
                                <div className="mt-4">
                                    {filesAttached?.map((doc, idx) => (
                                        <div className="flex space-x-1 w-full items-center">
                                            <div className="flex w-full mb-2 items-center p-3 rounded-[6px] space-x-3 border border-[#F1F5F9]">
                                                {displayFileIcon("pdf", true, "w-[25px] h-[25px]")}
                                                <div>
                                                    <p className="text-sm font-rocGroteskMedium text-slate-700">
                                                        {doc?.fileName}
                                                    </p>
                                                    <p className="text-xs font-rocGroteskMedium text-slate-500">
                                                        {/* {convertBytes(Number(doc?.size))} */}
                                                    </p>
                                                </div>
                                            </div>
                                            <i
                                                className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                                onClick={() => deleteAttachment(idx)}
                                            ></i>
                                        </div>
                                    ))}
                                    <div className="flex items-center justify-between p-3 rounded-md border border-dashed border-slate-200 mb-4">
                                        {uploadingDoc ? (
                                            <Loader />
                                        ) : (
                                            <div
                                                className="flex items-center  w-fit gap-4 cursor-pointer"
                                                onClick={() =>
                                                    document.getElementById("fileInput")?.click()
                                                }
                                            >
                                                <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                                                <div className="font-rocGroteskMedium">
                                                    <p className="text-sm underline text-gm-50 ">
                                                        Click to upload document
                                                    </p>
                                                    <span className="text-[13px] text-[#6B778C]">
                                                        .JPEG .PDF .CSV .DOCX | File size limit:
                                                        25MB
                                                    </span>
                                                </div>
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    className={`absolute z-[-1]  opacity-0`}
                                                    // accept=".pdf,csv,docx,image/*"
                                                    multiple
                                                    onChange={(evt) => {
                                                        if (
                                                            !isFileSizeLessThanOrEqualTo(
                                                                evt.target.files[0],
                                                                25
                                                            )
                                                        ) {
                                                            toast.custom((t) => (
                                                                <CustomToast
                                                                    t={t}
                                                                    message={"File to large"}
                                                                    type={"error"}
                                                                />
                                                            ));
                                                            return;
                                                        } else {
                                                            handleUpload(
                                                                evt.target.files[0].name,
                                                                evt.target.files[0]
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="py-7 space-y-6   md:px-8 ">
                                <div>
                                    <div className="mb-6 flex flex-row space-x-2">
                                        <p className="text-base font-rocGroteskMedium text-gm-50">
                                            Terms & Conditions
                                        </p>
                                        <i
                                            className={`ri-arrow-${
                                                showEditor ? "up" : "down"
                                            }-s-line cursor-pointer`}
                                            onClick={() => setShowEditor(!showEditor)}
                                        ></i>
                                    </div>

                                    {showEditor && (
                                        <Editor
                                            apiKey={config.TINYMICE_API}
                                            value={customerDetails.termsAndCondition}
                                            onEditorChange={(newValue, editor) => {
                                                setCustomerDetails((prev) => ({
                                                    ...prev,
                                                    termsAndCondition: newValue,
                                                }));
                                            }}
                                            init={{
                                                height: 400,
                                                menubar: false,
                                                plugins: [
                                                    "advlist",
                                                    "autolink",
                                                    "lists",
                                                    "link",
                                                    "anchor",
                                                    "searchreplace",
                                                    "visualblocks",
                                                    "fullscreen",
                                                    "insertdatetime",
                                                    "media",
                                                ],
                                                placeholder:
                                                    "Add guarantee terms, payment term, scope of work, return policy and other note for the ordered products or services",
                                                // selector: "textarea",
                                                resize: false,
                                                branding: false,
                                                toolbar:
                                                    "undo redo | casechange blocks | bold italic underline link backcolor | " +
                                                    "alignleft aligncenter alignright alignjustify | " +
                                                    "bullist numlist checklist outdent indent | removeformat",
                                                content_style:
                                                    "body { font-family:rocGrotesk,Helvetica,Arial,sans-serif; font-size:14px; }",
                                            }}
                                        />
                                    )}
                                </div>
                                <div>
                                    <div className="mb-6 flex flex-row space-x-2">
                                        <p className="text-base font-rocGroteskMedium text-gm-50">
                                            Additional Info
                                        </p>
                                        <i
                                            className={`ri-arrow-${
                                                showAdditionalInfo ? "up" : "down"
                                            }-s-line cursor-pointer`}
                                            onClick={() =>
                                                setShowAdditionalInfo(!showAdditionalInfo)
                                            }
                                        ></i>
                                    </div>

                                    {showAdditionalInfo && (
                                        <TextareaInput
                                            name={"additionalInfo"}
                                            value={customerDetails?.additionalInfo}
                                            onChange={handleChange}
                                            rows={5}
                                            placeholder={
                                                "Enter special instructions related to the order."
                                            }
                                            containerClassname=""
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-col space-y-3 max-lg:flex  hidden items-center left-0 right-0 px-5 shadow-faintShadow border-t justify-between  py-5 border-slate-100 bottom-[0px] w-full fixed z-[50]   bg-white">
                        <Button
                            btnText={"Preview"}
                            type={"submit"}
                            btnClassname="!py-2   !font-rocGroteskMedium "
                        />
                        <Button
                            btnText={"Cancel"}
                            type={"button"}
                            btnClassname="!py-2 border md:border-none border-n-20  !bg-transparent  !text-gm-50 !font-rocGroteskMedium"
                            onClick={() => setShowCancelSign(true)}
                        />
                    </div>
                </form>
            )}
            {showPreview && !success && (
                <div className="flex justify-center px-4 md:px-0">
                    <div className="sm:w-full w-full ">
                        <PurchaseOrderDetails
                            btnTextOne={"Adjust PR"}
                            btnTextThree="Cancel"
                            btnTextTwo="Create purchase request"
                            successPreview={false}
                            imageDetails={userProfile?.user?.profile?.avatar}
                            disabled={createPurchaseOrder}
                            isLoading={createPurchaseOrder || uploadingPdf}
                            userProfile={userProfile}
                            customerDetails={customerDetails}
                            totalQuantity={
                                customerDetails?.source?.toLowerCase() === "quote"
                                    ? totalQuantity
                                    : totalProductQuantity
                            }
                            ldbPhone={userProfile?.user?.mobile}
                            vendorPhone={quoteVendor?.mobile}
                            filesAttached={filesAttached}
                            ldbName={userProfile?.user?.profile?.businessName}
                            ldbEmail={userProfile?.user?.email}
                            ldbAddress={userProfile?.user?.profile?.address?.address}
                            vendorAddress={quoteVendor?.profile?.address?.address}
                            vendorEmail={quoteVendor?.email}
                            vendorName={quoteVendor?.profile?.businessName}
                            containers={
                                customerDetails?.source?.toLowerCase() === "quote"
                                    ? selectedItems
                                    : productItems
                            }
                            setSaveBlob={setSaveBlob}
                            setShowSendNow={setShowSendNow}
                            onClickOne={() => setShowPreview(false)}
                            onClickThree={() => setShowCancelSign(true)}
                            onClickTwo={() => {}}
                            taxAmount={isNaN(taxAmount) ? 0 : Number(taxAmount.toFixed(2))}
                            totalAmount={
                                isNaN(totalSubtotals) ? 0 : Number(totalSubtotals.toFixed(2))
                            }
                            grandTotal={
                                Number(totalSubtotals) +
                                (isNaN(Number(taxAmount.toFixed(2)))
                                    ? 0
                                    : Number(taxAmount.toFixed(2))) +
                                Number(customerDetails?.shippingAndHandling || 0)
                            }
                            title={"Preview PO"}
                            showBtn
                        />
                    </div>
                </div>
            )}

            {success && (
                <div className="flex justify-center px-4 md:px-0">
                    <div className="sm:w-full w-full ">
                        <PurchaseOrderDetails
                            btnTextOne={"Download PDF"}
                            successPreview={true}
                            imageDetails={userProfile?.user?.profile?.avatar}
                            disabled={false}
                            isLoading={false}
                            userProfile={userProfile}
                            setSaveBlob={setSaveBlob}
                            setShowSendNow={setShowSendNow}
                            filesAttached={filesAttached}
                            ldbPhone={userProfile?.user?.mobile}
                            vendorPhone={quoteVendor?.mobile}
                            totalQuantity={
                                customerDetails?.source?.toLowerCase() === "quote"
                                    ? totalQuantity
                                    : totalProductQuantity
                            }
                            vendorAddress={quoteVendor?.profile?.address?.address}
                            vendorEmail={quoteVendor?.email}
                            vendorName={quoteVendor?.profile?.businessName}
                            // approvers={settingsDetails?.approvers}
                            customerDetails={customerDetails}
                            ldbName={userProfile?.user?.profile?.businessName}
                            ldbEmail={userProfile?.user?.email}
                            ldbAddress={userProfile?.user?.profile?.address?.address}
                            containers={
                                customerDetails?.source?.toLowerCase() === "quote"
                                    ? selectedItems
                                    : productItems
                            }
                            taxAmount={isNaN(taxAmount) ? 0 : Number(taxAmount.toFixed(2))}
                            totalAmount={
                                isNaN(totalSubtotals) ? 0 : Number(totalSubtotals.toFixed(2))
                            }
                            title={"PO Created"}
                            grandTotal={
                                Number(totalSubtotals) +
                                Number(taxAmount.toFixed(2)) +
                                Number(customerDetails?.shippingAndHandling)
                            }
                        />
                    </div>
                </div>
            )}

            {showSendNow && (
                <ModalContainer
                    open={showSendNow}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[430px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setShowSendNow(false);
                        dispatch(orderActions.resetCreatePurchaseOrderSuccess());
                        navigate("/dashboard/purchase?curTab=1");
                        setSuccess(false);
                    }}
                >
                    <div className="bg-white !px-2 pt-6 pb-6  rounded-[12px]">
                        <div className="border-b pb-4  border-slate-200">
                            <div className="flex px-6 items-center justify-between">
                                <h1 className="text-black font-rocGroteskMedium text-[18px] ">
                                    Send Purchase Request
                                </h1>
                                <span
                                    className="material-icons text-gm-50 text-[24px] cursor-pointer font-medium"
                                    onClick={() => {
                                        setShowSendNow(false);
                                        navigate("/dashboard/purchase?curTab=1");
                                        setSuccess(false);
                                    }}
                                >
                                    close
                                </span>
                            </div>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="space-y-5 ">
                                <TextInput
                                    name={"sender"}
                                    type={"text"}
                                    value={emailDetails.sender}
                                    onChange={handleChangeTwo}
                                    placeholder={"From"}
                                    required={false}
                                />
                                <EmailInput
                                    setEmails={setEmails}
                                    emails={emails}
                                    placeholder={"Email"}
                                    required={true}
                                />

                                <TextareaInput
                                    name={"title"}
                                    value={emailDetails.title}
                                    onChange={handleChangeTwo}
                                    rows={6}
                                    placeholder={"Title"}
                                    required={false}
                                />
                            </div>
                            <div className="mt-12 flex items-center space-x-2">
                                <div className="w-full">
                                    <Button
                                        btnText={"Close"}
                                        btnClassname="!py-2.5 cursor-pointer !bg-[#F4F5F7] !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                                        onClick={() => {
                                            setShowSendNow(false);
                                            dispatch(
                                                orderActions.resetCreatePurchaseOrderSuccess()
                                            );
                                            navigate("/dashboard/purchase?curTab=1");
                                            setSuccess(false);
                                        }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        btnText={"Share Purchase Request"}
                                        type={"button"}
                                        isLoading={sendPurchaseOrder}
                                        btnClassname="!py-2.5 !whitespace-nowrap !text-[14px] !px-4"
                                        onClick={onSendNow}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
            {showSuccessPO && (
                <ModalContainer
                    open={showSuccessPO}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[484px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        dispatch(orderActions.resetCreatePurchaseOrderSuccess());
                        setShowSuccessPO(false);
                        navigate("/dashboard/purchase?curTab=1");
                    }}
                >
                    <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                        <div className="flex px-6 items-center justify-end">
                            <span
                                className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                                onClick={() => {
                                    dispatch(orderActions.resetCreatePurchaseOrderSuccess());
                                    setShowSuccessPO(false);
                                    navigate("/dashboard/purchase?curTab=1");
                                }}
                            >
                                close
                            </span>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="w-[48px] h-[48px] flex justify-center items-center bg-[#00DB8F] rounded-full">
                                    <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                </div>
                                <div>
                                    <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                        Purchase Request Created!
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Your purchase request has been successfully created and
                                        automatically sent for approval. You will receive
                                        notification once it has been reviewed by the appropriate
                                        authority
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 flex items-center space-x-2">
                                <div className="w-full">
                                    <Button
                                        btnText={"Close"}
                                        btnClassname="!py-3 cursor-pointer !bg-[#F4F5F7] !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                                        onClick={() => {
                                            dispatch(
                                                orderActions.resetCreatePurchaseOrderSuccess()
                                            );
                                            setShowSuccessPO(false);
                                            navigate("/dashboard/purchase?curTab=1");
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
            {showCancelSign && (
                <ModalContainer
                    open={showCancelSign}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[400px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setShowCancelSign(false);
                    }}
                >
                    <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                        <div className="flex px-6 items-center justify-end">
                            <span
                                className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                                onClick={() => setShowCancelSign(false)}
                            >
                                close
                            </span>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="">
                                    <img src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1697712771/Caution_sign_g1u9nv.svg" />
                                </div>
                                <div>
                                    <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                        Discard Changes?
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Leaving this page will result in the loss of any unsaved
                                        progress in your purchase request creation. Are you sure you
                                        want to exit?
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 flex space-x-2">
                                <Button
                                    btnText={"No"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-n-20 !text-gm-50 !text-[14px] !px-4"
                                    onClick={() => setShowCancelSign(false)}
                                />
                                <Button
                                    btnText={"Yes, cancel"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-r-50 !text-white !text-[14px] !px-4"
                                    onClick={onCancel}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
            <ModalContainer
                open={addShippingVia}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[488px]"
                showCloseIcon={false}
                closeModal={() => {
                    setAddShippingVia(false);
                    setNewShippingOption("");
                }}
            >
                <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                    <div className="px-6">
                        <div className="text-start flex  flex-col  space-y-5">
                            <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                Add Shipping options
                            </h3>
                            <TextInput
                                placeholder="Enter Option"
                                value={newShippingOption}
                                onChange={(e) => setNewShippingOption(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-end">
                            <div className="mt-5 flex justify-end">
                                <Button
                                    btnText={"Add shipping options"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px]  !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        if (newShippingOption.trim() !== "") {
                                            setShippingData((prevShippingData) => [
                                                newShippingOption.trim(),
                                                ...prevShippingData,
                                            ]);
                                        }
                                        setAddShippingVia(false);
                                        setNewShippingOption("");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={addShippingTerms}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[488px]"
                showCloseIcon={false}
                closeModal={() => {
                    setAddShippingTerms(false);
                    setNewShippingTermsOption("");
                }}
            >
                <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                    <div className="px-6">
                        <div className="text-start flex  flex-col  space-y-5">
                            <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                Add Shipping options
                            </h3>
                            <TextInput
                                placeholder="Enter Option"
                                value={newShippingTermsOption}
                                onChange={(e) => setNewShippingTermsOption(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-end">
                            <div className="mt-5 flex justify-end">
                                <Button
                                    btnText={"Add shipping options"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px]  !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        if (newShippingTermsOption.trim() !== "") {
                                            setShippingTermsData((prevShippingData) => [
                                                newShippingTermsOption.trim(),
                                                ...prevShippingData,
                                            ]);
                                        }
                                        setAddShippingTerms(false);
                                        setNewShippingTermsOption("");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={addPaymentOption}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[488px]"
                showCloseIcon={false}
                closeModal={() => {
                    setAddPaymentOption(false);
                    setNewPaymentOption("");
                }}
            >
                <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                    <div className="px-6">
                        <div className="text-start flex  flex-col  space-y-5">
                            <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                Add Payment options
                            </h3>
                            <TextInput
                                placeholder="Enter Option"
                                value={newPaymentOption}
                                onChange={(e) => setNewPaymentOption(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-end">
                            <div className="mt-5 flex justify-end">
                                <Button
                                    btnText={"Add payment options"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px]  !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        if (newPaymentOption.trim() !== "") {
                                            setPaymentTermsData((prevShippingData) => [
                                                newPaymentOption.trim(),
                                                ...prevShippingData,
                                            ]);
                                        }
                                        setAddPaymentOption(false);
                                        setNewPaymentOption("");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default CreateOrder;
