import React, { useState } from "react";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import { debounce, truncate } from "lodash";
import VendorAvatar from "components/common/VendorAvatar";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { useNavigate } from "react-router-dom";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";

type SelectProductsProps = {
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    selectedProducts: { [key: string]: any }[];
};

const PreviewSelectedProducts = ({ step, setStep, selectedProducts }: SelectProductsProps) => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { importingSyncedProducts } = useAppSelector((state) => state.inventory);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedSearchValue(e.target.value);
    });

    const handleImport = () => {
        const body = {
            productIds: selectedProducts?.map((product) => product?._id),
        };

        dispatch(inventoryActions.importSyncedProducts(body));
    };

    const handleGoBack = () => {
        setStep(1);
    };

    return (
        <div className="mt-[31px] flex justify-center">
            <div className="w-[70%] h-[75vh] bg-white rounded-[6px] shadow-[0px_20px_24px_-4px_#10182814]">
                <div className="w-full h-full relative">
                    <div className="px-6 pt-4 pb-6 border-b border-slate-200">
                        <div className="font-rocGroteskMedium flex justify-between items-center mb-1">
                            <div className="flex items-center space-x-1.5">
                                <i
                                    onClick={handleGoBack}
                                    className="ri-arrow-left-line text-lg mt-[-2px] cursor-pointer"
                                ></i>
                                <h4 className="text-g-75 text-base">
                                    Select product/SKUs to import to inventory
                                </h4>
                            </div>

                            <span className="text-sm text-slate-500 ">Step {step}/3</span>
                        </div>
                        <span className="block text-sm text-slate-500 font-rocGroteskMedium mb-3">
                            Before importing, please take a moment to review the details of your
                            selected product/SKU. Ensuring accuracy now can help prevent issues
                            later.
                        </span>

                        <div className="mb-4">
                            <TextInput
                                name={"search"}
                                value={searchValue}
                                type={"text"}
                                inputPlaceholder={"Search products"}
                                inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                onChange={handleSearch}
                                onInput={handleDebouncedChange}
                                containerClassname={"!w-full max-sm:!w-full"}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-slate-200"
                                }
                            />
                        </div>

                        <div className="grid grid-cols-3 space-x-1.5">
                            <span className="h-1 bg-[#FFB902] rounded"></span>
                            <span className="h-1 bg-[#FFB902] rounded"></span>
                            <span className="h-1 bg-[#EADFC1] rounded"></span>
                        </div>
                    </div>

                    <div className="h-[42.8vh] overflow-y-auto">
                        {selectedProducts
                            ?.filter((product) =>
                                product?.productName
                                    ?.toLowerCase()
                                    ?.includes(debouncedSearchValue?.toLowerCase())
                            )
                            ?.map((product, idx) => {
                                return (
                                    <div
                                        className={`flex items-center justify-between px-6 py-3 ${
                                            idx > 0 && "border-t"
                                        } border-slate-200`}
                                        key={product?._id}
                                    >
                                        <div className="flex items-center space-x-3">
                                            {product?.productImageDetails?.productImages?.[0] ? (
                                                <VendorAvatar
                                                    imageSrc={
                                                        product?.productImageDetails
                                                            ?.productAvatar ||
                                                        product?.productImageDetails
                                                            ?.productImages?.[0]
                                                    }
                                                    size={32}
                                                    name={product?.productName}
                                                    containerClassname="!rounded-md"
                                                />
                                            ) : (
                                                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                </div>
                                            )}
                                            <div>
                                                <span className="block font-rocGroteskMedium text-sm">
                                                    {truncate(product?.productName, { length: 40 })}
                                                </span>
                                                <div className="flex items-center space-x-1.5">
                                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                        {product?.sku}
                                                    </span>
                                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                        |
                                                    </span>
                                                    <div className="bg-slate-50 w-fit px-1.5 py-1 flex items-center border rounded-lg">
                                                        {product?.channel ? (
                                                            <div className="flex items-center space-x-1">
                                                                {displayProductChannelIcon(
                                                                    product?.channel,
                                                                    "!w-4 !h-4"
                                                                )}
                                                                <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                                    {truncate(product?.channel, {
                                                                        length: 20,
                                                                    })}{" "}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center space-x-1">
                                                                {displayProductChannelIcon(
                                                                    product?.channel,
                                                                    "!w-4 !h-4"
                                                                )}
                                                                <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                                    Synth
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    <div className="flex items-center justify-between absolute bottom-0 w-full bg-white border-t border-slate-200 px-6 py-4">
                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                            {selectedProducts?.length} products ready to be imported
                        </span>

                        <div className="flex items-center space-x-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="h-[48px] !bg-n-20"
                                disabled={importingSyncedProducts}
                                onClick={() => navigate(`/dashboard/inventory`)}
                            />

                            <Button
                                btnText="Import products to inventory"
                                btnClassname="h-[48px] !whitespace-nowrap"
                                isLoading={importingSyncedProducts}
                                onClick={handleImport}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreviewSelectedProducts;
