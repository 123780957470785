export const productCategories = [
    {
        label: "Raw Materials",
        value: "Raw Material",
    },
    {
        label: "Finished Goods",
        value: "Finished Goods",
    },
    {
        label: "Work-In-Progress (WIP)",
        value: "Work-In-Progress (WIP)",
    },
    {
        label: "Packaging Materials",
        value: "Packaging Materials",
    },
];
