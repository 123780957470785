import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import VendorAvatar from "components/common/VendorAvatar";
import PageTitleSection from "components/common/PageTitleSection";
import CustomTable from "components/common/CustomTable";
import { debounce, truncate, uniqBy } from "lodash";
import { useNavigate } from "react-router-dom";
import Dropdown from "components/common/Dropdown";
import TabsContainer from "components/common/TabsContainer";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import ModalContainer from "components/common/ModalContainer";
import SelectInput from "components/common/InputField/SelectInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { vendorActions } from "redux/Ldb/actions";
import Loader from "components/common/Loader";
import PageFrame from "components/layout/PageFrame";
import { periodStartDateAndEndDate } from "helpers/periodStartDateAndEndDate";
import SupplierDashboard from "components/views/Ldb/Dashboard/Orders/SupplierDashboard";
import SupplierDetails from "./supplierDetails";
import DeleteSupplierModal from "components/views/Synth2.0/Supplier/DeleteSupplierModal";
import { useNav } from "components/common/Navbar/NavContext";

const Suppliers = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const chartRef = useRef(null);
    const limit = 10;
    const [activeTab, setActiveTab] = useState(0);
    const [initiateReturn, setInitiateReturn] = useState<boolean>(false);
    const [editReturn, setEditReturn] = useState<boolean>(false);
    const [showSuccessRequest, setShowSuccessRequest] = useState<boolean>(false);
    const [supplierAnalytics, setSupplierAnalytics] = useState<{ [key: string]: any }>({});
    const [ldbVendorList, setLdbVendorList] = useState<{ [key: string]: any }[]>([]);
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [risk, setRisk] = useState<string>("");
    const [changeValue, setChangeValue] = useState<string>("");
    const [dates, setDates] = useState<{ [key: string]: any }>({});
    const [titleId, setTitleId] = useState<number>(0);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [deleteSupplier, setDeleteSupplier] = useState<{ id: string; name: string }>({
        name: "",
        id: "",
    });

    const values = [
        {
            label: "This year",
            value: "yearly",
        },
        // {
        //     label: "6 months",
        //     value: "six_month",
        // },
        {
            label: "This month",
            value: "monthly",
        },
        {
            label: "This week",
            value: "weekly",
        },
        {
            label: "All Data",
            value: "all",
        },
    ];
    const title = [
        {
            id: 0,
            name: "Dashboard",
        },
        {
            id: 1,
            name: "Supplier list",
        },
    ];
    const tabs = ["All", "Low risk supplier", "Medium risk supplier", "High risk supplier"];
    const tabs2 = ["All", "Low risk", "Medium risk", "High risk"];

    const colors = ["#32CCAE", "#FF3D1D", "#6562E5"];

    const { setNavName, setOnClick, setShowNavIcon } = useNav();

    useEffect(() => {
        setNavName("Suppliers");
        setOnClick(() => () => {
            navigate(-1);
        });
        setShowNavIcon(true);
    }, [setNavName, setOnClick, setShowNavIcon]);

    const {
        fetchingSupplierAnalytics,
        fetchSupplierAnalyticsSuccess,
        fetchingLdbVendor,
        fetchLdbVendorSuccess,
        // deletingSupplier,
        deleteSupplierSuccess,
        deleteSupplierFailure,
    } = useAppSelector((state) => state?.vendor);

    useEffect(() => {
        setSearch("");
        setDebouncedSearch("");
        if (activeTab === 1) {
            setRisk("low");
        } else if (activeTab === 2) {
            setRisk("medium");
        } else if (activeTab === 3) {
            setRisk("high");
        } else {
            setRisk("");
        }
    }, [activeTab]);

    useEffect(() => {
        if (Boolean(fetchSupplierAnalyticsSuccess)) {
            setSupplierAnalytics(fetchSupplierAnalyticsSuccess);
        }
    }, [fetchSupplierAnalyticsSuccess]);

    useEffect(() => {
        dispatch(vendorActions?.fetchSupplierAnalytics(changeValue));
    }, [dates, changeValue]);

    useEffect(() => {
        if (Boolean(fetchLdbVendorSuccess)) {
            setLdbVendorList((prev) =>
                page > 1
                    ? uniqBy([...prev, ...fetchLdbVendorSuccess], "_id")
                    : [...fetchLdbVendorSuccess]
            );
        }
    }, [fetchLdbVendorSuccess, dispatch]);

    useEffect(() => {
        dispatch(vendorActions?.fetchVendors(debouncedSearch, risk, limit, page));
    }, [dispatch, debouncedSearch, risk, page]);

    useEffect(() => {
        if (page || debouncedSearch || risk) {
            setPage(1);
        }
    }, [page, debouncedSearch, risk]);
    // useEffect(() => {
    //     let period, number;
    //     if (changeValue === "six_month") {
    //         number = 6;
    //         period = "month";
    //     } else {
    //         period = changeValue;
    //     }
    //     if (period) {
    //         const values = periodStartDateAndEndDate(period, number);
    //         setDates(values);
    //     } else {
    //         setDates({});
    //     }
    // }, [changeValue]);

    const checkRisk = (num) => {
        if (num >= 0 && num <= 4) {
            return {
                value: "Low",
                img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712468413/Frame_2615019_1_oyxqr8.svg",
            };
        } else if (num >= 5 && num <= 7) {
            return {
                value: "Medium",
                img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712468414/Frame_2615019_2_yz9dkz.svg",
            };
        }
        if (num >= 8 && num <= 10) {
            return {
                value: "High",
                img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712468414/Frame_2615019_3_rj8hoz.svg",
            };
        }
    };

    const handleDebouncedSearch = debounce((e) => {
        setDebouncedSearch(e.target?.value);
    }, 1000);

    const pieData = [
        {
            value: "1 - 4",
            name: "Low risk suppliers",
            name2: "Low risk",
            vendor: 3,
            color: colors[0],
        },
        {
            value: "5 - 7",
            name: "Medium risk suppliers",
            name2: "Medium risk",
            vendor: 3,
            color: colors[2],
        },
        {
            value: "8 - 10",
            name: "High risk suppliers",
            name2: "High risk",
            vendor: 3,
            color: colors[1],
        },
    ];

    const option = {
        backgroundColor: "transparent",
        title: {
            left: "center",
            top: 20,
            textStyle: {
                color: "#ccc",
            },
        },
        tooltip: {
            trigger: "item",
        },
        visualMap: {
            show: false,
            min: 80,
            max: 600,
            inRange: {},
        },
        series: [
            {
                name: "Legend",
                type: "pie",
                radius: ["10%", "90%"],
                center: ["50%", "50%"],
                data: [
                    {
                        value: supplierAnalytics?.analytics?.[0]?.lowRiskCount,
                        name: "Low risk suppliers ",
                        itemStyle: { color: colors[0] },
                    },
                    {
                        value: supplierAnalytics?.analytics?.[0]?.mediumRiskCount,
                        name: "Medium risk suppliers ",
                        itemStyle: { color: colors[2] },
                    },
                    {
                        value: supplierAnalytics?.analytics?.[0]?.highRiskCount,
                        name: "High risk suppliers",
                        itemStyle: { color: colors[1] },
                    },
                ].sort(function (a, b) {
                    return a.value - b.value;
                }),
                roseType: "radius",
                label: {
                    color: "rgba(255, 255, 255, 0.3)",
                },
                labelLine: {
                    lineStyle: {
                        color: "rgba(255, 255, 255, 0.3)",
                    },
                    smooth: 0.2,
                    length: 10,
                    length2: 20,
                },
                itemStyle: {
                    color: colors[0],
                },
                animationType: "scale",
                animationEasing: "elasticOut",
                animationDelay: function (idx) {
                    return Math.random() * 200;
                },
            },
        ],
    };

    const tableHeader = [
        { title: "Supplier", widthClass: "w-[17%]" },
        { title: "Contact", widthClass: "w-[12%]" },
        // { title: "Phone number", widthClass: "w-[9.8%]" },
        { title: "Source", widthClass: "w-[15%]" },
        { title: "Risk status", widthClass: "w-[9.8%]" },
        // { title: "Address", widthClass: "w-[9.8%]" },
        { title: "", widthClass: "w-[5.1%]" },
    ];
    console.log("ldbVendorList", ldbVendorList);
    const tableBody = ldbVendorList?.map((vendor, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    {vendor?.vendorData?.profile?.businessName ? (
                        <div className="flex items-center space-x-3">
                            <VendorAvatar
                                imageSrc={vendor?.vendorData?.profile?.logo}
                                containerClassname="!rounded-none"
                                size={32}
                                name={vendor?.vendorData?.profile?.businessName}
                            />

                            <span>{vendor?.vendorData?.profile?.businessName}</span>
                        </div>
                    ) : (
                        "N/A"
                    )}
                </div>
            ),
            cellClickAction: () => navigate(`/dashboard/suppliers/${vendor?.vendorId}`),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    {vendor?.source === "online" ? (
                        vendor?.vendorData?.profile?.website || "N/A"
                    ) : vendor?.vendorData?.firstName || vendor?.vendorData?.lastName ? (
                        <div className="flex items-center space-x-2">
                            <VendorAvatar
                                imageSrc={vendor?.vendorData?.profile?.avatar}
                                size={24}
                                name={vendor?.vendorData?.firstName || vendor?.vendorData?.lastName}
                            />
                            <span>
                                {vendor?.vendorData?.firstName ?? ""}{" "}
                                {vendor?.vendorData?.lastName ?? ""}
                            </span>
                        </div>
                    ) : (
                        "N/A"
                    )}
                </div>
            ),
        },
        // {
        //     content: (
        //         <div
        //             className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-gm-50 `}
        //         >
        //             <span className="">{`${vendor?.mobileExtension ?? ""}${
        //                 vendor?.mobile ?? ""
        //             }`}</span>
        //         </div>
        //     ),
        // },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-gm-50 `}
                >
                    {vendor?.source?.toLowerCase() === "online" ? (
                        <div className="flex items-center space-x-2">
                            <i className="ri-earth-line text-[#142837]"></i>
                            <p className="text-sm font-rocGroteskMedium text-[#142837]">Online</p>
                        </div>
                    ) : (
                        <div className="flex items-center space-x-2">
                            <i className="ri-store-2-line text-[#142837]"></i>
                            <p className="text-sm font-rocGroteskMedium text-[#142837]">Offline</p>
                        </div>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    {vendor?.vendorData?.riskDetails?.riskScore ? (
                        <div className="flex items-center space-x-1">
                            <p className="font-rocGroteskMedium text-[#64748B] pr-1">
                                {checkRisk(vendor?.vendorData?.riskDetails?.riskScore)?.value} risk
                            </p>
                            <img
                                src={checkRisk(vendor?.vendorData?.riskDetails?.riskScore)?.img}
                                className="h-[18px] w-[24px]"
                                alt=""
                            />
                        </div>
                    ) : (
                        "N/A"
                    )}
                </div>
            ),
        },
        // {
        //     content: (
        //         <div
        //             className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
        //         >
        //             <span>
        //                 {vendor?.vendorData?.profile?.address?.city}
        //                 {vendor?.vendorData?.profile?.address?.country ? ", " : ""}
        //                 {vendor?.vendorData?.profile?.address?.country}
        //             </span>
        //         </div>
        //     ),
        // },

        {
            content: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Edit Details",
                                value: "Edit details",
                                action: () => {
                                    navigate(
                                        `/dashboard/suppliers/edit-supplier/${vendor?.vendorId}`
                                    );
                                },
                            },
                            {
                                label: "View Details",
                                value: "view details",
                                action: () => {
                                    navigate(`/dashboard/suppliers/${vendor?.vendorId}`);
                                },
                            },
                            {
                                label: "Delete supplier",
                                value: "Delete supplier",
                                action: () => {
                                    setConfirmDeleteModal(true);
                                    setShowModal(true);
                                    setDeleteSupplier({
                                        id: vendor?.vendorId,
                                        name: vendor?.vendorData?.profile?.businessName,
                                    });
                                },
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const productTableHeader = [
        { title: "Product category", widthClass: "w-[10.8%]" },
        { title: "Product name", widthClass: "w-[10.8%]" },
        { title: "Qty", widthClass: "w-[10.6%]" },
        { title: "Total cost", widthClass: "w-[9.8%]" },
        { title: "Qty returned", widthClass: "w-[9.8%]" },
    ];

    const productTableBody = [1, 2, 3]?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-gm-50 `}
                >
                    <span className="">Grains</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <span className="">100</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <span className="">$100</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <span className="">$100</span>
                </div>
            ),
        },
        {
            content: (
                <div className={`font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}>
                    <SelectInput
                        value={""}
                        name="supplierCategory"
                        inputPlaceholder={"Qty"}
                        handleInputChange={debounce((evt) => {}, 800)}
                        searchLoading={false}
                        handleChange={() => {}}
                        isRequired={true}
                        className=""
                        dropdownOptions={[1, 2, 3]?.map((res) => ({
                            label: (
                                <div className="font-rocGroteskMedium -mt-2">
                                    <p className="text-slate-700 ">1</p>
                                </div>
                            ),
                            value: "1",
                        }))}
                    />
                </div>
            ),
        },
    ]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return fetchingLdbVendor && !debouncedSearch ? (
                    <div className="my-8">
                        <Loader size={8} />
                    </div>
                ) : ldbVendorList?.length >= 1 ? (
                    <div className="">
                        <div className="hidden lg:block">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-100"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="lg:hidden space-y-2.5">
                            {ldbVendorList?.map((vendor, idx) => {
                                return (
                                    <div
                                        className="border border-slate-200 rounded px-4 py-3.5 font-rocGroteskMedium flex gap-2 justify-between items-center"
                                        key={idx}
                                    >
                                        <div className="w-full">
                                            <div className="flex gap-2">
                                                <div
                                                    className={`text-gm-50 text-sm border-r border-slate-200 pr-4`}
                                                >
                                                    <p>
                                                        {vendor?.vendorData?.profile?.businessName}
                                                    </p>
                                                </div>

                                                <div className={`text-gm-50 text-sm`}>
                                                    <div className="flex items-center space-x-2">
                                                        {(vendor?.vendorData?.firstName ||
                                                            vendor?.vendorData?.secondName) && (
                                                            <VendorAvatar
                                                                imageSrc={
                                                                    vendor?.vendorData?.profile
                                                                        ?.avatar
                                                                }
                                                                size={24}
                                                                name={vendor?.vendorData?.firstName}
                                                            />
                                                        )}
                                                        <span>
                                                            {vendor?.vendorData?.firstName ?? ""}{" "}
                                                            {vendor?.vendorData?.lastName ?? ""}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex gap-2 mt-3">
                                                <div
                                                    className={`text-[#64748B] text-xs border-r border-slate-200 pr-4`}
                                                >
                                                    <p className="">{vendor?.vendorData?.email}</p>
                                                </div>
                                                <div className="text-[#64748B] text-xs">
                                                    <p>
                                                        {" "}
                                                        {`${vendor?.mobileExtension ?? ""}${
                                                            vendor?.mobile ?? ""
                                                        }`}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`font-rocGroteskMedium border-slate-100 text-sm  `}
                                        >
                                            {vendor?.vendorData?.riskDetails?.riskScore && (
                                                <img
                                                    src={
                                                        checkRisk(
                                                            vendor?.vendorData?.riskDetails
                                                                ?.riskScore
                                                        )?.img
                                                    }
                                                    className="h-[18px] w-[24px]"
                                                    alt=""
                                                />
                                            )}
                                        </div>

                                        <div
                                            className={`  text-sm text-slate-800 `}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <Dropdown
                                                dropdown={
                                                    <i className="ri-more-2-fill text-lg text-slate-500"></i>
                                                }
                                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                                                dropdownItemsClasses={``}
                                                name={"faq-dropdown"}
                                                dropdownOptions={[
                                                    {
                                                        label: "Edit Details",
                                                        value: "Edit details",
                                                        action: () => {
                                                            navigate(
                                                                `/dashboard/suppliers/edit-supplier/${vendor?.vendorId}`
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: "View Details",
                                                        value: "view details",
                                                        action: () => {
                                                            navigate(
                                                                `/dashboard/suppliers/${vendor?.vendorId}`
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: "Delete supplier",
                                                        value: "Delete supplier",
                                                        action: () => {
                                                            setConfirmDeleteModal(true);
                                                            setShowModal(true);
                                                            setDeleteSupplier({
                                                                id: vendor?.vendorId,
                                                                name: vendor?.vendorData?.profile
                                                                    ?.businessName,
                                                            });
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : ldbVendorList?.length < 1 && debouncedSearch ? (
                    <div className="flex items-center justify-center flex-col mt-20">
                        <p className="text-gm-40 text-sm font-rocGroteskMedium max-w-[330px] text-center mb-6">
                            No data matches your search query
                        </p>
                    </div>
                ) : (
                    <div className="flex items-center justify-center flex-col mt-20">
                        <i className="ri-user-line text-gm-40 text-xl font-rocGroteskMedium mb-6"></i>
                        <p className="text-gm-40 text-sm font-rocGroteskMedium max-w-[330px] text-center mb-6">
                            It appears you haven’t onboarded any suppliers yet. Begin by adding
                            suppliers to populate this section and streamline your process.
                        </p>
                        <Button
                            btnText={"Add Supplier"}
                            type={"button"}
                            icon={<i className="ri-user-add-fill text-base"></i>}
                            btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                            onClick={() => navigate("/dashboard/suppliers/create-supplier")}
                        />
                    </div>
                );

            default:
                return fetchingLdbVendor && !debouncedSearch ? (
                    <div className="my-8">
                        <Loader size={8} />
                    </div>
                ) : ldbVendorList?.length >= 1 ? (
                    <div className="">
                        <div className="hidden lg:block">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-100"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="lg:hidden space-y-2.5">
                            {ldbVendorList?.map((vendor, idx) => {
                                return (
                                    <div
                                        className="border border-slate-200 rounded px-4 py-3.5 font-rocGroteskMedium flex gap-2 justify-between items-center"
                                        key={idx}
                                    >
                                        <div className="w-full">
                                            <div className="flex gap-2">
                                                <div
                                                    className={`text-gm-50 text-sm border-r border-slate-200 pr-4`}
                                                >
                                                    <p>
                                                        {vendor?.vendorData?.profile?.businessName}
                                                    </p>
                                                </div>

                                                <div className={`text-gm-50 text-sm`}>
                                                    <div className="flex items-center space-x-2">
                                                        {(vendor?.vendorData?.firstName ||
                                                            vendor?.vendorData?.secondName) && (
                                                            <VendorAvatar
                                                                imageSrc={
                                                                    vendor?.vendorData?.profile
                                                                        ?.avatar
                                                                }
                                                                size={24}
                                                                name={vendor?.vendorData?.firstName}
                                                            />
                                                        )}
                                                        <span>
                                                            {vendor?.vendorData?.firstName ?? ""}{" "}
                                                            {vendor?.vendorData?.lastName ?? ""}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex gap-2 mt-3">
                                                <div
                                                    className={`text-[#64748B] text-xs border-r border-slate-200 pr-4`}
                                                >
                                                    <p className="">{vendor?.vendorData?.email}</p>
                                                </div>
                                                <div className="text-[#64748B] text-xs">
                                                    <p>
                                                        {" "}
                                                        {`${vendor?.mobileExtension ?? ""}${
                                                            vendor?.mobile ?? ""
                                                        }`}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`font-rocGroteskMedium border-slate-100 text-sm  `}
                                        >
                                            {vendor?.vendorData?.riskDetails?.riskScore && (
                                                <img
                                                    src={checkRisk(risk)?.img}
                                                    className="h-[18px] w-[24px]"
                                                    alt=""
                                                />
                                            )}
                                        </div>

                                        <div
                                            className={`  text-sm text-slate-800 `}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <Dropdown
                                                dropdown={
                                                    <i className="ri-more-2-fill text-lg text-slate-500"></i>
                                                }
                                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                                                dropdownItemsClasses={``}
                                                name={"faq-dropdown"}
                                                dropdownOptions={[
                                                    {
                                                        label: "Edit Details",
                                                        value: "Edit details",
                                                        action: () => {
                                                            navigate(
                                                                `/dashboard/suppliers/edit-supplier/${vendor?.vendorId}`
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: "View Details",
                                                        value: "view details",
                                                        action: () => {
                                                            navigate(
                                                                `/dashboard/suppliers/${vendor?.vendorId}`
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: "Delete supplier",
                                                        value: "Delete supplier",
                                                        action: () => {
                                                            setConfirmDeleteModal(true);
                                                            setShowModal(true);
                                                            setDeleteSupplier({
                                                                id: vendor?.vendorId,
                                                                name: vendor?.vendorData?.profile
                                                                    ?.businessName,
                                                            });
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : ldbVendorList?.length < 1 && debouncedSearch ? (
                    <div className="flex items-center justify-center flex-col mt-20">
                        <p className="text-gm-40 text-sm font-rocGroteskMedium max-w-[330px] text-center mb-6">
                            No data matches your search query
                        </p>
                    </div>
                ) : (
                    <div className="flex items-center justify-center flex-col mt-20">
                        <i className="ri-user-line text-gm-40 text-xl font-rocGroteskMedium mb-6"></i>
                        <p className="text-gm-40 text-sm font-rocGroteskMedium max-w-[330px] text-center mb-6">
                            {`Looks like there are no ${risk} risk suppliers to display at the moment..`}
                        </p>
                    </div>
                );
        }
    };

    const firstTab = () => {
        return (
            <div className="">
                <div className="lg:hidden flex justify-between items-center">
                    <div className="flex items-center space-x-[14px]">
                        <Dropdown
                            handleChange={(name, value) => {
                                setChangeValue(value);
                            }}
                            dropdown={
                                <div className="flex text-gm-50 whitespace-nowrap border border-n-20 items-center rounded-[5px] h-[32px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                                    <i className="ri-filter-3-line text-[#142837]"></i>

                                    <div
                                        className={`text-slate-800 font-rocGroteskMedium text-[13px]`}
                                    >
                                        {values.find((item) => item.value === changeValue)?.label ||
                                            "Filter"}
                                    </div>
                                </div>
                            }
                            dropdownItemsClasses={`!px-0 !py-0`}
                            dropdownContainerClasses={`shadow-cardShadow-4  !z-[150] border border-slate-100  rounded !max-h-fit
                            `}
                            dropdownOptions={[
                                ...values?.map((item, idx) => ({
                                    label: (
                                        <span
                                            key={idx}
                                            className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[159px] px-4 py-[10px]"
                                        >
                                            {item.label}
                                            {/* {idx === values?.length - 1 && (
                                                    <i className="ri-arrow-right-s-line text-gm-50 text-xs "></i>
                                                )} */}
                                        </span>
                                    ),
                                    value: item.value,
                                })),
                            ]}
                        />

                        {/* <div className="flex text-gm-50 whitespace-nowrap border border-n-20 items-center h-[32px] rounded-[5px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                            <i className="ri-share-forward-line text-sm mt-[-2px]"></i>

                            <p className="font-rocGroteskMedium text-sm">Share</p>
                        </div> */}
                    </div>

                    <Button
                        btnText={"Add Supplier"}
                        type={"button"}
                        icon={<i className="ri-user-add-fill text-base"></i>}
                        btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full !text-[13px] lg:!text-sm"
                        onClick={() => navigate("/dashboard/suppliers/create-supplier")}
                    />
                </div>

                <div className="grid grid-cols-2 gap-2.5 lg:flex mt-6 lg:mt-10 pb-4 border-b border-dotted border-[#E0E0E0] justify-between w-full">
                    <div className="shadow-boxShadow10 p-3  lg:shadow-none flex w-full items-center justify-between lg:py-0 lg:px-3 lg:border-r border-slate-100">
                        <div className="space-y-3">
                            <h3 className="text-[10px] lg:text-base text-[#64748B] font-rocGroteskMedium">
                                Total suppliers
                            </h3>
                            <h6 className="text-xl lg:text-4xl font-rocGroteskMedium text-[#22214C]">
                                {supplierAnalytics?.analytics?.[0]?.totalSuppliers || 0}
                            </h6>
                        </div>
                        <div className="h-6 w-6 bg-white shadow-greenShadow rounded-full lg:rounded flex items-center justify-center">
                            <i className="ri-user-line text-[18px] text-gm-50"></i>
                        </div>
                    </div>
                    <div className=" shadow-boxShadow10 p-3  lg:shadow-none flex w-full items-center justify-between lg:py-0 lg:px-3 lg:border-r border-slate-100">
                        <div className="space-y-3">
                            <h3 className="text-[10px] lg:text-base text-[#64748B] font-rocGroteskMedium">
                                Low risk supplier
                            </h3>
                            <h6 className="text-xl lg:text-4xl font-rocGroteskMedium text-[#22214C]">
                                {supplierAnalytics?.analytics?.[0]?.lowRiskCount || 0}
                            </h6>
                        </div>
                        <div className="h-6 w-6 bg-[#16C6A4] shadow-greenShadow rounded-full lg:rounded flex items-center justify-center">
                            <i className="ri-user-line text-[18px] text-white"></i>
                        </div>
                    </div>
                    <div className="shadow-boxShadow10 p-3  lg:shadow-none flex w-full items-center justify-between lg:py-0 lg:px-3 lg:border-r border-slate-100">
                        <div className="space-y-3">
                            <h3 className="text-[10px] lg:text-base text-[#64748B] font-rocGroteskMedium">
                                Medium risk supplier
                            </h3>
                            <h6 className="text-xl lg:text-4xl font-rocGroteskMedium text-[#22214C]">
                                {supplierAnalytics?.analytics?.[0]?.mediumRiskCount || 0}
                            </h6>
                        </div>
                        <div className="h-6  w-6 bg-[#6562E5] shadow-greenShadow rounded-full lg:rounded flex items-center justify-center">
                            <i className="ri-user-line text-[18px] text-white"></i>
                        </div>
                    </div>
                    <div className="shadow-boxShadow10 p-3  lg:shadow-none flex w-full items-center justify-between lg:py-0 lg:px-3">
                        <div className="space-y-3">
                            <h3 className="text-[10px] lg:text-base text-[#64748B] font-rocGroteskMedium">
                                High risk supplier
                            </h3>
                            <h6 className="text-xl lg:text-4xl font-rocGroteskMedium text-[#22214C]">
                                {supplierAnalytics?.analytics?.[0]?.highRiskCount || 0}
                            </h6>
                        </div>
                        <div className="h-6 w-6 bg-[#FF3D1D] shadow-greenShadow rounded-full lg:rounded flex items-center justify-center">
                            <i className="ri-user-line text-[18px] text-white"></i>
                        </div>
                    </div>
                </div>
                <div className="mt-6  w-full lg:space-x-4 lg:flex items-stretch pb-4 border-b border-dashed lg:border-dotted border-[#E0E0E0]">
                    <div className="lg:w-[50%] border-b border-dashed border-[#E0E0E0] lg:boder-none">
                        {fetchingSupplierAnalytics ? (
                            <div className="flex items-center justify-center h-[400px]">
                                <Loader size={8} />
                            </div>
                        ) : (
                            <div className="flex flex-col w-full h-full bg-white lg:shadow-faintShadow lg:px-4 pb-6 lg:py-6 rounded-[12px]  font-rocGrotesk pr-[30px]">
                                <div className="flex items-center gap-3">
                                    <p className="text-gm-50 font-rocGroteskMedium text-base">
                                        Analytics
                                    </p>

                                    {supplierAnalytics?.analytics?.[0]?.totalSuppliers < 1 && (
                                        <div className="flex items-center gap-3">
                                            <p className="h-[13px] w-[1px] text-slate-400 border-l border-slate-400">
                                                {" "}
                                            </p>
                                            <div className="text-[#FF3D1D] font-rocGroteskMedium bg-[rgba(255,61,29,0.03)] text-sm px-3 py-1 rounded-full ">
                                                No data entry
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="flex lg:block items-center">
                                    <div className=" w-full  lg:flex justify-center items-center !font-rocGrotesk">
                                        <div className="w-full lg:w-[60%]" ref={chartRef}>
                                            {supplierAnalytics?.analytics?.[0]?.totalSuppliers >=
                                            1 ? (
                                                <ReactEcharts option={option} />
                                            ) : (
                                                <div className="w-[210px] h-[210px] bg-slate-400 relative rounded-full">
                                                    <div className="absolute rounded-full top-[45%] left-[45%] bg-white h-6 w-6"></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex mt-14  flex-col gap-[10px]">
                                        <h3 className="text-xs lg:text-base text-[#64748B] font-rocGroteskMedium">
                                            Legend
                                        </h3>
                                        {pieData?.map((item, idx) => {
                                            return (
                                                <div
                                                    className="flex gap-[10px] items-center w-[154px] lg:w-auto"
                                                    key={idx}
                                                >
                                                    <div
                                                        className="w-3 h-3 rounded-full lg:rounded-[3px]"
                                                        style={{
                                                            backgroundColor: item.color,
                                                        }}
                                                    ></div>
                                                    <p className="text-[#0F172A] text-[13px] font-rocGroteskMedium">
                                                        <span className="hidden lg:inline-block">
                                                            {item?.name}
                                                        </span>
                                                        <span className="text-slate-500 hidden lg:inline-block">
                                                            - score ( {item?.value} )
                                                        </span>

                                                        <span className="lg:hidden border-r border-r-slate-200 px-2">
                                                            {item?.name2}
                                                        </span>

                                                        <span className="text-slate-500 lg:hidden pl-2">
                                                            {item?.value}
                                                        </span>
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="lg:w-[50%]">
                        {fetchingSupplierAnalytics ? (
                            <div className="flex items-center justify-center h-[400px]">
                                <Loader size={8} />
                            </div>
                        ) : (
                            <div className="flex flex-col w-full h-full  bg-white lg:shadow-faintShadow lg:px-4 py-6 rounded-[12px]  font-rocGrotesk pr-[30px]">
                                <div className="flex items-center gap-3">
                                    <p className="text-[#64748B] font-rocGroteskMedium text-base">
                                        Top 5 responsive supplier
                                    </p>

                                    {supplierAnalytics?.topSuppliers?.length < 1 && (
                                        <div className="flex items-center gap-3">
                                            <p className="h-[13px] w-[1px] text-slate-400 border-l border-slate-400">
                                                {" "}
                                            </p>
                                            <div className="text-[#FF3D1D] font-rocGroteskMedium bg-[rgba(255,61,29,0.03)] text-sm px-3 py-1 rounded-full ">
                                                No data entry
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {supplierAnalytics?.topSuppliers?.length >= 1 ? (
                                    <div className="space-y-3">
                                        {supplierAnalytics?.topSuppliers?.map((supplier, idx) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    className="mt-5 flex px-[14px] py-[16px] border border-[#E2E8F0] rounded items-center space-x-3"
                                                >
                                                    {/* <div>
                                                        <VendorAvatar
                                                            containerClassname="!rounded-[4px]"
                                                            imageSrc={supplier?.vendorProfile?.logo}
                                                            name={
                                                                supplier?.vendorProfile
                                                                    ?.businessName
                                                            }
                                                            size={40}
                                                        />
                                                    </div> */}
                                                    <div>
                                                        <div className="flex space-x-3 items-center">
                                                            <p className="pr-3 border-r text-base text-gm-50 border-[#E2E8F0] font-rocGroteskMedium">
                                                                {
                                                                    supplier?.vendorProfile
                                                                        ?.businessName
                                                                }
                                                            </p>
                                                            <VendorAvatar
                                                                size={24}
                                                                imageSrc={
                                                                    supplier?.vendorProfile?.avatar
                                                                }
                                                                name={supplier?.firstName}
                                                            />
                                                            {supplier?.firstName && (
                                                                <p className="text-base text-gm-50 font-rocGroteskMedium">
                                                                    {`${supplier?.firstName} ${supplier?.lastName}`}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="flex items-center space-x-3">
                                                            <p className="pr-3 border-r border-[#E2E8F0] text-sm font-rocGroteskMedium text-[#64748B]">
                                                                Risk score :{` `}
                                                                <span className="text-[#16C6A4]">
                                                                    {
                                                                        supplier?.riskDetails
                                                                            ?.riskScore
                                                                    }
                                                                </span>
                                                            </p>
                                                            {supplier?.riskDetails?.riskScore >=
                                                                0 && (
                                                                <>
                                                                    <p className="text-sm font-rocGroteskMedium text-[#64748B] ">
                                                                        {`
                                                                    ${
                                                                        checkRisk(
                                                                            supplier?.riskDetails
                                                                                ?.riskScore
                                                                        )?.value
                                                                    }
                                                                risk `}
                                                                    </p>
                                                                    <img
                                                                        src={
                                                                            checkRisk(
                                                                                supplier
                                                                                    ?.riskDetails
                                                                                    ?.riskScore
                                                                            )?.img
                                                                        }
                                                                        className="h-[14px] w-[24px]"
                                                                        alt=""
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-center flex-col mt-20">
                                        <i className="ri-user-line text-gm-40 text-xl font-rocGroteskMedium mb-6"></i>
                                        <p className="text-gm-40 text-md font-rocGroteskMedium max-w-[330px] text-center">
                                            Seems like there are no results to display at the
                                            moment. Check back later or explore other tabs to find
                                            what you’re looking for
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };
    const secondTab = () => {
        return (
            <div className="pt-6">
                <h3 className="text-base font-rocGroteskMedium text-[#64748B] mb-4 hidden lg:block">
                    Onboarded suppliers
                </h3>

                <div className="lg:hidden">
                    <div className="flex w-full items-center space-x-3 my-4 lg:my-0">
                        <TextInput
                            name={"search"}
                            type={"text"}
                            value={search}
                            inputPlaceholder={"Search supplier, Date, Status"}
                            inputClassName={"!h-[34px] pl-[0px] !bg-white text-sm "}
                            containerClassname={""}
                            onChange={(e) => setSearch(e.target?.value)}
                            onInput={handleDebouncedSearch}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px] !h-[40px] w-[410px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                            }
                        />
                        <Button
                            btnText={"Add Supplier"}
                            type={"button"}
                            btnTextClassName="hidden lg:block"
                            icon={<i className="ri-user-add-fill text-base"></i>}
                            btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                            onClick={() => navigate("/dashboard/suppliers/create-supplier")}
                        />
                    </div>
                    <TabsContainer
                        tabs={tabs2}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        component={displayActiveTab()}
                        className="!px-0"
                        itemClassName="!pb-2"
                        borderLineClase={"!text-slate-100 w-[40%] max-sm:w-full mb-4"}
                    />
                </div>

                <div className="hidden lg:block">
                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        component={displayActiveTab()}
                        className="!px-0"
                        itemClassName="!pb-2"
                        borderLineClase={"!text-slate-100 w-[40%] max-sm:w-full w-[50%] mb-4"}
                        showButton={
                            <div className="w-full flex items-center space-x-3 my-4 lg:my-0 !mb-4">
                                <TextInput
                                    name={"search"}
                                    type={"text"}
                                    value={search}
                                    inputPlaceholder={"Search supplier"}
                                    inputClassName={"!h-[34px] pl-[0px] !bg-white text-sm "}
                                    containerClassname={""}
                                    onChange={(e) => setSearch(e.target?.value)}
                                    onInput={handleDebouncedSearch}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] !h-[40px] w-[410px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                    }
                                />
                                <Button
                                    btnText={"Add Supplier"}
                                    type={"button"}
                                    btnTextClassName="block"
                                    icon={<i className="ri-user-add-fill text-base"></i>}
                                    btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                    onClick={() => navigate("/dashboard/suppliers/create-supplier")}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
        );
    };

    return (
        <PageFrame isLoading={fetchingSupplierAnalytics && fetchingLdbVendor}>
            <div className="bg-white lg:border lg:rounded-[8px] lg:p-4 lg:border-[#F1F5F9]">
                <div className="w-full bg-[#F8FAFC] py-4 px-6 rounded flex items-center space-x-5 mb-6 lg:hidden">
                    {title?.map((content, idx) => (
                        <div
                            key={idx}
                            onClick={() => {
                                setTitleId(content?.id);
                            }}
                            className={`${
                                idx === titleId ? "bg-white shadow-sm" : ""
                            } cursor-pointer text-sm  px-[16px] py-[7px] rounded-[6px] text-[#0F172A] font-rocGroteskMedium`}
                        >
                            {content?.name}
                        </div>
                    ))}
                </div>
                <div className="hidden lg:block">
                    <PageTitleSection
                        leftComp={
                            <h3 className="text-xl font-rocGroteskMedium">Supplier Management</h3>
                        }
                        rightComp={
                            <div className="flex items-center space-x-[14px]">
                                <Dropdown
                                    handleChange={(name, value) => {
                                        setChangeValue(value);
                                    }}
                                    dropdown={
                                        <div className="flex text-gm-50 whitespace-nowrap border border-n-20 items-center rounded-[5px] h-[32px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                                            <i className="ri-filter-3-line text-[#142837]"></i>

                                            <div
                                                className={`text-slate-800 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {values.find((item) => item.value === changeValue)
                                                    ?.label || "Filter"}
                                            </div>
                                        </div>
                                    }
                                    dropdownItemsClasses={`!px-0 !py-0`}
                                    dropdownContainerClasses={`shadow-cardShadow-4  !z-[150] border border-slate-100  rounded !max-h-fit
                            `}
                                    dropdownOptions={[
                                        ...values?.map((item, idx) => ({
                                            label: (
                                                <span
                                                    key={idx}
                                                    className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[159px] px-4 py-[10px]"
                                                >
                                                    {item.label}
                                                    {/* {idx === values?.length - 1 && (
                                                    <i className="ri-arrow-right-s-line text-gm-50 text-xs "></i>
                                                )} */}
                                                </span>
                                            ),
                                            value: item.value,
                                        })),
                                    ]}
                                />

                                {/* <div className="flex text-gm-50 whitespace-nowrap border border-n-20 items-center h-[32px] rounded-[5px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                                    <i className="ri-share-forward-line text-sm mt-[-2px]"></i>

                                    <p className="font-rocGroteskMedium text-sm">Share</p>
                                </div> */}
                            </div>
                        }
                    />
                </div>

                <div className="hidden lg:block">
                    {firstTab()}
                    {secondTab()}
                </div>
                <div className="lg:hidden">
                    {titleId === 0 && firstTab()}
                    {titleId === 1 && secondTab()}
                </div>
                <ModalContainer
                    open={initiateReturn}
                    showCloseIcon={false}
                    tailwindClassName="w-[553px]"
                    closeModal={() => {
                        setInitiateReturn(false);
                    }}
                >
                    <div className="bg-white p-6  space-y-1.5 rounded-lg shadow-cardShadow relative">
                        <div className="flex justify-end">
                            <div className="text-base cursor-pointer w-fit">
                                <i className="ri-close-fill text-[#42526E]"></i>
                            </div>
                        </div>
                        <h3 className="text-base text-[#64748B] font-rocGroteskMedium">
                            Select the PO you wish to initiate a return for
                        </h3>

                        <div className="mt-3">
                            <SelectInput
                                value={""}
                                name="supplierCategory"
                                placeholder={"Select supplier category"}
                                handleInputChange={debounce((evt) => {}, 800)}
                                searchLoading={false}
                                handleChange={() => {}}
                                isRequired={true}
                                dropdownOptions={[1, 2, 3]?.map((res) => ({
                                    label: (
                                        <div className="font-rocGroteskMedium -mt-2">
                                            <p className="text-slate-700 ">Name</p>
                                            <p className="text-slate-500 ">Address</p>
                                        </div>
                                    ),
                                    value: "1",
                                }))}
                            />
                            <div className="mt-5 pb-3">
                                <Button
                                    btnText={"Next"}
                                    type={"button"}
                                    btnClassname="!py-3 !px-4  max-sm:w-full !border !border-[#F4F5F7]"
                                    onClick={() => {
                                        setEditReturn(true);
                                        setInitiateReturn(false);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
                <ModalContainer
                    open={editReturn}
                    showCloseIcon={false}
                    tailwindClassName="w-[844px] overflow-y-auto h-[500px]"
                    closeModal={() => {
                        setEditReturn(false);
                    }}
                >
                    <div className="bg-white p-6   space-y-1.5 rounded-lg shadow-cardShadow relative">
                        <div className="flex items-center w-full pb-4 border-b border-dotted border-[#E0E0E0]">
                            <div className="flex space-x-2 items-center w-full">
                                <div className="w-[60px] h-[60px] bg-[#F1F5F9] flex justify-center items-center">
                                    <VendorAvatar
                                        size={40}
                                        imageSrc=""
                                        containerClassname="!rounded-none"
                                    />
                                </div>
                                <div>
                                    <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                        Supplier
                                    </p>
                                    <p className="text-lg text-gm-50 font-rocGroteskMedium">
                                        Flexport Inc
                                    </p>
                                </div>
                            </div>
                            <div className="w-full">
                                <p className="text-gm-50 text-sm font-rocGroteskMedium">PO ID</p>
                                <p className="text-p-50 text-sm font-rocGroteskMedium underline">
                                    PO 123
                                </p>
                            </div>
                        </div>
                        <div className="pt-3">
                            <p className="text-base mb-3 font-rocGroteskMedium text-gm-50">
                                Product details
                            </p>

                            <div className="pb-4 border-b border-dotted border-[#E0E0E0]">
                                <CustomTable
                                    tableBody={productTableBody}
                                    tableHeader={productTableHeader}
                                    isScrollable={false}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerContainerClass="!bg-slate-50"
                                    headerItemClass="font-rocGroteskMedium "
                                />
                            </div>
                        </div>
                        <div className="pt-3">
                            <p className="text-base mb-3 font-rocGroteskMedium text-gm-50">
                                Enter the return date
                            </p>

                            <div className="pb-4 border-b border-dotted border-[#E0E0E0]">
                                <TextInput
                                    name={"returnDate"}
                                    type={"date"}
                                    value={""}
                                    onChange={() => {}}
                                    placeholder={"Return date"}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="pt-3">
                            <p className="text-base mb-3 font-rocGroteskMedium text-gm-50">
                                Reason for return
                            </p>

                            <div className="pb-4 ">
                                <TextareaInput
                                    name={"emailBody"}
                                    value={""}
                                    onChange={(e) => {}}
                                    rows={5}
                                    placeholder={"Reason"}
                                    required={true}
                                    containerClassname=""
                                />
                            </div>
                        </div>
                        <div className="flex justify-end w-full">
                            <div className="flex space-x-3 w-[50%] items-center">
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-[#F4F5F7] !px-4 !w-full !border !border-[#F4F5F7]"
                                    onClick={() => {
                                        setEditReturn(true);
                                        setInitiateReturn(false);
                                    }}
                                />
                                <Button
                                    btnText={"Submit return details"}
                                    type={"button"}
                                    btnClassname="!py-3 !px-4  w-full !border !border-[#F4F5F7]"
                                    onClick={() => {
                                        setEditReturn(false);
                                        setInitiateReturn(false);
                                        setShowSuccessRequest(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
                {showModal && (
                    <DeleteSupplierModal
                        setConfirmDeleteModal={setConfirmDeleteModal}
                        confirmDeleteModal={confirmDeleteModal}
                        deleteSupplier={deleteSupplier}
                    />
                )}
            </div>
        </PageFrame>
    );
};

export default Suppliers;
