import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { debounce, uniqBy } from "lodash";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetInventoryAvgCostValue = (fetchData: boolean, scrollContent?: HTMLElement) => {
    const dispatch = useAppDispatch();
    const [rawData, setRawData] = useState<{
        [key: string]: any;
    }>([]);
    const [inventoryAvgCostValue, setInventoryAvgCostValue] = useState<{ [key: string]: any }>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [sortValues, setSortValues] = useState<any>({});
    const [startDateRange, setStartDateRange] = useState<string>("");
    const [endDateRange, setEndDateRange] = useState<string>("");
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [inventoryId, setInventoryId] = useState<string>("");
    const [channel, setChannel] = useState<string>("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string } | any>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent
        );

    const {
        fetchingInventoryAvgCostValue,
        fetchedInventoryAvgCostValueSuccess,
        fetchedInventoryAvgCostValueFailure,
    } = useAppSelector((state) => state.inventory);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e?.target?.value);
    }, 500);

    useEffect(() => {
        if (Boolean(fetchedInventoryAvgCostValueSuccess)) {
            setInventoryAvgCostValue((prev: any) =>
                updatedPage === 1
                    ? uniqBy([...fetchedInventoryAvgCostValueSuccess?.stockInfo], "_id")
                    : uniqBy([...prev, ...fetchedInventoryAvgCostValueSuccess?.stockInfo], "_id")
            );

            setRawData(() => uniqBy([...fetchedInventoryAvgCostValueSuccess?.stockInfo], "_id"));

            setPagination({
                currentPage: fetchedInventoryAvgCostValueSuccess.pagination?.current,
                noOfPages: fetchedInventoryAvgCostValueSuccess.pagination?.number_of_pages,
            });
            // setLoadMore(false);
        }
    }, [fetchedInventoryAvgCostValueSuccess, updatedPage]);

    useEffect(() => {
        if (fetchData) {
            dispatch(
                inventoryActions.fetchInventoryAvgCostValue(
                    warehouseId,
                    channel,
                    10,
                    updatedPage,
                    startDateRange,
                    endDateRange,
                    debouncedSearch,
                    sortValues
                )
            );
            return () => {
                dispatch(inventoryActions.resetfetchInventoryAvgCostValue());
            };
        }
    }, [
        fetchData,
        warehouseId,
        channel,
        updatedPage,
        debouncedSearch,
        startDateRange,
        endDateRange,
        sortValues,
    ]);

    return {
        rawData,
        data: inventoryAvgCostValue,
        isFetching: fetchingInventoryAvgCostValue,
        error: fetchedInventoryAvgCostValueFailure,
        handleDebouncedChange,
        setStartDateRange,
        setEndDateRange,
        pagination,
        setPagination,
        setSortValues,
        setWarehouseId,
        setInventoryId,
        setDebouncedSearch,
        setChannel,
        isLoadingMore,
        debouncedSearch,
        setUpdatedPage,
    };
};

export default useGetInventoryAvgCostValue;
