import Button from "components/common/Button";
import PageTitleSection from "components/common/PageTitleSection";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SalesDashboard from "components/views/Synth2.0/Order/Tabs/SalesDashboard";
import OrderList from "components/views/Synth2.0/Order/Tabs/OrderList";
import Dropdown from "components/common/Dropdown";
import SalesCard from "components/common/SalesCard";
import useGetSalesDashboardStats from "hooks/salesHooks/useGetSalesDashboardStats";
import { formatMoney } from "helpers";
import Skeleton from "react-loading-skeleton";
import useGetSalesOrderList from "hooks/salesHooks/useGetSalesOrderList";

const Order = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [label, setLabel] = useState<string>("This month");
    const [orderListlabel, setOrderListLabel] = useState<string>("This month");
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const currTab = param.get("currTab");
    const tabs = ["Sales dashboard", "Sales list"];

    const dashboardDuration = label?.includes("month")
        ? "monthly"
        : label?.includes("week")
        ? "weekly"
        : "yearly";

    const salesOrderListDuration = orderListlabel?.includes("month")
        ? "monthly"
        : orderListlabel?.includes("week")
        ? "weekly"
        : "yearly";

    const { handleDurationChange } = useGetSalesDashboardStats();
    const { handleDurationChange: handleOrderListDurationChange } = useGetSalesOrderList("");

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <SalesDashboard durationValue={dashboardDuration} />;

            case 1: {
                return <OrderList durationValue={salesOrderListDuration} />;
            }
            default:
                break;
        }
    };

    useEffect(() => {
        if (currTab) {
            setActiveTab(Number(currTab));
        }
    }, [currTab]);

    return (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
            <div className="p-4">
                <PageTitleSection
                    leftComp={<h3 className="text-xl font-rocGroteskMedium">Sales</h3>}
                />
            </div>

            <div className="p-4">
                <div className="flex items-center justify-between mb-6">
                    <div className="w-fit bg-slate-50 rounded p-1 flex items-center space-x-2.5 ">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`px-4 py-[7px] cursor-pointer ${
                                    activeTab === index &&
                                    "bg-white rounded shadow-[0px_4px_10px_0px_#0000000D]"
                                }`}
                                onClick={() => {
                                    setActiveTab(index);
                                    navigate(`/dashboard/order?currTab=${index}`);
                                }}
                            >
                                <span className="text-sm font-rocGroteskMedium">{tab}</span>
                            </div>
                        ))}
                    </div>

                    <div className="flex items-center justify-between space-x-6">
                        <Dropdown
                            name="period"
                            value={""}
                            dropdownTriggerClassName={" "}
                            handleChange={(name, value) => {
                                if (activeTab === 0) {
                                    setLabel(value?.split("-")?.[0]);
                                } else {
                                    setOrderListLabel(value?.split("-")?.[0]);
                                }
                            }}
                            dropdown={
                                <div className="bg-white flex justify-between items-center h-10 space-x-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20 !rounded">
                                    <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                        {activeTab === 0 ? label : orderListlabel}
                                    </span>

                                    <div className="flex items-center space-x-2">
                                        <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                    </div>
                                </div>
                            }
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full max-sm:z-[9999] rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                            dropdownOptions={[
                                {
                                    label: "This week",
                                    value: "This week-weekly",
                                },
                                {
                                    label: "This month",
                                    value: "This month-monthly",
                                },
                            ]}
                        />
                        <Button
                            btnClassname="!px-4 !py-2 !h-10 !w-fit whitespace-nowrap"
                            btnText="Create sales order"
                            icon={<i className="ri-add-circle-line"></i>}
                            onClick={() => navigate("/dashboard/order/create")}
                        />
                    </div>
                </div>

                {displayActiveTab()}
            </div>
        </div>
    );
};

export default Order;
