import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { debounce, uniqBy } from "lodash";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetInventoryOutStockValue = (fetchData: boolean, scrollContent?: HTMLElement) => {
    const dispatch = useAppDispatch();
    const [rawData, setRawData] = useState<{
        [key: string]: any;
    }>([]);
    const [inventoryOutStockValue, setInventoryOutStockValue] = useState<{ [key: string]: any }>(
        []
    );
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [sortValues, setSortValues] = useState<any>({});
    const [startDateRange, setStartDateRange] = useState<string>("");
    const [endDateRange, setEndDateRange] = useState<string>("");
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [inventoryId, setInventoryId] = useState<string>("");
    const [channel, setChannel] = useState<string>("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string } | any>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent
        );

    const {
        fetchingInventoryOutStockValue,
        fetchedInventoryOutStockValueSuccess,
        fetchedInventoryOutStockValueFailure,
    } = useAppSelector((state) => state.inventory);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e?.target?.value);
    }, 500);

    useEffect(() => {
        if (Boolean(fetchedInventoryOutStockValueSuccess)) {
            setInventoryOutStockValue((prev: any) =>
                updatedPage === 1
                    ? uniqBy([...fetchedInventoryOutStockValueSuccess?.stockInfo], "_id")
                    : uniqBy([...prev, ...fetchedInventoryOutStockValueSuccess?.stockInfo], "_id")
            );

            setRawData(() => uniqBy([...fetchedInventoryOutStockValueSuccess?.stockInfo], "_id"));

            setPagination({
                currentPage: fetchedInventoryOutStockValueSuccess.pagination?.current,
                noOfPages: fetchedInventoryOutStockValueSuccess.pagination?.number_of_pages,
            });
            // setLoadMore(false);
        }
    }, [fetchedInventoryOutStockValueSuccess, updatedPage]);

    useEffect(() => {
        if (fetchData) {
            dispatch(
                inventoryActions.fetchInventoryOutStockValue(
                    warehouseId,
                    channel,
                    10,
                    updatedPage,
                    startDateRange,
                    endDateRange,
                    debouncedSearch,
                    sortValues
                )
            );
            return () => {
                dispatch(inventoryActions.resetfetchInventoryOutStockValue());
            };
        }
    }, [
        fetchData,
        warehouseId,
        channel,
        updatedPage,
        debouncedSearch,
        startDateRange,
        endDateRange,
        sortValues,
    ]);

    return {
        rawData,
        data: inventoryOutStockValue,
        isFetching: fetchingInventoryOutStockValue,
        error: fetchedInventoryOutStockValueFailure,
        handleDebouncedChange,
        setStartDateRange,
        setEndDateRange,
        pagination,
        setPagination,
        setSortValues,
        setWarehouseId,
        setInventoryId,
        setDebouncedSearch,
        setChannel,
        isLoadingMore,
        debouncedSearch,
        setUpdatedPage,
    };
};

export default useGetInventoryOutStockValue;
