import React from "react";
import Dropdown from "./Dropdown";
import { useNavigate } from "react-router-dom";

type TaskCardProps = {
    title: string;
    description: string;
    time: string;
    userAttentionRequired?: boolean;
    avatarUrl?: string;
    onClick?: () => void;
};

const TaskCard = ({
    title,
    description,
    time,
    userAttentionRequired,
    avatarUrl,
    onClick,
}: TaskCardProps) => {
    return (
        <div
            onClick={() => onClick?.()}
            className="bg-white rounded-lg border border-slate-200 shadow-[0px_4px_16px_0px_#0000000A] font-rocGroteskMedium cursor-pointer"
        >
            <div className="px-4 py-3 border-b border-slate-200">
                <div className="mb-2">
                    <span className="block text-sm">{title}</span>
                    <span className="block text-[13px] text-slate-500">{description}</span>
                </div>

                <div className="space-y-1">
                    {time && (
                        <div className="w-fit pl-1.5 pr-2 py-1 bg-slate-100 flex items-center space-x-1.5 h-[25px] rounded">
                            <i className="ri-time-line text-base text-slate-500"></i>
                            <span className="text-xs text-slate-500">{time}</span>
                        </div>
                    )}

                    <div
                        className={`w-fit pl-1.5 pr-2 py-1 ${
                            userAttentionRequired ? "bg-[#FEE2E2]" : "bg-slate-100"
                        } flex items-center space-x-1.5 h-[25px] rounded`}
                    >
                        <i
                            className={`ri-information-line text-base ${
                                userAttentionRequired ? "text-[#DC2626]" : "text-slate-500"
                            }`}
                        ></i>
                        <span
                            className={`text-xs ${
                                userAttentionRequired ? "text-[#DC2626]" : "text-slate-500"
                            }`}
                        >
                            {userAttentionRequired
                                ? "User Attention Needed"
                                : "No User Attention Needed"}
                        </span>
                    </div>
                </div>
            </div>
            <div className="px-4 py-3 flex items-center justify-between">
                {avatarUrl ? (
                    <img
                        src={avatarUrl}
                        alt="ai avatar"
                        className="w-5 h-5 rounded-full object-cover"
                    />
                ) : (
                    <div className="w-5 h-5 rounded-full bg-p-50 flex justify-center items-center ">
                        <i className="ri-robot-fill text-white text-[11px] mt-[-2px]"></i>
                    </div>
                )}

                <div onClick={(e) => e.stopPropagation()}>
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-90px] `}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            { label: "View details", value: "view", action: () => onClick?.() },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default TaskCard;
