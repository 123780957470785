import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import { formatMoney, onScroll } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce, truncate, uniqBy } from "lodash";
import moment from "moment";
import React, { act, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";

interface Prop {
    totalApproved: number;
    totalIntransit: number;
    totalPending: number;
    totalPurchaseOrders: number;
    totalRejected: number;
}

const PurchaseOrderRequestList = () => {
    const limit = 10;
    const [activeTab, setActiveTab] = useState(0);
    const [loadMorePurchaseOrder, setLoadMorePurchaseOrder] = useState<boolean>(false);
    const [recommendedTotal, setRecommendedTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [recommendedPage, setRecommendedPage] = useState<number>(1);
    const [statusName, setStatusName] = useState("");
    // const [dashboardContent, setDashboardContent] = useState<HTMLElement | null>(null);
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
    const [totalPO, setTotalPO] = useState<Prop>({
        totalApproved: 0,
        totalIntransit: 0,
        totalPending: 0,
        totalPurchaseOrders: 0,
        totalRejected: 0,
    });
    const [recommendedPurchaseOrderList, setRecommendedPurchaseOrderList] = useState([]);
    const [purchaseOrderPagination, setPurchaseOrderPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [recommendedPurchaseOrderPagination, setRecommendedPurchaseOrderPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });

    const [filter, setFilter] = useState({
        search: "",
        taId: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
    });
    const tabs = ["All", "Recommended"];

    const dashboardContent = document.getElementById("dashboardContent");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        fetchingPurchaseOrder,
        fetchedPurchaseOrderSuccess,
        fetchedPurchaseOrderFailure,
        fetchedTotalPurchaseOrderSuccess,
        fetchingTotalPurchaseOrder,
        fetchingTotalPurchaseRequest,
        fetchedTotalPurchaseRequestSuccess,
    } = useAppSelector((state) => state.order);

    useEffect(() => {
        dispatch(orderActions.resetPurchaseOrderRequest());
        setPage(1);
        setRecommendedPage(1);
        if (activeTab === 0) {
            setPurchaseOrderList([]);
            setStatusName("");
        } else if (activeTab === 1) {
            setPurchaseOrderList([]);
            setRecommendedPurchaseOrderList([]);
            setStatusName("recommended");
        }
    }, [activeTab]);

    const onPurchaseOrderTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                activeTab === 0
                    ? (purchaseOrderPagination?.current as number)
                    : (recommendedPurchaseOrderPagination?.current as number),
                activeTab === 0
                    ? (purchaseOrderPagination?.number_of_pages as number)
                    : (recommendedPurchaseOrderPagination?.number_of_pages as number),
                () => {
                    if (activeTab === 0) {
                        setPage(() => page + 1);
                    }
                    if (activeTab === 1) {
                        setRecommendedPage(() => recommendedPage + 1);
                    }
                    setLoadMorePurchaseOrder(true);
                }
            ),
        [dashboardContent, purchaseOrderPagination, recommendedPurchaseOrderPagination, activeTab]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onPurchaseOrderTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onPurchaseOrderTableScroll);
        };
    }, [dashboardContent, onPurchaseOrderTableScroll]);

    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;
        setPage(1);
        setRecommendedPage(1);
        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);

    const handleSearch = (e) => {
        const { name, value } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        dispatch(orderActions.getTotalPurchaseRequest(20, 1, "", "", "recommended"));
    }, [dispatch]);

    useEffect(() => {
        if (statusName === "recommended") {
            dispatch(
                orderActions.getPurchaseRequest(
                    limit,
                    recommendedPage,
                    debouncedFilter.search,
                    "",
                    "recommended"
                )
            );
        } else {
            dispatch(
                orderActions.getPurchaseRequest(limit, page, debouncedFilter.search, "", statusName)
            );
        }
    }, [dispatch, debouncedFilter.search, page, recommendedPage, limit, statusName]);

    useEffect(() => {
        dispatch(orderActions.getTotalPurchaseOrder());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTotalPurchaseOrderSuccess)) {
            setTotalPO(fetchedTotalPurchaseOrderSuccess?.counters);
        }
    }, [fetchedTotalPurchaseOrderSuccess]);
    useEffect(() => {
        if (Boolean(fetchedTotalPurchaseRequestSuccess)) {
            setRecommendedTotal(fetchedTotalPurchaseRequestSuccess?.total);
        }
    }, [fetchedTotalPurchaseRequestSuccess]);

    useEffect(() => {
        if (Boolean(fetchedPurchaseOrderSuccess) && statusName?.toLowerCase() === "recommended") {
            setRecommendedPurchaseOrderList((prev) =>
                recommendedPage > 1
                    ? uniqBy([...prev, ...fetchedPurchaseOrderSuccess?.po], "_id")
                    : [...fetchedPurchaseOrderSuccess?.po]
            );
            setRecommendedPurchaseOrderPagination({
                current: fetchedPurchaseOrderSuccess?.pagination.current,
                number_of_pages: fetchedPurchaseOrderSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedPurchaseOrderSuccess, statusName, debouncedFilter]);

    useEffect(() => {
        if (Boolean(fetchedPurchaseOrderSuccess) && statusName?.toLowerCase() !== "recommended") {
            setPurchaseOrderList((prev) =>
                page > 1
                    ? uniqBy([...prev, ...fetchedPurchaseOrderSuccess?.po], "_id")
                    : [...fetchedPurchaseOrderSuccess?.po]
            );
            setPurchaseOrderPagination({
                current: fetchedPurchaseOrderSuccess?.pagination.current,
                number_of_pages: fetchedPurchaseOrderSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedPurchaseOrderSuccess, statusName]);

    const tableHeader = [
        { title: "PO ID", widthClass: "w-[8.7%]" },
        { title: "Supplier", widthClass: "w-[10.8%]" },
        { title: "Created by", widthClass: "w-[10.6%]" },
        { title: "Product name", widthClass: "w-[9.8%]" },
        { title: "Est Arrival Date", widthClass: "w-[9.8%]" },
        { title: "Quantity", widthClass: "w-[5.9%]" },
        { title: "No. of shipments", widthClass: "w-[9.8%]" },
        { title: "Status", widthClass: "w-[9.8%]" },
        { title: "", widthClass: "w-[5.1%]" },
    ];

    const tableBody =
        statusName === "recommended"
            ? recommendedPurchaseOrderList?.map((po, idx) => [
                  {
                      content: (
                          <div
                              key={idx}
                              className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-500 `}
                          >
                              <p>{po?.taId}</p>
                          </div>
                      ),
                      cellClickAction: () => navigate(`/dashboard/purchase/${po?._id}`),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                          >
                              <div className="flex items-center space-x-3">
                                  <VendorAvatar
                                      imageSrc={po?.vendor?.profile?.avatar}
                                      size={32}
                                      name={po?.vendor?.email}
                                  />
                                  <span>
                                      {truncate(po?.vendor?.profile?.businessName, { length: 10 })}
                                  </span>
                              </div>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <div className="flex items-center space-x-2">
                                  <VendorAvatar
                                      imageSrc={po?.createdBy?.profile?.avatar}
                                      size={24}
                                      name={po?.createdBy?.fullName}
                                  />
                                  <span>{truncate(po?.createdBy?.fullName, { length: 10 })}</span>
                              </div>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{po?.inventory?.productName}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{moment(po?.dueDate).format("MMM DD, YYYY")}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{formatMoney().format(Number(po?.totalNumberOfItems))}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500`}
                          >
                              <span>{formatMoney().format(Number(po?.numberOfShipments))}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 space-x-1  font-rocGroteskMedium flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                          >
                              <div
                                  className={`h-2 w-2  ${
                                      po?.approvalStatus?.toLowerCase() === "delivered" &&
                                      "bg-[#6562E5]"
                                  } ${
                                      po?.approvalStatus?.toLowerCase() === "approved" && "bg-g-50"
                                  } ${
                                      po?.approvalStatus?.toLowerCase() === "pending" &&
                                      po?.currentStatus?.toLowerCase() !== "draft" &&
                                      "bg-[#F59E0B]"
                                  } 
                                ${
                                    po?.approvalStatus?.toLowerCase() === "rejected" &&
                                    "bg-[#FF3D1D]"
                                }
                                ${
                                    po?.approvalStatus?.toLowerCase() === "pending" &&
                                    po?.currentStatus?.toLowerCase() === "draft" &&
                                    "bg-slate-400"
                                }
                                ${
                                    po?.approvalStatus?.toLowerCase() === "in-transit" &&
                                    "bg-[#F59E0B]"
                                } rounded-full`}
                              ></div>
                              <p>
                                  {po?.approvalStatus?.toLowerCase() === "pending" &&
                                  po?.currentStatus?.toLowerCase() === "draft"
                                      ? "Draft"
                                      : po?.approvalStatus?.charAt(0).toUpperCase() +
                                        po?.approvalStatus.slice(1)}
                              </p>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                          >
                              <Dropdown
                                  dropdown={
                                      <i className="ri-more-2-fill text-lg text-slate-500"></i>
                                  }
                                  dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                  dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-140px] `}
                                  dropdownItemsClasses={``}
                                  name={"faq-dropdown"}
                              />
                          </div>
                      ),
                  },
              ])
            : purchaseOrderList?.map((po, idx) => [
                  {
                      content: (
                          <div
                              key={idx}
                              className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-500 `}
                          >
                              <p>PO {po?.taId}</p>
                          </div>
                      ),
                      cellClickAction: () => navigate(`/dashboard/purchase/${po?._id}`),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                          >
                              <div className="flex items-center space-x-3">
                                  <VendorAvatar
                                      imageSrc={po?.vendor?.profile?.avatar}
                                      size={32}
                                      name={po?.vendor?.email}
                                  />
                                  <span>
                                      {truncate(po?.vendor?.profile?.businessName, { length: 10 })}
                                  </span>
                              </div>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <div className="flex items-center space-x-2">
                                  <VendorAvatar
                                      imageSrc={po?.createdBy?.profile?.avatar}
                                      size={24}
                                      name={po?.createdBy?.fullName}
                                  />
                                  <span>{truncate(po?.createdBy?.fullName, { length: 10 })}</span>
                              </div>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{po?.inventory?.productName}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{moment(po?.dueDate).format("MMM DD, YYYY")}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{formatMoney().format(Number(po?.totalNumberOfItems))}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500`}
                          >
                              <span>{formatMoney().format(Number(po?.numberOfShipments))}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 space-x-1  font-rocGroteskMedium flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                          >
                              <div
                                  className={`h-2 w-2  ${
                                      po?.approvalStatus?.toLowerCase() === "delivered" &&
                                      "bg-[#6562E5]"
                                  } ${
                                      po?.approvalStatus?.toLowerCase() === "approved" && "bg-g-50"
                                  } ${
                                      po?.approvalStatus?.toLowerCase() === "pending" &&
                                      po?.currentStatus?.toLowerCase() !== "draft" &&
                                      "bg-[#F59E0B]"
                                  } 
                                  ${
                                      po?.approvalStatus?.toLowerCase() === "rejected" &&
                                      "bg-[#FF3D1D]"
                                  }
                                  ${
                                      po?.approvalStatus?.toLowerCase() === "pending" &&
                                      po?.currentStatus.toLowerCase() === "draft" &&
                                      "bg-slate-400"
                                  }
                                  ${
                                      po?.approvalStatus?.toLowerCase() === "in-transit" &&
                                      "bg-[#F59E0B]"
                                  } rounded-full`}
                              ></div>
                              <p>
                                  {po?.approvalStatus?.toLowerCase() === "pending" &&
                                  po?.currentStatus?.toLowerCase() === "draft"
                                      ? "Draft"
                                      : po?.approvalStatus?.charAt(0).toUpperCase() +
                                        po?.approvalStatus?.slice(1)}
                              </p>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                          >
                              <Dropdown
                                  dropdown={
                                      <i className="ri-more-2-fill text-lg text-slate-500"></i>
                                  }
                                  dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                  dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-140px] `}
                                  dropdownItemsClasses={``}
                                  name={"faq-dropdown"}
                              />
                          </div>
                      ),
                  },
              ]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return fetchingPurchaseOrder &&
                    purchaseOrderList?.length === 0 &&
                    !debouncedFilter.search ? (
                    <div>
                        <div className="max-lg:hidden block">
                            <CustomTableLoader tableHeader={tableHeader} />
                        </div>
                        <div className="max-lg:block hidden my-[5%]">
                            <Loader />
                        </div>
                    </div>
                ) : purchaseOrderList?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="max-lg:block hidden">
                            {purchaseOrderList?.map((po, idx) => (
                                <div className="w-full mt-4 " key={idx}>
                                    <div className="flex justify-between items-center border px-2 py-4 sm:px-4 sm:py-4 rounded">
                                        <div>
                                            <div>
                                                <div className="flex space-x-3">
                                                    <p className="text-[#142837] text-sm font-rocGroteskMedium">
                                                        PO {po?.taId}
                                                    </p>
                                                    <div className="border-r"></div>
                                                    <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                                        {truncate(
                                                            po?.vendor?.profile?.businessName,
                                                            { length: 20 }
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="flex mt-2 items-center space-x-2">
                                                    <div className="flex xs:hidden items-center space-x-2">
                                                        <i className="ri-user-line text-[#64748B] "></i>
                                                        <p className="text-[#64748B]  text-xs">
                                                            {truncate(po?.createdBy?.fullName, {
                                                                length: 14,
                                                            })}
                                                        </p>
                                                    </div>
                                                    <div className="xs:flex hidden items-center space-x-2">
                                                        <i className="ri-user-line text-[#64748B] "></i>
                                                        <p className="text-[#64748B]  text-xs">
                                                            {truncate(po?.createdBy?.fullName, {
                                                                length: 20,
                                                            })}
                                                        </p>
                                                    </div>
                                                    <div className="border-r border-[#E2E8F0]"></div>
                                                    <div className="flex items-center space-x-2">
                                                        <i className="ri-calendar-line text-[#64748B] "></i>
                                                        <p className="text-[#64748B] text-xs">
                                                            {moment(po?.issuedDate).format(
                                                                "MMM DD, YYYY"
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex mt-2 xs:hidden items-center space-x-2">
                                                <div
                                                    className={`h-2 w-2  ${
                                                        po?.approvalStatus?.toLowerCase() ===
                                                            "delivered" && "bg-[#6562E5]"
                                                    } ${
                                                        po?.approvalStatus?.toLowerCase() ===
                                                            "approved" && "bg-g-50"
                                                    } ${
                                                        po?.approvalStatus?.toLowerCase() ===
                                                            "pending" &&
                                                        po?.currentStatus?.toLowerCase() !==
                                                            "draft" &&
                                                        "bg-[#F59E0B]"
                                                    }   ${
                                                        po?.approvalStatus?.toLowerCase() ===
                                                            "rejected" && "bg-[#FF3D1D]"
                                                    } ${
                                                        po?.approvalStatus?.toLowerCase() ===
                                                            "pending" &&
                                                        po?.currentStatus?.toLowerCase() ===
                                                            "draft" &&
                                                        "bg-slate-400"
                                                    } ${
                                                        po?.approvalStatus?.toLowerCase() ===
                                                            "in-transit" && "bg-[#F59E0B]"
                                                    } rounded-full`}
                                                ></div>
                                                <p className="text-sm text-[#142837] font-rocGroteskMedium">
                                                    {po?.approvalStatus?.toLowerCase() ===
                                                        "pending" &&
                                                    po?.currentStatus?.toLowerCase() === "draft"
                                                        ? "Draft"
                                                        : po?.approvalStatus
                                                              ?.charAt(0)
                                                              .toUpperCase() +
                                                          po?.approvalStatus?.slice(1)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="hidden xs:flex items-center space-x-2">
                                            <div
                                                className={`h-2 w-2 ${
                                                    po?.approvalStatus?.toLowerCase() ===
                                                        "delivered" && "bg-[#6562E5]"
                                                } ${
                                                    po?.approvalStatus?.toLowerCase() ===
                                                        "approved" && "bg-g-50"
                                                } ${
                                                    po?.approvalStatus?.toLowerCase() ===
                                                        "pending" &&
                                                    po?.currentStatus.toLowerCase() !== "draft" &&
                                                    "bg-[#F59E0B]"
                                                }   ${
                                                    po?.approvalStatus?.toLowerCase() ===
                                                        "rejected" && "bg-[#FF3D1D]"
                                                } ${
                                                    po?.approvalStatus?.toLowerCase() ===
                                                        "pending" &&
                                                    po?.currentStatus.toLowerCase() === "draft" &&
                                                    "bg-slate-400"
                                                } ${
                                                    po?.approvalStatus?.toLowerCase() ===
                                                        "in-transit" && "bg-[#F59E0B]"
                                                } rounded-full`}
                                            ></div>

                                            <p className="text-sm text-[#142837] font-rocGroteskMedium">
                                                {po?.approvalStatus?.toLowerCase() === "pending" &&
                                                po?.currentStatus?.toLowerCase() === "draft"
                                                    ? "Draft"
                                                    : po?.approvalStatus?.charAt(0).toUpperCase() +
                                                      po?.approvalStatus?.slice(1)}
                                            </p>
                                        </div>
                                        <div>
                                            <i
                                                className="ri-more-2-fill cursor-pointer text-[#64748B] text-2xl"
                                                onClick={() =>
                                                    navigate(`/dashboard/purchase/${po?._id}`)
                                                }
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {loadMorePurchaseOrder && fetchingPurchaseOrder && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {purchaseOrderPagination?.current ===
                            purchaseOrderPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex-col flex  h-[100%]  justify-center my-[8%]">
                        {filter?.search ? (
                            <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-search-line text-2xl text-[#142837] opacity-50"></i>
                                </div>

                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    Sorry, no results match your search. Refine your criteria and
                                    try again.
                                </p>
                            </div>
                        ) : (
                            <div className="space-y-3 max-w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-guide-fill text-2xl text-light-black opacity-50"></i>
                                </div>
                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    Looks like there are no purchase request at the moment. Start by
                                    creating your first purchase request to keep of track of your
                                    transaction and manage your procurement efficiently
                                </p>
                                <Button
                                    btnText={"Create purchase request"}
                                    type={"button"}
                                    btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                    onClick={() =>
                                        navigate("/dashboard/purchase/create-purchase-request")
                                    }
                                />
                            </div>
                        )}
                    </div>
                );
            case 1:
                return fetchingPurchaseOrder &&
                    recommendedPurchaseOrderList?.length === 0 &&
                    !debouncedFilter.search ? (
                    <div>
                        <div className="max-lg:hidden block">
                            <CustomTableLoader tableHeader={tableHeader} />
                        </div>
                        <div className="max-lg:block hidden my-[5%]">
                            <Loader />
                        </div>
                    </div>
                ) : recommendedPurchaseOrderList?.length > 0 ? (
                    <div className="">
                        <div id="tableContainer" className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="max-lg:block hidden">
                            {statusName === "recommended" &&
                                recommendedPurchaseOrderList?.map((po, idx) => (
                                    <div className="w-full mt-4 " key={idx}>
                                        <div className="flex justify-between items-center border px-2 py-4 sm:px-4 sm:py-4 rounded">
                                            <div>
                                                <div>
                                                    <div className="flex space-x-3">
                                                        <p className="text-[#142837] text-sm font-rocGroteskMedium">
                                                            PO {po?.taId}
                                                        </p>
                                                        <div className="border-r"></div>
                                                        <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                                            {truncate(
                                                                po?.vendor?.profile?.businessName,
                                                                { length: 20 }
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="flex mt-2 items-center space-x-2">
                                                        <div className="flex xs:hidden items-center space-x-2">
                                                            <i className="ri-user-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {truncate(po?.createdBy?.fullName, {
                                                                    length: 14,
                                                                })}
                                                            </p>
                                                        </div>
                                                        <div className="xs:flex hidden items-center space-x-2">
                                                            <i className="ri-user-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {truncate(po?.createdBy?.fullName, {
                                                                    length: 20,
                                                                })}
                                                            </p>
                                                        </div>
                                                        <div className="border-r border-[#E2E8F0]"></div>
                                                        <div className="flex items-center space-x-2">
                                                            <i className="ri-calendar-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {moment(po?.issuedDate).format(
                                                                    "MMM DD, YYYY"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex mt-2 xs:hidden items-center space-x-2">
                                                    <div
                                                        className={`h-2 w-2  bg-slate-400 rounded-full`}
                                                    ></div>
                                                    <p className="text-sm text-[#142837] font-rocGroteskMedium">
                                                        Draft
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="hidden xs:flex items-center space-x-2">
                                                <div className="h-2 w-2 bg-slate-400 rounded-full"></div>
                                                <p className="text-sm text-[#142837] font-rocGroteskMedium">
                                                    Draft
                                                </p>
                                            </div>
                                            <div>
                                                <i
                                                    className="ri-more-2-fill cursor-pointer text-[#64748B] text-2xl"
                                                    onClick={() =>
                                                        navigate(`/dashboard/purchase/${po?._id}`)
                                                    }
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {loadMorePurchaseOrder && fetchingPurchaseOrder && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {recommendedPurchaseOrderPagination?.current ===
                            recommendedPurchaseOrderPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex-col flex  h-[100%]  justify-center my-[8%]">
                        {filter?.search ? (
                            <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-search-line text-2xl text-[#142837] opacity-50"></i>
                                </div>

                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    Sorry, no results match your search. Refine your criteria and
                                    try again.
                                </p>
                            </div>
                        ) : (
                            <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-guide-fill text-2xl text-light-black opacity-50"></i>
                                </div>
                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    No recommended request currently. Check back later for updates
                                </p>
                            </div>
                        )}
                    </div>
                );
            default:
                return;
        }
    };

    return (
        <>
            <div className="mb-6">
                {fetchingTotalPurchaseOrder ? (
                    <Loader />
                ) : (
                    <div className="grid lg:grid-cols-4 3md:grid-cols-2 grid-cols-1 gap-4 pb-6 border-b border-dashed border-slate-200">
                        <div className="p-4 lg:border-r bg-white lg:bg-transparent lg:shadow-none shadow-faintShadow lg:rounded-none rounded border-slate-100 ">
                            <div className="p-[5px] lg:flex hidden items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                <i className="ri-guide-fill text-2xl text-black"></i>
                            </div>
                            <span className="md:text-base text-xs lg:mt-0 pt-2 text-slate-500 font-rocGroteskMedium block">
                                Total no. of PO
                            </span>
                            <div className="flex items-center justify-between">
                                <span className="text-p-70 text-[36px] leading-[51.84px] font-rocGroteskMedium block">
                                    {totalPO?.totalPurchaseOrders ? (
                                        <>
                                            {formatMoney().format(
                                                Number(totalPO?.totalPurchaseOrders)
                                            )}
                                        </>
                                    ) : (
                                        "-----"
                                    )}
                                </span>
                                <div className="p-[5px] lg:hidden flex items-center justify-center w-[34px] h-[34px] rounded-full bg-slate-100 ">
                                    <i className="ri-guide-fill text-2xl text-black"></i>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:border-r bg-white lg:bg-transparent lg:shadow-none shadow-faintShadow lg:rounded-none rounded border-slate-100">
                            <div className="p-[5px] lg:flex hidden items-center justify-center w-[34px] h-[34px] rounded-lg bg-[#16C6A40D] mb-2.5">
                                <i className="ri-guide-fill text-2xl text-carribean-green"></i>
                            </div>
                            <span className="md:text-base text-xs text-slate-500 font-rocGroteskMedium block">
                                Approved purchase request
                            </span>
                            <div className="flex items-center justify-between">
                                <span className="text-p-70 text-[36px] leading-[51.84px] font-rocGroteskMedium block">
                                    {totalPO?.totalApproved ? (
                                        <>{formatMoney().format(Number(totalPO?.totalApproved))}</>
                                    ) : (
                                        "-----"
                                    )}
                                </span>
                                <div className="p-[5px] lg:hidden flex items-center justify-center w-[34px] h-[34px] rounded-full bg-[#16C6A40D]">
                                    <i className="ri-guide-fill text-2xl text-carribean-green"></i>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:border-r bg-white lg:bg-transparent lg:shadow-none shadow-faintShadow lg:rounded-none rounded border-slate-100">
                            <div className="p-[5px] lg:flex hidden items-center justify-center w-[34px] h-[34px] rounded-lg bg-[#3668E80D] mb-2.5">
                                <i className="ri-guide-fill text-2xl text-[#3668E8]"></i>
                            </div>
                            <span className="md:text-base text-xs text-slate-500 font-rocGroteskMedium block">
                                Pending purchase request
                            </span>
                            <div className="flex items-center justify-between">
                                <span className="text-p-70 text-[36px] leading-[51.84px] font-rocGroteskMedium block">
                                    {totalPO?.totalPending ? (
                                        <>{formatMoney().format(Number(totalPO?.totalPending))}</>
                                    ) : (
                                        "-----"
                                    )}
                                </span>
                                <div className="p-[5px] lg:hidden flex items-center justify-center w-[34px] h-[34px] rounded-full bg-[#3668E80D]">
                                    <i className="ri-guide-fill text-2xl text-[#3668E8]"></i>
                                </div>
                            </div>
                        </div>
                        <div className="p-4  bg-white lg:bg-transparent lg:shadow-none shadow-faintShadow lg:rounded-none rounded">
                            <div className="p-[5px] lg:flex hidden items-center justify-center w-[34px] h-[34px] rounded-lg bg-[#FF3D1D0D] mb-2.5">
                                <i className="ri-guide-fill text-2xl text-[#FF3D1D]"></i>
                            </div>
                            <span className="md:text-base text-xs text-slate-500 font-rocGroteskMedium block">
                                Rejected purchase request
                            </span>
                            <div className="flex items-center justify-between">
                                <span className="text-p-70 text-[36px] leading-[51.84px] font-rocGroteskMedium block">
                                    {totalPO?.totalIntransit ? (
                                        <>{formatMoney().format(Number(totalPO?.totalIntransit))}</>
                                    ) : (
                                        "-----"
                                    )}
                                </span>
                                <div className="p-[5px] lg:hidden flex items-center justify-center w-[34px] h-[34px] rounded-full bg-[#FF3D1D0D]">
                                    <i className="ri-guide-fill text-2xl text-[#FF3D1D]"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="pt-4 relative overflow-y-auto">
                <TabsContainer
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    component={displayActiveTab()}
                    className="!px-0"
                    itemClassName="!pb-2"
                    totalRecommended={recommendedTotal}
                    borderLineClase={"!text-[#F1F5F9] w-[30%] max-lg:w-full !h-fit  mb-4"}
                    showButton={
                        <>
                            <div className="max-lg:hidden flex xl:w-[830px] lg:w-[600px] w-[400px] absolute  items-center space-x-3">
                                <TextInput
                                    name={"search"}
                                    type={"text"}
                                    value={filter.search}
                                    inputPlaceholder={"Search supplier, outlet, ID..."}
                                    inputClassName={"!h-[32px] pl-[0px] !bg-white text-sm "}
                                    containerClassname={""}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] !h-[40px]  !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                    }
                                    onChange={handleSearch}
                                    onInput={handleDebouncedChange}
                                />
                                <Dropdown
                                    name="filter"
                                    value={statusName}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => {
                                        setPage(1);
                                        setRecommendedPage(1);
                                        setStatusName(value);
                                    }}
                                    dropdown={
                                        <div className="bg-white flex items-center h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20 !rounded">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                {statusName !== "" && statusName !== "recommended"
                                                    ? statusName?.charAt(0).toUpperCase() +
                                                      statusName?.slice(1)
                                                    : "All Status"}
                                            </span>

                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4  !mt-0  !w-[159px] rounded !max-h-fit
                                     !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={[
                                        {
                                            label: (
                                                <div className="flex items-center space-x-2">
                                                    {/* <div className="h-2 w-2 bg-[#16C6A4] rounded-[900px]"></div> */}
                                                    <p className="text-[#0F172A] text-sm font-rocGroteskMedium">
                                                        All Status
                                                    </p>
                                                </div>
                                            ),
                                            value: "",
                                        },
                                        {
                                            label: (
                                                <div className="flex items-center space-x-2">
                                                    <div className="h-2 w-2 bg-[#16C6A4] rounded-[900px]"></div>
                                                    <p className="text-[#0F172A] text-sm font-rocGroteskMedium">
                                                        Approved
                                                    </p>
                                                </div>
                                            ),
                                            value: "approved",
                                        },
                                        {
                                            label: (
                                                <div className="flex items-center space-x-2">
                                                    <div className="h-2 w-2 bg-[#6562E5] rounded-[900px]"></div>
                                                    <p className="text-[#0F172A] text-sm font-rocGroteskMedium">
                                                        Pending
                                                    </p>
                                                </div>
                                            ),
                                            value: "pending",
                                        },
                                        {
                                            label: (
                                                <div className="flex items-center space-x-2">
                                                    <div className="h-2 w-2 bg-[#FF3D1D] rounded-[900px]"></div>
                                                    <p className="text-[#0F172A] text-sm font-rocGroteskMedium">
                                                        Rejected
                                                    </p>
                                                </div>
                                            ),
                                            value: "rejected",
                                        },
                                        {
                                            label: (
                                                <div className="flex items-center space-x-2">
                                                    <div className="h-2 w-2 bg-[#94A3B8] rounded-[900px]"></div>
                                                    <p className="text-[#0F172A] text-sm font-rocGroteskMedium">
                                                        Draft
                                                    </p>
                                                </div>
                                            ),
                                            value: "draft",
                                        },
                                    ]}
                                />
                                <Button
                                    btnText={"Settings"}
                                    type={"button"}
                                    btnClassname="!py-2 !px-4 !w-fit max-sm:w-full !whitespace-nowrap !bg-white !border !border-[#F4F5F7]"
                                    icon={<i className="ri-settings-3-line text-base"></i>}
                                    onClick={() => navigate("/dashboard/purchase/settings")}
                                />
                                {/* <Button
                            btnText={"Initiate return"}
                            type={"button"}
                            btnClassname="!py-2 !px-4 !w-fit max-sm:w-full !whitespace-nowrap !bg-slate-100"
                            icon={<i className="ri-arrow-turn-back-fill text-base"></i>}
                            onClick={() => navigate("/dashboard/purchase")}
                        /> */}

                                <Button
                                    btnText={"Create purchase request"}
                                    type={"button"}
                                    icon={<i className="ri-add-line text-gm-50 text-base"></i>}
                                    btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                    onClick={() =>
                                        navigate("/dashboard/purchase/create-purchase-request")
                                    }
                                />
                            </div>
                            <div className=" max-lg:flex hidden  space-x-3 items-center">
                                <TextInput
                                    name={"search"}
                                    type={"text"}
                                    value={filter.search}
                                    inputPlaceholder={"Search supplier, outlet, ID..."}
                                    inputClassName={"!h-[32px] pl-[0px] !bg-white text-sm  !w-full"}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] !h-[40px]  !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                    }
                                    onChange={handleSearch}
                                    onInput={handleDebouncedChange}
                                />
                                <div>
                                    <Dropdown
                                        name="filter"
                                        value={statusName}
                                        dropdownTriggerClassName={" "}
                                        handleChange={(name, value) => {
                                            setPage(1);
                                            setRecommendedPage(1);
                                            setStatusName(value);
                                        }}
                                        dropdown={
                                            <div className="bg-white flex items-center h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20 !rounded">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    {statusName !== "" &&
                                                    statusName !== "recommended"
                                                        ? statusName?.charAt(0).toUpperCase() +
                                                          statusName?.slice(1)
                                                        : "All Status"}
                                                </span>

                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        }
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4  !mt-0  !w-[159px] rounded !max-h-fit
                                              !p-0 !top-[105%]`}
                                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                        dropdownOptions={[
                                            {
                                                label: (
                                                    <div className="flex items-center space-x-2">
                                                        {/* <div className="h-2 w-2 bg-[#16C6A4] rounded-[900px]"></div> */}
                                                        <p className="text-[#0F172A] text-sm font-rocGroteskMedium">
                                                            All Status
                                                        </p>
                                                    </div>
                                                ),
                                                value: "",
                                            },
                                            {
                                                label: (
                                                    <div className="flex items-center space-x-2">
                                                        <div className="h-2 w-2 bg-[#16C6A4] rounded-[900px]"></div>
                                                        <p className="text-[#0F172A] text-sm font-rocGroteskMedium">
                                                            Approved
                                                        </p>
                                                    </div>
                                                ),
                                                value: "approved",
                                            },
                                            {
                                                label: (
                                                    <div className="flex items-center space-x-2">
                                                        <div className="h-2 w-2 bg-[#6562E5] rounded-[900px]"></div>
                                                        <p className="text-[#0F172A] text-sm font-rocGroteskMedium">
                                                            Pending
                                                        </p>
                                                    </div>
                                                ),
                                                value: "pending",
                                            },
                                            {
                                                label: (
                                                    <div className="flex items-center space-x-2">
                                                        <div className="h-2 w-2 bg-[#FF3D1D] rounded-[900px]"></div>
                                                        <p className="text-[#0F172A] text-sm font-rocGroteskMedium">
                                                            Rejected
                                                        </p>
                                                    </div>
                                                ),
                                                value: "rejected",
                                            },
                                            {
                                                label: (
                                                    <div className="flex items-center space-x-2">
                                                        <div className="h-2 w-2 bg-[#94A3B8] rounded-[900px]"></div>
                                                        <p className="text-[#0F172A] text-sm font-rocGroteskMedium">
                                                            Draft
                                                        </p>
                                                    </div>
                                                ),
                                                value: "draft",
                                            },
                                        ]}
                                    />
                                </div>
                                <Button
                                    type={"button"}
                                    btnClassname="!py-2 !px-4 !w-fit max-sm:w-full !whitespace-nowrap !bg-white !border !border-[#F4F5F7]"
                                    icon={<i className="ri-settings-3-line text-base"></i>}
                                    onClick={() => navigate("/dashboard/purchase/settings")}
                                />
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default PurchaseOrderRequestList;
