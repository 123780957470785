import React, { useEffect, useState } from "react";
import { vendorActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import moment from "moment";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";

type BookADemoModalProps = {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const BookADemoModal = ({ openModal, setOpenModal }: BookADemoModalProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { joiningWaitList, joinWaitListSuccess } = useAppSelector((state) => state.vendor);

    const [bookDemoData, setBookDemoData] = useState({
        name: "",
        email: "",
        company: "",
        industry: "",
    });

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const { name, value } = e.target;

        setBookDemoData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const submitBookDemo = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        const data: { [key: string]: any } = {
            email: bookDemoData.email,
            fullName: bookDemoData.name,
            companyName: bookDemoData.company,
            industry: bookDemoData.industry,
        };

        dispatch(vendorActions.joinWaitlist(data));
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        if (Boolean(joinWaitListSuccess)) {
            setOpenModal(false);
            setBookDemoData({
                name: "",
                email: "",
                company: "",
                industry: "",
            });

            toast.custom((t) => <CustomToast t={t} message={"Demo booked"} type="success" />);
            dispatch(vendorActions.resetJoinWaitListSuccess());
        }
    }, [joinWaitListSuccess, dispatch]);

    return (
        <ModalContainer
            open={openModal}
            showCloseIcon={false}
            modalClassName={"py-10 px-10 max-sm:p-0"}
            tailwindClassName="w-[80%] max-lg:w-full"
            closeModal={handleCloseModal}
        >
            <div className="bg-[#FFF7DC] p-6 max-lg:p-3 rounded-[8px] relative">
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715184895/book_demo_vector_bekzps.svg"
                    alt="book demo vector"
                    className="absolute left-[-30px]  z-[10] top-[-80px]  max-lg:hidden"
                />
                <div className="flex  justify-between  relative z-[1000]  space-x-[65px] max-lg:space-x-0">
                    <div className="w-[43%] pt-10 pb-[22px] max-lg:hidden">
                        <h3 className="text-[36px] leading-[43px] text-[#013D28] mb-[36px] font-flame">
                            Unified forecasting and production management for CPG
                        </h3>

                        <div className="space-y-6">
                            <div>
                                <div className="flex items-center space-x-2 mb-1.5">
                                    {/* <img
                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715185785/chart-bar-fill_1_tgrnas.svg"
                                        alt="Personalized Insights"
                                        className="w-5 h-5"
                                    /> */}
                                    <span className="text-lg font-flame text-green-2">
                                       No-Obligation exploration
                                    </span>
                                </div>
                                <p className="text-base font-interDisplay text-green-2">
                                    Try Synth for free for 14 days, with no pressure. 
                                </p>
                            </div>

                            <div>
                                <div className="flex items-center space-x-2 mb-1.5">
                                    {/* <img
                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715185785/user-fill_yhbjxw.svg"
                                        alt="Expert Guidance"
                                        className="w-5 h-5"
                                    /> */}
                                    <span className="text-lg font-flame text-orange-1">
                                        Easy integration and set-up
                                    </span>
                                </div>
                                <p className="text-base font-interDisplay text-green-2">
                                    Get your Synth account integrated and set up for free, in no time.
                                </p>
                            </div>

                            <div>
                                <div className="flex items-center space-x-2 mb-1.5">
                                    {/* <img
                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715185785/compass-fill_h2mwah.svg"
                                        alt="No-Obligation Exploration"
                                        className="w-5 h-5"
                                    /> */}
                                    <span className="text-lg font-flame text-[#E4281D]">
                                        Personalized insights
                                    </span>
                                </div>
                                <p className="text-base font-interDisplay text-green-2">
                                    Book a demo tailored to how Synth solves your specific bottlenecks.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="w-[50%] bg-white rounded-lg shadow-[0px_20px_24px_-4px_#FFB90214] max-lg:w-full">
                        <form
                            onSubmit={submitBookDemo}
                            className="relative max-lg:w-full   p-8 max-lg:p-6"
                        >
                            <span
                                onClick={handleCloseModal}
                                className="material-symbols-outlined text-base absolute right-[16px] top-[16px] cursor-pointer !text-[#013D28] "
                            >
                                close
                            </span>
                            <div className="mb-6">
                                <h4 className="text-2xl font-flame mb-1 max-lg:hidden !text-[#013D28] ">
                                    See Synth in action - Book a free demo
                                </h4>
                                <h4 className="text-2xl font-flame mb-1 hidden max-lg:block !text-[#013D28] ">
                                    Book a free demo
                                </h4>
                                <p className="text-base font-interDisplay  ">
                                    Fill out the form below to schedule your live demo. Our team
                                    will contact you to customize your demo based on your needs.
                                </p>
                            </div>

                            <div className="flex flex-col space-y-3 mb-6">
                                <TextInput
                                    value={bookDemoData.name}
                                    name={"name"}
                                    type={"text"}
                                    onChange={handleChange}
                                    inputPlaceholder={"Name"}
                                    required={true}
                                    containerClassname=""
                                />

                                <TextInput
                                    value={bookDemoData.email}
                                    name={"email"}
                                    type={"email"}
                                    onChange={handleChange}
                                    inputPlaceholder={"Email address"}
                                    required={true}
                                    containerClassname=""
                                />

                                <TextInput
                                    value={bookDemoData.company}
                                    name={"company"}
                                    type={"text"}
                                    onChange={handleChange}
                                    inputPlaceholder={"Company"}
                                    required={true}
                                    containerClassname=""
                                />

                                <TextInput
                                    value={bookDemoData.industry}
                                    name={"industry"}
                                    type={"text"}
                                    onChange={handleChange}
                                    inputPlaceholder={"Industry"}
                                    required={true}
                                    containerClassname=""
                                />
                            </div>
                            <Button
                                type={"submit"}
                                btnText={"Book a demo"}
                                btnClassname={
                                    "!h-[48px] !text-base !text-[#013D28] !rounded-md !px-6 !border-[2px] !border-[#FFD25B80] !font-flame !shadow-[0px_-3px_0px_0px_#0000001A_inset]"
                                }
                                isLoading={joiningWaitList}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

export default BookADemoModal;
