import React from "react";

export const displayProductChannelIcon = (channel: string, className?: string) => {
    switch (channel?.toLowerCase()) {
        case "quickbooks":
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716480828/quickbooksIcon_poqahg.svg"
                    alt="quickbooks"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "quickbooks desktop":
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716480828/quickbooksIcon_poqahg.svg"
                    alt="quickbooks desktop"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "sps-commerce":
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728484711/sps_commerce_v0uhiz.png"
                    alt="sps commerce"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "shopify":
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716480854/shopifyIcon_qelbea.svg"
                    alt="shopify"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "amazon":
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1721139624/amazon_ciygng.svg"
                    alt="amazon"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "gmail":
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1713957047/gmail_1_zya4ai.svg"
                    alt="gmail"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "microsoft":
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1727914077/microsoft_md3oil.svg"
                    alt="microsoft"
                    className={`w-6 h-6 ${className}`}
                />
            );
        default:
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716480916/synthIconOnly_rcj7zd.svg"
                    alt="synth"
                    className={`w-6 h-6 ${className}`}
                />
            );
    }
};
