import React from "react";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import Checkbox from "components/common/InputField/Checkbox";
import useGetArchivedProductsByChannel from "hooks/inventoryHooks/useGetArchivedProductsByChannel";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import Loader from "components/common/Loader";
import { useNavigate } from "react-router-dom";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";

type SelectProductsProps = {
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    selectedProducts: { [key: string]: any }[];
    setSelectedProducts: React.Dispatch<React.SetStateAction<{ [key: string]: any }[]>>;
};

const SelectProducts = ({
    step,
    setStep,
    selectedProducts,
    setSelectedProducts,
}: SelectProductsProps) => {
    const navigate = useNavigate();
    const scrollContent = document.getElementById("archiveContainer");

    const {
        data: products,
        isFetching,
        total,
        search,
        handleDebouncedChange,
        handleSearch,
        pagination,
        isLoadingMore,
        updatedPage,
    } = useGetArchivedProductsByChannel(scrollContent);

    const handleNextStep = () => {
        return selectedProducts?.length > 0 ? setStep(2) : null;
    };

    return (
        <div className="mt-[31px] flex justify-center">
            <div className="w-[70%] h-[75vh] bg-white rounded-[6px] shadow-[0px_20px_24px_-4px_#10182814]">
                <div className="w-full h-full relative">
                    <div className="px-6 pt-4 pb-6 border-b border-slate-200">
                        <div className="font-rocGroteskMedium flex justify-between items-center mb-2">
                            <h4 className="text-g-75 text-base">
                                Select product/SKUs to import to inventory
                            </h4>
                            <span className="text-sm text-slate-500 ">Step {step}/3</span>
                        </div>

                        <div className="mb-4">
                            <TextInput
                                name={"search"}
                                value={search}
                                type={"text"}
                                inputPlaceholder={"Search products"}
                                inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                onChange={handleSearch}
                                onInput={handleDebouncedChange}
                                containerClassname={"!w-full max-sm:!w-full"}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-slate-200"
                                }
                            />
                        </div>

                        <div className="grid grid-cols-3 space-x-1.5">
                            <span className="h-1 bg-[#FFB902] rounded"></span>
                            <span className="h-1 bg-[#EADFC1] rounded"></span>
                            <span className="h-1 bg-[#EADFC1] rounded"></span>
                        </div>
                    </div>

                    {isFetching && Number(updatedPage) === 1 ? (
                        <div className="h-[48.8vh] flex items-center justify-center">
                            <Loader size={5} />
                        </div>
                    ) : (
                        <div className="h-[48.8vh] overflow-y-auto" id="archiveContainer">
                            {products?.map((product, idx) => {
                                return (
                                    <div
                                        className={`flex items-center justify-between px-6 py-3 ${
                                            idx > 0 && "border-t"
                                        } border-slate-200`}
                                        key={product?._id}
                                    >
                                        <div className="flex items-center space-x-3">
                                            {product?.productImageDetails?.productImages?.[0] ? (
                                                <VendorAvatar
                                                    imageSrc={
                                                        product?.productImageDetails
                                                            ?.productAvatar ||
                                                        product?.productImageDetails
                                                            ?.productImages?.[0]
                                                    }
                                                    size={32}
                                                    name={product?.productName}
                                                    containerClassname="!rounded-md"
                                                />
                                            ) : (
                                                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                </div>
                                            )}
                                            <div className="">
                                                <span className="block font-rocGroteskMedium text-sm">
                                                    {truncate(product?.productName, { length: 40 })}
                                                </span>
                                                <div className="flex items-center space-x-1.5">
                                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                        {product?.sku}
                                                    </span>
                                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                        |
                                                    </span>
                                                    <div className="bg-slate-50 w-fit px-1.5 py-1 flex items-center border rounded-lg">
                                                        {product?.channel ? (
                                                            <div className="flex items-center space-x-1">
                                                                {displayProductChannelIcon(
                                                                    product?.channel,
                                                                    "!w-4 !h-4"
                                                                )}
                                                                <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                                    {truncate(product?.channel, {
                                                                        length: 20,
                                                                    })}{" "}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center space-x-1">
                                                                {displayProductChannelIcon(
                                                                    product?.channel,
                                                                    "!w-4 !h-4"
                                                                )}
                                                                <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                                    Synth
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Checkbox
                                            name="select"
                                            isChecked={Boolean(
                                                selectedProducts.find(
                                                    (prod) => prod?._id === product?._id
                                                )
                                            )}
                                            onChange={() => {
                                                const selectedProductsCopy = [...selectedProducts];
                                                const isSelectedProduct = selectedProductsCopy.find(
                                                    (prod) => prod?._id === product?._id
                                                );

                                                if (isSelectedProduct) {
                                                    setSelectedProducts(
                                                        selectedProductsCopy.filter(
                                                            (prod) => prod?._id !== product?._id
                                                        )
                                                    );
                                                    return;
                                                }

                                                setSelectedProducts([...selectedProducts, product]);
                                            }}
                                            className={
                                                "!h-4 !w-4 !rounded-[4px] mr-4 checked:!bg-y-10"
                                            }
                                        />
                                    </div>
                                );
                            })}

                            {isLoadingMore && (
                                <div className="flex justify-center my-4">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}

                            {pagination?.currentPage === pagination?.noOfPages && (
                                <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    )}

                    <div className="flex items-center justify-between absolute bottom-0 w-full bg-white border-t border-slate-200 px-6 py-4">
                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                            {selectedProducts?.length} out of {total} product(s) selected
                        </span>

                        <div className="flex items-center space-x-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="h-[48px] !bg-n-20"
                                onClick={() => navigate(`/dashboard/inventory`)}
                            />

                            <Button
                                btnText="Preview products"
                                btnClassname="h-[48px] !whitespace-nowrap"
                                disabled={selectedProducts?.length === 0}
                                onClick={() => handleNextStep()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectProducts;
