export const AiNameSuggestions = [
    "Astra",
    "Nexus",
    "Vela",
    "Orion",
    "Solara",
    "Vega",
    "Nova",
    "Quanta",
    "Verity",
    "Solace",
    "Helix",
    "Aria",
    "Atlas",
    "Lumina",
];
