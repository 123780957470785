import React from "react";
import Button from "./Button";

type EmptyStateCompProps = {
    title: string;
    description?: string;
    icon?: React.ReactNode;
    hasButton?: boolean;
    btnText?: string;
    btnAction?: () => void;
    contentContainerClass?: string;
    className?:string
};

const EmptyStateComp = ({
    title,
    description,
    icon,
    hasButton,
    btnText,
    btnAction,
    contentContainerClass,
    className
}: EmptyStateCompProps) => {
    return (
        <div className={`flex justify-center w-full my-12 ${className} `}>
            <div
                className={`flex space-x-6 ${
                    hasButton ? "items-start" : "items-center"
                } p-10 w-[59%] ${contentContainerClass} border border-slate-200 rounded-lg shadow-[0px_8px_8px_-4px_#10182808] max-sm:w-full max-sm:p-5`}
            >
                {icon}
                <div>
                    <h6 className="mb-2 text-base font-rocGroteskMedium">{title}</h6>
                    {description && <p className="text-sm text-slate-500 mb-4">{description}</p>}
                    {hasButton && (
                        <Button
                            btnClassname="!py-2 !px-4 !w-fit !h-10"
                            btnText={btnText}
                            onClick={() => btnAction?.()}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmptyStateComp;
