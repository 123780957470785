import { flattenDeep, reduce, sortBy, sortedUniq } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { getWeeksInMonth, getWeekDateRange } from "./dateTime";

export const getBarchartDataPerFilter = (
    data: { [key: string]: string | number }[],
    filter: string
) => {
    let chartData = {
        "x-axis": [],
        values: [],
    };
    let valuesArr = [];
    switch (filter) {
        case "weekly":
            const sortedData = data?.sort((a, b) => Number(a?.dayOfWeek) - Number(b?.dayOfWeek));

            sortedData?.forEach((item, idx) => {
                valuesArr[Number(item?.dayOfWeek) - 1] = Number(item?.count);
            });

            chartData["x-axis"] = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            chartData.values = valuesArr;

            return chartData;

        case "monthly":
            const sortedValues = data?.sort((a, b) => Number(a?.day) - Number(b?.day));
            let count = 0;

            valuesArr = [0, 1, 2, 3, 4]?.map((item, idx) => {
                return sortedValues?.splice(idx * 7, (idx + 1) * 7);
            });

            chartData["x-axis"] = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];
            chartData.values = data;

            return chartData;

        case "yearly":
            return Object.keys(data)
                .sort((a, b) => data[b] - data[a])
                .slice(0, 5)
                .map((key) => {
                    return {
                        name: key,
                        value: data[key],
                    };
                });

        default:
            return Object.keys(data).map((key) => {
                return {
                    name: key,
                    value: data[key],
                };
            });
    }
};

export const getSalesMultiBarChartData = (
    data: { [key: string]: any },
    filter: string,
    date?: string[] | string
) => {
    const handleMonthlyData = (
        customCurrentYear?: number,
        customCurrentMonth?: number,
        customObjKey?: string
    ) => {
        const today = moment();
        const currentYear = customCurrentYear || today.year();
        const currentMonth = customCurrentMonth || today.month() + 1;
        const objKey = customObjKey || today?.format("MMM-YYYY");

        const currentSalesMonthData = data?.currentSales?.find((entry) => entry[objKey]) || {};

        const currentWeeklySales = currentSalesMonthData?.[objKey]
            ?.filter((item) => item.year === currentYear && item.month === currentMonth)
            ?.map((item) => {
                return { weekOfMonth: item.weekOfMonth, totalSales: item.totalSales };
            })
            ?.reduce((acc, curr) => {
                acc[curr.weekOfMonth] = (acc[curr.weekOfMonth] || 0) + curr.totalSales;
                return acc;
            }, {});

        const currentSalesResult = Object.keys(currentWeeklySales || {})?.map((week) => [
            `Week ${week}`,
            currentWeeklySales?.[week]?.toFixed(0) || 0,
            0,
        ]);

        const forecastSalesMonthData = data?.forecastSales?.find((entry) => entry[objKey]) || {};

        const forecastWeeklySales = forecastSalesMonthData?.[objKey]
            ?.filter((item) => item.year === currentYear && item.month === currentMonth)
            ?.map((item) => {
                return { weekOfMonth: item.weekOfMonth, totalSales: item.totalSales };
            })
            ?.reduce((acc, curr) => {
                acc[curr.weekOfMonth] = (acc[curr.weekOfMonth] || 0) + curr.totalSales;
                return acc;
            }, {});

        const forecaseSalesResult = Object.keys(forecastWeeklySales || {})?.map((week) => [
            `Week ${week}`,
            0,
            forecastWeeklySales?.[week]?.toFixed(0) || 0,
        ]);

        return [...currentSalesResult, ...forecaseSalesResult];
    };

    const handleYearlyData = () => {
        const sortedCurrentSalesYearsKey = data?.currentSales
            ?.map((item) => item)
            ?.sort((a, b) => {
                const keyA = Object.keys(a)[0];
                const keyB = Object.keys(b)[0];

                return new Date(keyA).getTime() - new Date(keyB).getTime();
            });

        const currentSalesYearsKey = sortedCurrentSalesYearsKey?.map((item) => {
            return flattenDeep(
                Object.entries(item)?.map(([key, value]) => {
                    const totalCurrentSalesSum = (value as { [key: string]: any }[])?.reduce(
                        (acc, curr) => acc + curr?.totalSales,
                        0
                    );

                    return [key, totalCurrentSalesSum?.toFixed(0) || 0, 0];
                })
            );
        });

        const sortedForecastSalesYearsKey = data?.forecastSales
            ?.map((item) => item)
            ?.sort((a, b) => {
                const keyA = Object.keys(a)[0];
                const keyB = Object.keys(b)[0];

                return new Date(keyA).getTime() - new Date(keyB).getTime();
            });

        const forecastSalesYearsKey = sortedForecastSalesYearsKey?.map((item, idx) => {
            return flattenDeep(
                Object.entries(item)?.map(([key, value]) => {
                    const totalForecastSalesSum = (value as { [key: string]: any }[])?.reduce(
                        (acc, curr) => acc + curr?.totalSales,
                        0
                    );

                    return [key, 0, totalForecastSalesSum?.toFixed(0) || 0];
                })
            );
        });

        const sortedData = [...(currentSalesYearsKey || []), ...(forecastSalesYearsKey || [])].sort(
            (a, b) => {
                const dateA = new Date(`01-${a[0]}`);
                const dateB = new Date(`01-${b[0]}`);
                return dateA.getTime() - dateB.getTime();
            }
        );
        return sortedData;
    };

    const handleCustomData = () => {
        const startDate = moment(date[0]);
        const endDate = moment(date[1]);
        const daysDifference = endDate.diff(startDate, "days");
        const isWithinThirtyDays = daysDifference <= 31;

        const currentYear = startDate.year();
        const currentMonth = startDate.month() + 1;
        const objKey = startDate?.format("MMM-YYYY");

        if (isWithinThirtyDays) {
            return handleMonthlyData(currentYear, currentMonth, objKey);
        }
        return handleYearlyData();
    };

    switch (filter) {
        case "monthly":
            // const currentSalesMonthsValue = Object.entries(data?.currentSales?.[0] || {})?.map(
            //     ([key, value]) => value
            // );
            // const forecastSalesMonthsValue = Object.entries(data?.forecastSales?.[0] || {})?.map(
            //     ([key, value]) => value
            // );
            // const currentSalesValuesArray = Object.entries(currentSalesMonthsValue?.[0] || {})?.map(
            //     ([key, value]) => ({
            //         xAxisLabel: key,
            //         value1: value,
            //         value2: {},
            //     })
            // );
            // const forecastSalesValuesArray = Object.entries(
            //     forecastSalesMonthsValue?.[0] || {}
            // )?.map(([key, value]) => ({
            //     xAxisLabel: key,
            //     value1: {},
            //     value2: value,
            // }));

            // const valuesFormat = [...currentSalesValuesArray, ...forecastSalesValuesArray]?.map(
            //     (item, idx) => {
            //         return [
            //             item.xAxisLabel || "",
            //             Math.round((item?.value1 as any)?.totalSale) || 0,
            //             Math.round((item?.value2 as any)?.totalSale) || 0,
            //         ];
            //     }
            // );
            // const sortedValues = valuesFormat.sort((a, b) => {
            //     const months = {
            //         jan: 0,
            //         feb: 1,
            //         mar: 2,
            //         apr: 3,
            //         may: 4,
            //         jun: 5,
            //         jul: 6,
            //         aug: 7,
            //         sep: 8,
            //         oct: 9,
            //         nov: 10,
            //         dec: 11,
            //     };
            //     return (
            //         months[a[0]?.toString()?.toLowerCase()] - months[b[0]?.toString().toLowerCase()]
            //     );
            // });
            // console.log("first --->>", valuesFormat);
            // return sortedValues;

            // Step 1: Get the current month and year
            // const today = moment();
            // const currentYear = today.year();
            // const currentMonth = today.month() + 1;
            // const objKey = today?.format("MMM-YYYY");
            // console.log("currentMonth", currentMonth, currentYear, today?.format("MMM-YYYY"));

            // const currentSalesMonthData = data?.currentSales?.find((entry) => entry[objKey]) || {};

            // const currentWeeklySales = currentSalesMonthData?.[objKey]
            //     ?.filter((item) => item.year === currentYear && item.month === currentMonth)
            //     ?.map((item) => {
            //         return { weekOfMonth: item.weekOfMonth, totalSales: item.totalSales };
            //     })
            //     ?.reduce((acc, curr) => {
            //         acc[curr.weekOfMonth] = (acc[curr.weekOfMonth] || 0) + curr.totalSales;
            //         return acc;
            //     }, {});

            // const currentSalesResult = Object.keys(currentWeeklySales || {})?.map((week) => [
            //     `Week ${week}`,
            //     currentWeeklySales?.[week]?.toFixed(0) || 0,
            //     0,
            // ]);

            // const forecastSalesMonthData =
            //     data?.forecastSales?.find((entry) => entry[objKey]) || {};

            // const forecastWeeklySales = forecastSalesMonthData?.[objKey]
            //     ?.filter((item) => item.year === currentYear && item.month === currentMonth)
            //     ?.map((item) => {
            //         return { weekOfMonth: item.weekOfMonth, totalSales: item.totalSales };
            //     })
            //     ?.reduce((acc, curr) => {
            //         acc[curr.weekOfMonth] = (acc[curr.weekOfMonth] || 0) + curr.totalSales;
            //         return acc;
            //     }, {});

            // const forecaseSalesResult = Object.keys(forecastWeeklySales || {})?.map((week) => [
            //     `Week ${week}`,
            //     0,
            //     forecastWeeklySales?.[week]?.toFixed(0) || 0,
            // ]);

            // return [...currentSalesResult, ...forecaseSalesResult];
            return handleMonthlyData();

        case "yearly":
            return handleYearlyData();

        case "custom":
            return handleCustomData();

        default:
            return [];
    }
};

export const getDashboardChartData = (
    data: any,
    // data: { [key: string]: string | number },
    filter: string,
    date?: string[]
) => {
    const currentDate = moment();
    const daysInMonth = currentDate?.clone()?.daysInMonth();

    const handleMonthlyData = (data) => {
        const today = moment();
        const currentYear = today.year();
        const currentMonth = today.month();
        const startOfMonth = moment().startOf("month");
        const startOfWeek = startOfMonth.startOf("week");
        const nOfWeeksOfTheMonth = getWeeksInMonth(currentYear, currentMonth + 1);
        const currWeekOfMonth = today.diff(startOfWeek, "weeks");
        const currentMonthFormat = today.format("MMM-YYYY");
        let currentSalesDays = [];
        let forecastSalesDays = [];
        for (let i = currWeekOfMonth; i >= 0; i--) {
            currentSalesDays?.push(moment().subtract(i, "weeks").format("DD/MMM/YYYY"));
        }
        for (let i = 0; i <= nOfWeeksOfTheMonth - currWeekOfMonth; i++) {
            forecastSalesDays?.push(moment().add(i, "days").format("DD/MMM/YYYY"));
        }

        const y = today.format("YYYY");
        const m = today.format("MMM");
        const currentSales = data?.currentSales?.[0];
        const currentForecast = data?.forecastSales?.[0];
        const forecastConfigs = data?.forecastConfigs?.[0];
        const monthKey = `${m}-${y}`;
        const currentSalesDaysData = (
            currentSales && currentSales[monthKey]
                ? Array.isArray(currentSales[monthKey])
                    ? currentSales[monthKey]
                    : typeof currentSales[monthKey] === "object"
                    ? Object.values(currentSales[monthKey])
                    : []
                : []
        )?.reduce((acc, item) => {
            const weekOfMonth = item?.weekOfMonth;
            if (!acc[weekOfMonth]) {
                acc[weekOfMonth] = {
                    value: 0,
                    quantity: 0,
                    week: weekOfMonth,
                };
            }
            acc[weekOfMonth].value = Math.round(item.totalSales || 0);
            acc[weekOfMonth].quantity = Math.round(item.totalQty || 0);
            return acc;
        }, {});

        const currentSalesDaysDataArray = Object.values(currentSalesDaysData || {});

        const forecastSalesDaysData = (
            currentForecast && currentForecast[monthKey]
                ? Array.isArray(currentForecast[monthKey])
                    ? currentForecast[monthKey]
                    : typeof currentForecast[monthKey] === "object"
                    ? Object.values(currentForecast[monthKey])
                    : []
                : []
        )?.reduce((acc, item) => {
            const weekOfMonth = item?.weekOfMonth;
            if (!acc[weekOfMonth]) {
                acc[weekOfMonth] = { value: 0, quantity: 0, week: weekOfMonth };
            }
            acc[item?.weekOfMonth].value = Math.round(item.totalSales || 0);
            acc[item?.weekOfMonth].quantity = Math.round(item.totalQty || 0);
            return acc;
        }, {});

        const forecastSalesDaysDataArray = Object.values(forecastSalesDaysData || {});

        let newArrayDays = [];
        for (let i = 0; i < currentSalesDaysDataArray?.length - 1; i++) {
            newArrayDays.push(null);
        }

        const arrayForecastSalesDaysData = newArrayDays.concat(forecastSalesDaysDataArray);

        currentSalesDays.pop();
        const daysOfTheMonth = new Array(nOfWeeksOfTheMonth)
            .fill(1)
            .map(
                (item, idx) =>
                    `Week ${idx + 1} (${
                        getWeekDateRange(currentYear, currentMonth + 1, idx + 1).start
                    } - ${getWeekDateRange(currentYear, currentMonth + 1, idx + 1).end})`
            );

        const forecastConfigDaysData = (
            forecastConfigs && forecastConfigs[currentMonthFormat]
                ? Array.isArray(forecastConfigs[currentMonthFormat])
                    ? forecastConfigs[currentMonthFormat]
                    : typeof forecastConfigs[currentMonthFormat] === "object"
                    ? Object.values(forecastConfigs[currentMonthFormat])
                    : []
                : []
        )?.reduce((acc, item, idx) => {
            const weekOfMonth = item?.weekOfMonth || idx + 1;
            if (!acc[weekOfMonth]) {
                acc[weekOfMonth] = { value: 0, quantity: 0, week: weekOfMonth };
            }
            acc[weekOfMonth].value = Math.round(item.totalSales || 0);
            acc[weekOfMonth].quantity = Math.round(item.totalQty || 0);
            return acc;
        }, {});

        const forecastConfigDaysDataArray = Object.values(forecastConfigDaysData || {});

        return [
            daysOfTheMonth,
            currentSalesDaysDataArray,
            arrayForecastSalesDaysData,
            forecastConfigDaysDataArray,
        ];
    };

    const handleYearlyData = (data) => {
        let currentSalesMonths = [];
        let forecastSalesMonths = [];
        for (let i = 6; i >= 0; i--) {
            currentSalesMonths?.push(moment().subtract(i, "months").format("MMM YYYY"));
        }
        for (let i = 0; i < 6; i++) {
            forecastSalesMonths?.push(moment().add(i, "months").format("MMM YYYY"));
        }
        const currentSalesMonthsData = currentSalesMonths.map((month) => {
            const [m, y] = month.split(" ");
            const monthKey = `${m}-${y}`;
            let salesData = { totalQty: 0, totalSales: 0 };

            (Array.isArray(data?.currentSales) ? data?.currentSales : [])?.forEach((monthData) => {
                if (monthData[monthKey]) {
                    salesData = monthData[monthKey][0];
                }
            });
            return {
                value: Math.round(salesData?.totalSales || 0),
                quantity: Math.round(salesData?.totalQty || 0),
            };
        });

        const forecastSalesMonthsData = forecastSalesMonths.map((month) => {
            const [m, y] = month.split(" ");
            const monthKey = `${m}-${y}`;
            let forecastData = { totalQty: 0, totalSales: 0 };
            (Array.isArray(data?.forecastSales) ? data?.forecastSales : [])?.forEach(
                (monthData) => {
                    if (monthData[monthKey]) {
                        forecastData = monthData[monthKey][0];
                    }
                }
            );
            return {
                value: Math.round(forecastData?.totalSales || 0),
                quantity: Math.round(forecastData?.totalQty || 0),
            };
        });

        let newArray = [];
        for (let i = 0; i < currentSalesMonthsData?.length - 1; i++) {
            newArray.push(null);
        }

        const arrayForecastSalesMonthsData = newArray.concat(forecastSalesMonthsData);

        currentSalesMonths.pop();
        let months = currentSalesMonths?.concat(forecastSalesMonths);

        const forecastConfigMonthsData = months.map((month) => {
            const [m, y] = month.split(" ");
            const monthKey = `${m}-${y}`;
            let forecastData = { totalQty: 0, totalSales: 0 };
            (Array.isArray(data?.forecastConfigs) ? data?.forecastConfigs : [])?.forEach(
                (monthData) => {
                    if (monthData[monthKey]) {
                        forecastData = monthData[monthKey][0];
                    }
                }
            );
            return forecastData?.totalSales || forecastData?.totalQty
                ? {
                      value: Math.round(forecastData?.totalSales || 0),
                      quantity: Math.round(forecastData?.totalQty || 0),
                  }
                : null;
        });
        return [
            months,
            currentSalesMonthsData,
            arrayForecastSalesMonthsData,
            forecastConfigMonthsData,
        ];
    };

    const handleCustomData = (data: any, date: string[], isWithinOneMonth: boolean) => {
        const customStartDate = moment(date[0], "MMM DD YYYY");
        const customEndDate = moment(date[1], "MMM DD YYYY");
        const startOfWeek = customStartDate.clone();
        const endOfWeek = customEndDate.clone();

        let currentSalesCustomDays = [];
        let forecastSalesCustomDays = [];

        const currentDateCount = customEndDate.diff(customStartDate, "days");
        const forecastDateCount = customEndDate.diff(customStartDate, "days");

        if (currentDateCount > 0) {
            if (Boolean(isWithinOneMonth)) {
                for (let m = startOfWeek.clone(); m.isSameOrBefore(endOfWeek); m.add(1, "week")) {
                    currentSalesCustomDays.push(m.format("DD/MMM/YYYY"));
                }

                const currentYear = customStartDate.year();
                const currentSalesDaysData = data?.currentSales?.reduce((acc, monthData) => {
                    Object.values(monthData)?.forEach((weeksArray: any) => {
                        weeksArray?.forEach((item) => {
                            const weekOfMonth = item?.weekOfMonth;
                            if (!acc[weekOfMonth]) {
                                acc[weekOfMonth] = { value: 0, quantity: 0, week: weekOfMonth };
                            }
                            acc[weekOfMonth].value += Math.round(item?.totalSales || 0);
                            acc[weekOfMonth].quantity += Math.round(item?.totalQty || 0);
                        });
                    });
                    return acc;
                }, {});

                const currentSalesDaysDataArray = Object.values(currentSalesDaysData || {});

                const forecastSalesDaysData = data?.forecastSales?.reduce((acc, monthData) => {
                    Object.values(monthData)?.forEach((weeksArray: any) => {
                        weeksArray?.forEach((item) => {
                            const weekOfMonth = item.weekOfMonth;
                            if (!acc[weekOfMonth]) {
                                acc[weekOfMonth] = { value: 0, quantity: 0, week: weekOfMonth };
                            }
                            acc[weekOfMonth].value += Math.round(item?.totalSales || 0);
                            acc[weekOfMonth].quantity += Math.round(item?.totalQty || 0);
                        });
                    });
                    return acc;
                }, {});

                const forecastSalesDaysDataArray = Object.values(forecastSalesDaysData || {});

                let newArrayDays = [];

                for (let i = 0; i < currentSalesDaysDataArray.length - 1; i++) {
                    newArrayDays.push(null);
                }

                const arrayForecastSalesDaysData = newArrayDays.concat(forecastSalesDaysDataArray);
                const arrayCurrentSalesDaysData = newArrayDays.concat(currentSalesDaysDataArray);

                const nOfWeeksOfTheMonth = Math.ceil(
                    customEndDate.diff(startOfWeek, "weeks", true)
                );
                const daysOfTheMonth = new Array(nOfWeeksOfTheMonth)
                    .fill(1)
                    .map(
                        (item, idx) =>
                            `Week ${idx + 1} (${startOfWeek
                                .clone()
                                .add(idx, "weeks")
                                .format("MMM DD YYYY")} - ${startOfWeek
                                .clone()
                                .add(idx, "weeks")
                                .add(6, "days")
                                .format("MMM DD YYYY")})`
                    );
                return [daysOfTheMonth, arrayCurrentSalesDaysData, arrayForecastSalesDaysData];
            } else {
                const uniqueMonths = new Set<string>();

                for (
                    let i = customStartDate.clone();
                    i.isSameOrBefore(customEndDate);
                    i.add(1, "month")
                ) {
                    uniqueMonths.add(i.format("MMM-YYYY"));
                }
                data.currentSales.forEach((item: any) => {
                    const monthYear = Object.keys(item)[0];
                    uniqueMonths.add(monthYear);
                });

                data.forecastSales.forEach((item: any) => {
                    const monthYear = Object.keys(item)[0];
                    uniqueMonths.add(monthYear);
                });

                const sortedUniqueMonths = Array.from(uniqueMonths).sort((a, b) => {
                    return moment(a, "MMM-YYYY").isBefore(moment(b, "MMM-YYYY")) ? -1 : 1;
                });
                const sortedCurrentSalesData = sortedUniqueMonths.map((monthYear) => {
                    const currentSales = data.currentSales.find((sale: any) => sale[monthYear]);
                    return currentSales
                        ? {
                              value: Math.round(currentSales[monthYear][0].totalSales || 0),
                              quantity: Math.round(currentSales[monthYear][0].totalQty || 0),
                          }
                        : {
                              totalSales: 0,
                              totalQty: 0,
                              year: +monthYear.split("-")[1],
                              month: moment().month(monthYear.split("-")[0]).format("M"),
                          };
                });
                const sortedForecastSalesData = sortedUniqueMonths?.map((monthYear) => {
                    const forecastSales = data?.forecastSales?.find((sale: any) => sale[monthYear]);
                    return forecastSales
                        ? {
                              value: Math.round(forecastSales[monthYear][0]?.totalSales || 0),
                              quantity: Math.round(forecastSales[monthYear][0]?.totalQty || 0),
                          }
                        : {
                              totalSales: 0,
                              totalQty: 0,
                              year: +monthYear.split("-")[1],
                              month: moment().month(monthYear?.split("-")[0]).format("M"),
                          };
                });

                return [sortedUniqueMonths, sortedCurrentSalesData, sortedForecastSalesData];
            }
        }
    };

    switch (filter) {
        case "weekly":
            let currentSalesWeek = [];
            let forecastSalesWeek = [];
            for (let i = 3; i >= 0; i--) {
                currentSalesWeek?.push(moment().subtract(i, "days").format("DD/MMM/YYYY"));
            }
            for (let i = 0; i <= 3; i++) {
                forecastSalesWeek?.push(moment().add(i, "days").format("DD/MMM/YYYY"));
            }

            const currentSalesWeekData = currentSalesWeek.map((month) => {
                const [d, m, y] = month.split("/");
                const foundItem = (data?.currentSales || {})?.[y]?.[m]?.days?.find((item) => {
                    return item?.day === +d ? item?.totalSale : 0;
                });
                return {
                    value: foundItem?.totalSale ? Math.round(foundItem?.totalSale) : 0,
                    quantity: foundItem?.totalQty || 0,
                };
            });

            const forecastSalesWeekData = forecastSalesWeek.map((month) => {
                const [d, m, y] = month.split("/");
                const foundItem = (data?.forecastSales || {})?.[y]?.[m]?.days?.find((item) => {
                    return item?.day === +d ? item?.totalSale : 0;
                });
                return {
                    value: foundItem?.totalSale ? Math.round(foundItem?.totalSale) : 0,
                    quantity: foundItem?.totalQty || 0,
                };
            });

            let newArrayWeek = [];
            for (let i = 0; i < currentSalesWeekData?.length - 1; i++) {
                newArrayWeek.push(null);
            }

            const arrayForecastSalesWeekData = newArrayWeek.concat(forecastSalesWeekData);

            currentSalesWeek.pop();
            let week = currentSalesWeek.concat(forecastSalesWeek);
            const getDayOfWeek = (dateString) => {
                const date = moment(dateString, "DD/MMM/YYYY");
                return date.format("ddd");
            };

            const daysOfWeek = week.map(getDayOfWeek);
            return [daysOfWeek, currentSalesWeekData, arrayForecastSalesWeekData];

        case "monthly":
            return handleMonthlyData(data);

        case "yearly":
            return handleYearlyData(data);

        case "custom": {
            const startDate = moment(date[0]);
            const endDate = moment(date[1]);
            const daysDifference = endDate.diff(startDate, "days");
            const isWithinThirtyDays = daysDifference <= 30;

            return handleCustomData(data, date, isWithinThirtyDays);
        }
        default:
            return [];
    }
};
