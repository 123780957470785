import React, { useMemo } from "react";
import PrivateRoutes from "components/common/Routes/PrivateRoute";
import PublicRoutes from "components/common/Routes/PublicRoute";
import NoMatchPage from "pages/Ldb/404Page";

// Private Pages
import Copilot from "pages/Ldb/Copilot";
import Dashboard from "pages/Ldb/Dashboard";
// import DashboardHome from "pages/Ldb/Home";
import PurchaseOrder from "pages/Ldb/Orders/PurchaseOrder";
import PurchaseOrderDetail from "pages/Ldb/Orders/PurchaseOrder/PurchaseOrderDetails";
import Quotes from "pages/Ldb/Orders/Quotes";
// import QuoteDetails from "pages/Ldb/Orders/Quotes/QuoteDetails";
import QuoteItemDetails from "pages/Ldb/Orders/Quotes/QuoteItemDetails";
import QuoteVendorDetails from "pages/Ldb/Orders/Quotes/QuoteVendorDetails";
import Rfqs from "pages/Ldb/Orders/Rfqs";
import CreateRfqs from "pages/Ldb/Orders/Rfqs/CreateRfqs";
// import OrderDetails from "pages/Ldb/Orders/Rfqs/OrderDetails";
import RfqDetails from "pages/Ldb/Orders/Rfqs/RfqDetails";
import Shipment from "pages/Ldb/Shipment";
import ShipmentDetails from "pages/Ldb/Shipment/ShipmentDetails";
import Inventory from "pages/Ldb/Inventory/ProductList";
import AddProduct from "components/views/Synth2.0/Inventory/Tabs/ProductList/AddProduct";
import AddBundle from "components/views/Synth2.0/Inventory/Tabs/ProductList/AddBundleProduct";
import BundleDetails from "components/views/Synth2.0/Inventory/Tabs/ProductList/BundleDetails";
import TeamManagement from "pages/Synth2.0/TeamManagement";
import AddVendor from "pages/Ldb/VendorManagement/AddVendor";
import VendorAnalytics from "pages/Ldb/VendorManagement/VendorAnalytics";
import VendorDetails from "pages/Ldb/VendorManagement/VendorDetails";

import Tasks from "pages/Ldb/Task";
import VendorList from "pages/Ldb/VendorManagement/VendorList";
import EditPurchaseOrder from "pages/Ldb/Orders/PurchaseOrder/EditOrders";
import CreatePurchaseRequest from "pages/Synth2.0/Purchase/CreatePurchaseRequest";
import EditPurchaseRequest from "pages/Synth2.0/Purchase/EditPurchaseRequest";
import PurchaseOrderAnalytics from "pages/Ldb/BusinessAnalytics/PurchaseOrder";
import Settings from "pages/Ldb/Settings";
import ProcurementCycle from "pages/Ldb/BusinessAnalytics/ProcurementCycle";
import AnalyticsPurchaseOrder from "pages/Ldb/BusinessAnalytics/PurchaseOrder";
import SpendAnalysis from "pages/Ldb/BusinessAnalytics/SpendAnalysis";
import ProductDetailsPage from "pages/Ldb/Inventory/ProductList/ProductDetails";
import WareHouse from "pages/Ldb/Inventory/Warehouse";
import AddWareHouse from "pages/Ldb/Inventory/Warehouse/AddWarehouse";
import EditWareHouse from "pages/Ldb/Inventory/Warehouse/EditWarehouse";
import WarehouseDetails from "components/views/Synth2.0/Inventory/Tabs/WarehouseDetails";
import Purchase from "pages/Synth2.0/Purchase";
import PurchaseSettings from "pages/Synth2.0/Purchase/Settings";
import Suppliers from "pages/Synth2.0/Suppliers";
import CreateSuppliers from "pages/Synth2.0/Suppliers/createSuppliers";
import SupplierDetails from "pages/Synth2.0/Suppliers/supplierDetails";
import SupplierContract from "pages/Synth2.0/Suppliers/supplierContract";
import SalesDetails from "pages/Synth2.0/Sales/SalesDetails";
import DashboardHome from "pages/Synth2.0/Dashboard";

// end private pages

// Public Pages
import Home from "pages/Landing/Home";
// import BookADemo from "pages/Landing/BookADemo";
import WaitList from "pages/Landing/WaitList";
// import Pricing from "pages/Landing/Pricing";
import Terms from "pages/Landing/Terms";
import Policy from "pages/Landing/Policy";
import AuthSteps from "pages/Ldb/Auth/AuthSteps";
import Login from "pages/Ldb/Auth/Login";
import Signup from "pages/Ldb/Auth/Signup";
import ForgotPassword from "pages/Ldb/Auth/ForgotPassword";
import ResetPassword from "pages/Ldb/Auth/ResetPassword";
import SignupSuccess from "pages/Ldb/Auth/SignupSuccess";
import NewOnboard from "pages/Ldb/Onboarding/newOnboard";
import AcctVerification from "pages/Ldb/Auth/AcctVerification";
import ChangeVerificationEmail from "pages/Ldb/Auth/ChangeVerificationEmail";
import GoogleAuth from "pages/Ldb/Auth/GoogleAuth";
import QuoteDetails from "pages/Synth2.0/Purchase/QuoteDetails";
import Playground from "pages/Synth2.0/Playground";
import Integration from "pages/Synth2.0/Integration";
import ShipmentPurchaseDetails from "pages/Synth2.0/Purchase/ShipmentPurchaseDetails";
import PurchaseDetails from "pages/Synth2.0/Purchase/PurchaseOrderDetails";
import IntegrationDetails from "pages/Synth2.0/Integration/integrationDetails";
import Products from "pages/Synth2.0/Inventory";
import ImportProducts from "pages/Synth2.0/Inventory/ImportProducts";
import Workforce from "pages/Synth2.0/Workforce";
import CreateAiTeammate from "pages/Synth2.0/Workforce/CreateAiTeammate";
import TaskManager from "pages/Synth2.0/TaskManager";
import TaskDetail from "pages/Synth2.0/TaskManager/TaskDetail";
import EditOrderStep from "pages/Synth2.0/TaskManager/EditOrderStep";
import AlertDetails from "components/views/Synth2.0/Inventory/Tabs/Alerts/AlertDetails";
import ProductDetail from "components/views/Synth2.0/Inventory/Tabs/ProductList/ProductDetail";
import AddStock from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockEntry/AddStockCount";
import StockAdjustment from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockAdjustment/StockAdjustment";
import StockAdjustmentDetails from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockAdjustment/StockAdjustmentDetails";
import StockTransfer from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockTransfer/StockTransfer";
import StockTransferDetails from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockTransfer/StockTransferDetails";
import StockDetails from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockEntry/StockDetails";
import EditStock from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockEntry/EditStock";
import Order from "pages/Synth2.0/Order";
import OrderDetails from "pages/Synth2.0/Order/OrderDetails";
import CreateOrder from "pages/Synth2.0/Order/CreateOrder";
import OrderSettings from "pages/Synth2.0/Order/Settings";
import ShopifyAuth from "pages/Synth2.0/Integration/ShopifyAuth";
// import CreateOrder from "pages/Synth2.0/Purchase/CreatePurchaseRequest";
import EditProduct from "components/views/Synth2.0/Inventory/Tabs/ProductList/EditProduct";
import TrackAndTrace from "pages/Synth2.0/TrackAndTrace";
import ProductTrackDetails from "components/views/Synth2.0/TrackTrace/ProductTrackDetails";
import CreateQuote from "pages/Synth2.0/Order/CreateQuote";
import QuoteSingleDetails from "pages/Synth2.0/Order/Quotedetails";
import CreateSku from "pages/Synth2.0/Inventory/SkuCreation/index";
import ExpandedDetails from "components/views/Synth2.0/Order/ExpandedDetails";
import MappedSkuDetail from "components/views/Synth2.0/Inventory/Tabs/SkuMapping/MappedSkuDetail";
import EditSku from "components/views/Synth2.0/Inventory/Tabs/SkuMapping/EditSku";

import { ChannelProvider } from "ably/react";
import { getFromStorage } from "helpers";
import useGetUserProfile from "hooks/useGetUserProfile";

const AppRoutes = () => {
    const [profile] = useGetUserProfile();
    const userProfile = getFromStorage("ally-user");
    // console.log("userProfile", userProfile);
    // console.log("profile", profile);

    const routes = [
        {
            path: "",
            element: <Home />,
        },
        // {
        //     path: "/book-a-demo",
        //     element: <Home />,
        // },
        {
            path: "/wait-list",
            element: <Home />,
        },
        {
            path: "/terms",
            element: <Terms />,
        },
        {
            path: "/policy",
            element: <Policy />,
        },

        // {
        //     path: "/auth-steps",
        //     element: (
        //         <PrivateRoutes>
        //             <AuthSteps />
        //         </PrivateRoutes>
        //     ),
        // },
        {
            path: "/login",
            element: (
                <PublicRoutes>
                    <Login />
                </PublicRoutes>
            ),
        },
        // {
        //     path: "/register",
        //     element: (
        //         <PublicRoutes>
        //             <Signup />
        //         </PublicRoutes>
        //     ),
        // },

        {
            path: "/forgot-password",
            element: (
                <PublicRoutes>
                    <ForgotPassword />
                </PublicRoutes>
            ),
        },
        {
            path: "/reset-password",
            element: (
                <PublicRoutes>
                    <ResetPassword />
                </PublicRoutes>
            ),
        },
        {
            path: "/registration-success/:token",
            element: (
                <PublicRoutes>
                    <SignupSuccess />
                </PublicRoutes>
            ),
        },
        {
            path: "/new-onboard",
            element: (
                <PrivateRoutes>
                    <NewOnboard />
                </PrivateRoutes>
            ),
        },
        {
            path: "/verify",
            element: (
                <PublicRoutes>
                    <AcctVerification />
                </PublicRoutes>
            ),
        },
        {
            path: "/change-email/:token",
            element: (
                <PublicRoutes>
                    <ChangeVerificationEmail />
                </PublicRoutes>
            ),
        },

        {
            path: "/google-auth",
            element: (
                <PublicRoutes>
                    <GoogleAuth />
                </PublicRoutes>
            ),
        },
        {
            path: "/integrations/shopify",
            element: (
                <PublicRoutes>
                    <ShopifyAuth />
                </PublicRoutes>
            ),
        },
        {
            path: "/dashboard",
            element: <Dashboard />,
            children: [
                {
                    path: "home",
                    element: (
                        <ChannelProvider
                            channelName={`synth-ai-chat-${
                                process.env.REACT_APP_STAGE === "production"
                                    ? "production"
                                    : "staging"
                            }`}
                        >
                            <DashboardHome />
                        </ChannelProvider>
                    ),
                },

                // start of synthally v2.0 routes
                {
                    path: "inventory",
                    element: <Products />,
                },
                {
                    path: "inventory/mapped-sku/:productId",
                    element: <MappedSkuDetail />,
                },
                {
                    path: "inventory/import-products",
                    element: <ImportProducts />,
                },
                {
                    path: "inventory/alert/:alertId/:inventoryIdx",
                    element: <AlertDetails />,
                },
                {
                    path: "inventory/stock-control/stock-entry",

                    element: <AddStock />,
                },
                {
                    path: "inventory/stock-control/stock-entry/:id",

                    element: <StockDetails />,
                },
                {
                    path: "inventory/stock-control/edit-stock/:id",

                    element: <EditStock />,
                },
                {
                    path: "inventory/stock-control/stock-adjustment",

                    element: <StockAdjustment />,
                },
                {
                    path: "inventory/stock-control/stock-adjustment/:id",

                    element: <StockAdjustmentDetails />,
                },
                {
                    path: "inventory/stock-control/stock-transfer",

                    element: <StockTransfer />,
                },
                {
                    path: "inventory/stock-control/transfer-stock/:id",

                    element: <StockTransferDetails />,
                },

                {
                    path: "inventory/sku-mapping/create",

                    element: <CreateSku />,
                },
                {
                    path: "inventory/mapped-sku/edit/:productId",

                    element: <EditSku />,
                },
                // {
                //     path: "inventory/stock-control/transfer-stock/:id",
                //     element: <PreviewTransferStock />,
                // },
                {
                    path: "order",
                    element: <Order />,
                },
                {
                    path: "order/dashboard-details",
                    element: <ExpandedDetails />,
                },
                {
                    path: "order/:orderId",
                    element: <OrderDetails />,
                },
                {
                    path: "order/create",
                    element: <CreateOrder />,
                },
                {
                    path: "quote/:quoteId",
                    element: <QuoteSingleDetails />,
                },
                {
                    path: "quote/create",
                    element: <CreateQuote />,
                },
                {
                    path: "order/settings",
                    element: <OrderSettings />,
                },
                {
                    path: "purchase",
                    element: <Purchase />,
                },
                {
                    path: "purchase/:purchaseId",
                    element: <PurchaseDetails />,
                },
                {
                    path: "purchase/shipment/:purchaseId",
                    element: <ShipmentPurchaseDetails />,
                },
                {
                    path: "purchase/:purchaseId/quotes/:quoteId",
                    element: <QuoteDetails />,
                },
                {
                    path: "playground",
                    element: <Playground />,
                },
                {
                    path: "purchase/create-purchase-request",
                    element: <CreatePurchaseRequest />,
                },
                {
                    path: "purchase/edit-purchase-request/:editId",
                    element: <EditPurchaseRequest />,
                },

                {
                    path: "purchase/settings",
                    element: <PurchaseSettings />,
                },
                {
                    path: "integrations",
                    element: <Integration />,
                },
                {
                    path: "integrations/:integrationName/:integrationId",
                    element: <IntegrationDetails />,
                },
                {
                    path: "inventory/products/:productId",
                    element: <ProductDetail />,
                },
                {
                    path: "teams",
                    element: <TeamManagement />,
                },
                {
                    path: "suppliers",
                    element: <Suppliers />,
                },
                {
                    path: "suppliers/create-supplier",
                    element: <CreateSuppliers />,
                },
                {
                    path: "suppliers/edit-supplier/:supplierId",
                    element: <CreateSuppliers />,
                },
                {
                    path: "suppliers/:id",
                    element: <SupplierDetails />,
                },
                {
                    path: "suppliers/:id/contract",
                    element: <SupplierContract />,
                },
                {
                    path: "track-trace",
                    element: <TrackAndTrace />,
                },
                {
                    path: "track-trace/:id",
                    element: <ProductTrackDetails />,
                },
                {
                    path: "workforce",
                    element: <Workforce />,
                },
                {
                    path: "workforce/create-ai-teammate",
                    element: <CreateAiTeammate />,
                },
                {
                    path: "task",
                    element: (
                        <ChannelProvider
                            channelName={`agent_tasks_${
                                userProfile?.user?.parentId || userProfile?.user?._id
                            }`}
                        >
                            <TaskManager />
                        </ChannelProvider>
                    ),
                },
                {
                    path: "task/:taskId",
                    element: (
                        <ChannelProvider
                            channelName={`agent_tasks_${
                                userProfile?.user?.parentId || userProfile?.user?._id
                            }`}
                        >
                            <TaskDetail />
                        </ChannelProvider>
                    ),
                },
                {
                    path: "task/:taskId/edit-order",
                    element: <EditOrderStep />,
                },
                // end of synthally v2.0 routes

                // start of synthally v1.0 routes
                // {
                //     path: "workflow",
                //     element: <WorkFlow />,
                // },
                // {
                //     path: "workflow/run/:workflowId",
                //     element: <WorkFlowRun />,
                // },
                // {
                //     path: "workflow/:workflowId",
                //     element: <WorkflowGraph />,
                // },
                // {
                //     path: "workflow/template",
                //     element: <WorkflowGraph />,
                // },
                // {
                //     path: "task/:id",
                //     element: <Tasks />,
                // },
                {
                    path: "vendors",
                    element: <VendorList />,
                },
                {
                    path: "vendors/add-vendor",
                    element: <AddVendor />,
                },
                {
                    path: "vendor/:vendorId",
                    element: <VendorDetails />,
                },
                {
                    path: "vendors/analytics",
                    element: <VendorAnalytics />,
                },
                {
                    path: "shipment",
                    element: <Shipment />,
                },
                {
                    path: "shipment/:shipmentId",
                    element: <ShipmentDetails />,
                },
                // {
                //     path: "inventory",
                //     element: <Inventory />,
                // },
                {
                    path: "inventory/add-product",
                    element: <AddProduct />,
                },
                {
                    path: "inventory/edit-product/:productId",
                    element: <AddProduct />,
                },
                {
                    path: "inventory/add-bundle",
                    element: <AddBundle />,
                },
                {
                    path: "inventory/edit-bundle/:bundleId",
                    element: <AddBundle />,
                },
                {
                    path: "inventory/bundle/:bundleId",
                    element: <BundleDetails />,
                },
                // {
                //     path: "sales/:id",
                //     element: <SalesDetails />,
                // },
                // {
                //     path: "inventory/product/:id",
                //     element: <ProductDetailsPage />,
                // },
                // {
                //     path: "inventory/stock-control",
                //     element: <StockControl />,
                // },
                // {
                //     path: "inventory/stock-control/:id",alert/1
                //     element: <StockDetail />,
                // },
                // {
                //     path: "inventory/stock-control/new-transfer",
                //     element: <NewStockTransfer />,
                // },
                // {
                //     path: "inventory/stock-control/transfer-stock/:id",
                //     element: <PreviewTransferStock />,
                // },
                // {
                //     path: "inventory/order-stock",
                //     element: <OrderStock />,
                // },
                // {
                //     path: "inventory/stock-count/:inventoryId/warehouse/:warehouseId",
                //     element: <StockCountHistory />,
                // },
                // {
                //     path: "inventory/warehouse",
                //     element: <WareHouse />,
                // },
                {
                    path: "inventory/add-warehouse",
                    element: <AddWareHouse />,
                },
                {
                    path: "inventory/edit-warehouse/:warehouseId",
                    element: <AddWareHouse />,
                },
                {
                    path: "inventory/warehouse/:id",
                    element: <WarehouseDetails />,
                },
                // {
                //     path: "inventory/stock-control/edit-stock/:id",
                //     element: <EditStock />,
                // },
                // {
                //     path: "rfqs",
                //     element: <Rfqs />,
                // },
                // {
                //     path: "co-pilot",
                //     element: <Copilot />,
                // },
                // {
                //     path: "quotes",
                //     element: <Quotes />,
                // },
                // {
                //     path: "quotes/:quoteId",
                //     element: <QuoteDetails />,
                // },
                // {
                //     path: "quotes/:quoteId/responses",
                //     element: <QuoteItemDetails />,
                // },
                // {
                //     path: "order/:orderId",
                //     element: <OrderDetails />,
                // },
                // {
                //     path: "quotes/:quoteId/item/:itemId/quote-detail/:id",
                //     element: <QuoteVendorDetails />,
                // },
                {
                    path: "purchase-orders",
                    element: <PurchaseOrder />,
                },
                {
                    path: "purchase-orders/:poId",
                    element: <PurchaseOrderDetail />,
                },
                {
                    path: "purchase-orders/edit",
                    element: <EditPurchaseOrder />,
                },
                // {
                //     path: "rfq/:rfqId",
                //     element: <RfqDetails />,
                // },
                // {
                //     path: "rfqs/create",
                //     element: <CreateRfqs />,
                // },
                {
                    path: "team-management",
                    element: <TeamManagement />,
                },
                // {
                //     path: "analytics/purchase-orders",
                //     element: <PurchaseOrderAnalytics />,
                // },
                // {
                //     path: "analytics/procurement-cycle",
                //     element: <ProcurementCycle />,
                // },
                // {
                //     path: "analytics/spend-analysis",
                //     element: <SpendAnalysis />,
                // },
                // {
                //     path: "analytics/purchase-orders",
                //     element: <AnalyticsPurchaseOrder />,
                // },
                // {
                //     path: "analytics/procurement-cycle",
                //     element: <ProcurementCycle />,
                // },
                {
                    path: "settings/:id",
                    element: <Settings />,
                },
                // end of synthally v1.0 routes
            ]?.filter((item) => {
                if (profile?.role === "owner") {
                    return true;
                } else {
                    return !item?.path?.toLowerCase()?.includes("workforce");
                }
            }),
        },

        {
            path: "*",
            element: <NoMatchPage />,
        },
    ];

    return routes;
};

// const routes = [
//     {
//         path: "",
//         element: <Home />,
//     },
//     // {
//     //     path: "/book-a-demo",
//     //     element: <Home />,
//     // },
//     {
//         path: "/wait-list",
//         element: <Home />,
//     },
//     {
//         path: "/terms",
//         element: <Terms />,
//     },
//     {
//         path: "/policy",
//         element: <Policy />,
//     },

//     // {
//     //     path: "/auth-steps",
//     //     element: (
//     //         <PrivateRoutes>
//     //             <AuthSteps />
//     //         </PrivateRoutes>
//     //     ),
//     // },
//     {
//         path: "/login",
//         element: (
//             <PublicRoutes>
//                 <Login />
//             </PublicRoutes>
//         ),
//     },
//     // {
//     //     path: "/register",
//     //     element: (
//     //         <PublicRoutes>
//     //             <Signup />
//     //         </PublicRoutes>
//     //     ),
//     // },

//     {
//         path: "/forgot-password",
//         element: (
//             <PublicRoutes>
//                 <ForgotPassword />
//             </PublicRoutes>
//         ),
//     },
//     {
//         path: "/reset-password",
//         element: (
//             <PublicRoutes>
//                 <ResetPassword />
//             </PublicRoutes>
//         ),
//     },
//     {
//         path: "/registration-success/:token",
//         element: (
//             <PublicRoutes>
//                 <SignupSuccess />
//             </PublicRoutes>
//         ),
//     },
//     {
//         path: "/new-onboard",
//         element: (
//             <PrivateRoutes>
//                 <NewOnboard />
//             </PrivateRoutes>
//         ),
//     },
//     {
//         path: "/verify",
//         element: (
//             <PublicRoutes>
//                 <AcctVerification />
//             </PublicRoutes>
//         ),
//     },
//     {
//         path: "/change-email/:token",
//         element: (
//             <PublicRoutes>
//                 <ChangeVerificationEmail />
//             </PublicRoutes>
//         ),
//     },

//     {
//         path: "/google-auth",
//         element: (
//             <PublicRoutes>
//                 <GoogleAuth />
//             </PublicRoutes>
//         ),
//     },
//     {
//         path: "/integrations/shopify",
//         element: (
//             <PublicRoutes>
//                 <ShopifyAuth />
//             </PublicRoutes>
//         ),
//     },
//     {
//         path: "/dashboard",
//         element: <Dashboard />,
//         children: [
//             {
//                 path: "home",
//                 element: (
//                     <ChannelProvider
//                         channelName={`synth-ai-chat-${
//                             process.env.REACT_APP_STAGE === "production" ? "production" : "staging"
//                         }`}
//                     >
//                         <DashboardHome />
//                     </ChannelProvider>
//                 ),
//             },

//             // start of synthally v2.0 routes
//             {
//                 path: "inventory",
//                 element: <Products />,
//             },
//             {
//                 path: "inventory/mapped-sku/:productId",
//                 element: <MappedSkuDetail />,
//             },
//             {
//                 path: "inventory/import-products",
//                 element: <ImportProducts />,
//             },
//             {
//                 path: "inventory/alert/:alertId/:inventoryIdx",
//                 element: <AlertDetails />,
//             },
//             {
//                 path: "inventory/stock-control/stock-entry",

//                 element: <AddStock />,
//             },
//             {
//                 path: "inventory/stock-control/stock-entry/:id",

//                 element: <StockDetails />,
//             },
//             {
//                 path: "inventory/stock-control/edit-stock/:id",

//                 element: <EditStock />,
//             },
//             {
//                 path: "inventory/stock-control/stock-adjustment",

//                 element: <StockAdjustment />,
//             },
//             {
//                 path: "inventory/stock-control/stock-adjustment/:id",

//                 element: <StockAdjustmentDetails />,
//             },
//             {
//                 path: "inventory/stock-control/stock-transfer",

//                 element: <StockTransfer />,
//             },
//             {
//                 path: "inventory/stock-control/transfer-stock/:id",

//                 element: <StockTransferDetails />,
//             },

//             {
//                 path: "inventory/sku-mapping/create",

//                 element: <CreateSku />,
//             },
//             {
//                 path: "inventory/mapped-sku/edit/:productId",

//                 element: <EditSku />,
//             },
//             // {
//             //     path: "inventory/stock-control/transfer-stock/:id",
//             //     element: <PreviewTransferStock />,
//             // },
//             {
//                 path: "order",
//                 element: <Order />,
//             },
//             {
//                 path: "order/dashboard-details",
//                 element: <ExpandedDetails />,
//             },
//             {
//                 path: "order/:orderId",
//                 element: <OrderDetails />,
//             },
//             {
//                 path: "order/create",
//                 element: <CreateOrder />,
//             },
//             {
//                 path: "quote/:quoteId",
//                 element: <QuoteSingleDetails />,
//             },
//             {
//                 path: "quote/create",
//                 element: <CreateQuote />,
//             },
//             {
//                 path: "order/settings",
//                 element: <OrderSettings />,
//             },
//             {
//                 path: "purchase",
//                 element: <Purchase />,
//             },
//             {
//                 path: "purchase/:purchaseId",
//                 element: <PurchaseDetails />,
//             },
//             {
//                 path: "purchase/shipment/:purchaseId",
//                 element: <ShipmentPurchaseDetails />,
//             },
//             {
//                 path: "purchase/:purchaseId/quotes/:quoteId",
//                 element: <QuoteDetails />,
//             },
//             {
//                 path: "playground",
//                 element: <Playground />,
//             },
//             {
//                 path: "purchase/create-purchase-request",
//                 element: <CreatePurchaseRequest />,
//             },
//             {
//                 path: "purchase/edit-purchase-request/:editId",
//                 element: <EditPurchaseRequest />,
//             },

//             {
//                 path: "purchase/settings",
//                 element: <PurchaseSettings />,
//             },
//             {
//                 path: "integrations",
//                 element: <Integration />,
//             },
//             {
//                 path: "integrations/:integrationName/:integrationId",
//                 element: <IntegrationDetails />,
//             },
//             {
//                 path: "inventory/products/:productId",
//                 element: <ProductDetail />,
//             },
//             {
//                 path: "teams",
//                 element: <TeamManagement />,
//             },
//             {
//                 path: "suppliers",
//                 element: <Suppliers />,
//             },
//             {
//                 path: "suppliers/create-supplier",
//                 element: <CreateSuppliers />,
//             },
//             {
//                 path: "suppliers/edit-supplier/:supplierId",
//                 element: <CreateSuppliers />,
//             },
//             {
//                 path: "suppliers/:id",
//                 element: <SupplierDetails />,
//             },
//             {
//                 path: "suppliers/:id/contract",
//                 element: <SupplierContract />,
//             },
//             {
//                 path: "track-trace",
//                 element: <TrackAndTrace />,
//             },
//             {
//                 path: "track-trace/:id",
//                 element: <ProductTrackDetails />,
//             },
//             {
//                 path: "workforce",
//                 element: <Workforce />,
//             },
//             {
//                 path: "workforce/create-ai-teammate",
//                 element: <CreateAiTeammate />,
//             },
//             {
//                 path: "task",
//                 element: <TaskManager />,
//             },
//             {
//                 path: "task/:taskId",
//                 element: <TaskDetail />,
//             },
//             {
//                 path: "task/:taskId/edit-order",
//                 element: <EditOrderStep />,
//             },
//             // end of synthally v2.0 routes

//             // start of synthally v1.0 routes
//             // {
//             //     path: "workflow",
//             //     element: <WorkFlow />,
//             // },
//             // {
//             //     path: "workflow/run/:workflowId",
//             //     element: <WorkFlowRun />,
//             // },
//             // {
//             //     path: "workflow/:workflowId",
//             //     element: <WorkflowGraph />,
//             // },
//             // {
//             //     path: "workflow/template",
//             //     element: <WorkflowGraph />,
//             // },
//             // {
//             //     path: "task/:id",
//             //     element: <Tasks />,
//             // },
//             {
//                 path: "vendors",
//                 element: <VendorList />,
//             },
//             {
//                 path: "vendors/add-vendor",
//                 element: <AddVendor />,
//             },
//             {
//                 path: "vendor/:vendorId",
//                 element: <VendorDetails />,
//             },
//             {
//                 path: "vendors/analytics",
//                 element: <VendorAnalytics />,
//             },
//             {
//                 path: "shipment",
//                 element: <Shipment />,
//             },
//             {
//                 path: "shipment/:shipmentId",
//                 element: <ShipmentDetails />,
//             },
//             // {
//             //     path: "inventory",
//             //     element: <Inventory />,
//             // },
//             {
//                 path: "inventory/add-product",
//                 element: <AddProduct />,
//             },
//             {
//                 path: "inventory/edit-product/:productId",
//                 element: <AddProduct />,
//             },
//             {
//                 path: "inventory/add-bundle",
//                 element: <AddBundle />,
//             },
//             {
//                 path: "inventory/edit-bundle/:bundleId",
//                 element: <AddBundle />,
//             },
//             {
//                 path: "inventory/bundle/:bundleId",
//                 element: <BundleDetails />,
//             },
//             // {
//             //     path: "sales/:id",
//             //     element: <SalesDetails />,
//             // },
//             // {
//             //     path: "inventory/product/:id",
//             //     element: <ProductDetailsPage />,
//             // },
//             // {
//             //     path: "inventory/stock-control",
//             //     element: <StockControl />,
//             // },
//             // {
//             //     path: "inventory/stock-control/:id",alert/1
//             //     element: <StockDetail />,
//             // },
//             // {
//             //     path: "inventory/stock-control/new-transfer",
//             //     element: <NewStockTransfer />,
//             // },
//             // {
//             //     path: "inventory/stock-control/transfer-stock/:id",
//             //     element: <PreviewTransferStock />,
//             // },
//             // {
//             //     path: "inventory/order-stock",
//             //     element: <OrderStock />,
//             // },
//             // {
//             //     path: "inventory/stock-count/:inventoryId/warehouse/:warehouseId",
//             //     element: <StockCountHistory />,
//             // },
//             // {
//             //     path: "inventory/warehouse",
//             //     element: <WareHouse />,
//             // },
//             {
//                 path: "inventory/add-warehouse",
//                 element: <AddWareHouse />,
//             },
//             {
//                 path: "inventory/edit-warehouse/:warehouseId",
//                 element: <AddWareHouse />,
//             },
//             {
//                 path: "inventory/warehouse/:id",
//                 element: <WarehouseDetails />,
//             },
//             // {
//             //     path: "inventory/stock-control/edit-stock/:id",
//             //     element: <EditStock />,
//             // },
//             // {
//             //     path: "rfqs",
//             //     element: <Rfqs />,
//             // },
//             // {
//             //     path: "co-pilot",
//             //     element: <Copilot />,
//             // },
//             // {
//             //     path: "quotes",
//             //     element: <Quotes />,
//             // },
//             // {
//             //     path: "quotes/:quoteId",
//             //     element: <QuoteDetails />,
//             // },
//             // {
//             //     path: "quotes/:quoteId/responses",
//             //     element: <QuoteItemDetails />,
//             // },
//             // {
//             //     path: "order/:orderId",
//             //     element: <OrderDetails />,
//             // },
//             // {
//             //     path: "quotes/:quoteId/item/:itemId/quote-detail/:id",
//             //     element: <QuoteVendorDetails />,
//             // },
//             {
//                 path: "purchase-orders",
//                 element: <PurchaseOrder />,
//             },
//             {
//                 path: "purchase-orders/:poId",
//                 element: <PurchaseOrderDetail />,
//             },
//             {
//                 path: "purchase-orders/edit",
//                 element: <EditPurchaseOrder />,
//             },
//             // {
//             //     path: "rfq/:rfqId",
//             //     element: <RfqDetails />,
//             // },
//             // {
//             //     path: "rfqs/create",
//             //     element: <CreateRfqs />,
//             // },
//             {
//                 path: "team-management",
//                 element: <TeamManagement />,
//             },
//             // {
//             //     path: "analytics/purchase-orders",
//             //     element: <PurchaseOrderAnalytics />,
//             // },
//             // {
//             //     path: "analytics/procurement-cycle",
//             //     element: <ProcurementCycle />,
//             // },
//             // {
//             //     path: "analytics/spend-analysis",
//             //     element: <SpendAnalysis />,
//             // },
//             // {
//             //     path: "analytics/purchase-orders",
//             //     element: <AnalyticsPurchaseOrder />,
//             // },
//             // {
//             //     path: "analytics/procurement-cycle",
//             //     element: <ProcurementCycle />,
//             // },
//             {
//                 path: "settings/:id",
//                 element: <Settings />,
//             },
//             // end of synthally v1.0 routes
//         ]?.filter((item) => {
//             if (profile?.user?.role === "owner") {
//                 return true;
//             } else {
//                 return !item?.path?.includes("workforce");
//             }
//         }),
//     },

//     {
//         path: "*",
//         element: <NoMatchPage />,
//     },
// ];

// console.log("routes", routes);

export default AppRoutes;
