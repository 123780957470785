import { workforceTypes } from "../types";

const initialState = {
    fetchingAiAgents: false,
    fetchAiAgentSuccess: null,
    fetchAiAgentFailure: null,
    fetchingAiTeammates: false,
    fetchAiTeammatesSuccess: null,
    fetchAiTeammatesFailure: null,
    creatingAiTeammate: false,
    createAiTeammateSuccess: null,
    createAiTeammateFailure: null,
    fetchingTaskList: false,
    fetchTaskListSuccess: null,
    fetchTaskListFailure: null,
    fetchingSingleTask: false,
    fetchSingleTaskSuccess: null,
    fetchSingleTaskFailure: null,
    updatingTaskSalesOrder: false,
    updateTaskSalesOrderSuccess: null,
    updateTaskSalesOrderFailure: null,
    approvingTaskStep: false,
    approveTaskStepSuccess: null,
    approveTaskStepFailure: null,
    retryingTaskStep: false,
    retryTaskStepSuccess: null,
    retryTaskStepFailure: null,
    givingTaskStepFeedback: false,
    giveTaskStepFeedbackSuccess: null,
    giveTaskStepFeedbackFailure: null,
    fetchingTaskAnalytics: false,
    fetchTaskAnalyticsSuccess: null,
    fetchTaskAnalyticsFailure: null,
};

export const workforce = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case workforceTypes.FETCH_TASK_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingTaskAnalytics: true,
                fetchTaskAnalyticsSuccess: null,
                fetchTaskAnalyticsFailure: null,
            };
        case workforceTypes.FETCH_TASK_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingTaskAnalytics: false,
                fetchTaskAnalyticsSuccess: action.payload,
                fetchTaskAnalyticsFailure: null,
            };
        case workforceTypes.FETCH_TASK_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingTaskAnalytics: false,
                fetchTaskAnalyticsSuccess: null,
                fetchTaskAnalyticsFailure: action.payload,
            };

        case workforceTypes.GIVE_TASK_STEP_FEEDBACK_REQUEST:
            return {
                ...state,
                givingTaskStepFeedback: true,
                giveTaskStepFeedbackSuccess: null,
                giveTaskStepFeedbackFailure: null,
            };
        case workforceTypes.GIVE_TASK_STEP_FEEDBACK_SUCCESS:
            return {
                ...state,
                givingTaskStepFeedback: false,
                giveTaskStepFeedbackSuccess: action.payload,
                giveTaskStepFeedbackFailure: null,
            };
        case workforceTypes.GIVE_TASK_STEP_FEEDBACK_FAILURE:
            return {
                ...state,
                givingTaskStepFeedback: false,
                giveTaskStepFeedbackSuccess: null,
                giveTaskStepFeedbackFailure: action.payload,
            };

        case workforceTypes.RETRY_TASK_STEP_REQUEST:
            return {
                ...state,
                retryingTaskStep: true,
                retryTaskStepSuccess: null,
                retryTaskStepFailure: null,
            };
        case workforceTypes.RETRY_TASK_STEP_SUCCESS:
            return {
                ...state,
                retryingTaskStep: false,
                retryTaskStepSuccess: action.payload,
                retryTaskStepFailure: null,
            };
        case workforceTypes.RETRY_TASK_STEP_FAILURE:
            return {
                ...state,
                retryingTaskStep: false,
                retryTaskStepSuccess: null,
                retryTaskStepFailure: action.payload,
            };

        case workforceTypes.APPROVE_TASK_STEP_REQUEST:
            return {
                ...state,
                approvingTaskStep: true,
                approveTaskStepSuccess: null,
                approveTaskStepFailure: null,
            };
        case workforceTypes.APPROVE_TASK_STEP_SUCCESS:
            return {
                ...state,
                approvingTaskStep: false,
                approveTaskStepSuccess: action.payload,
                approveTaskStepFailure: null,
            };
        case workforceTypes.APPROVE_TASK_STEP_FAILURE:
            return {
                ...state,
                approvingTaskStep: false,
                approveTaskStepSuccess: null,
                approveTaskStepFailure: action.payload,
            };

        case workforceTypes.UPDATE_TASK_SALES_ORDER_REQUEST:
            return {
                ...state,
                updatingTaskSalesOrder: true,
                updateTaskSalesOrderSuccess: null,
                updateTaskSalesOrderFailure: null,
            };
        case workforceTypes.UPDATE_TASK_SALES_ORDER_SUCCESS:
            return {
                ...state,
                updatingTaskSalesOrder: false,
                updateTaskSalesOrderSuccess: action.payload,
                updateTaskSalesOrderFailure: null,
            };
        case workforceTypes.UPDATE_TASK_SALES_ORDER_FAILURE:
            return {
                ...state,
                updatingTaskSalesOrder: false,
                updateTaskSalesOrderSuccess: null,
                updateTaskSalesOrderFailure: action.payload,
            };

        case workforceTypes.FETCH_SINGLE_TASK_REQUEST:
            return {
                ...state,
                fetchingSingleTask: true,
                fetchSingleTaskSuccess: null,
                fetchSingleTaskFailure: null,
            };
        case workforceTypes.FETCH_SINGLE_TASK_SUCCESS:
            return {
                ...state,
                fetchingSingleTask: false,
                fetchSingleTaskSuccess: action.payload,
                fetchSingleTaskFailure: null,
            };
        case workforceTypes.FETCH_SINGLE_TASK_FAILURE:
            return {
                ...state,
                fetchingSingleTask: false,
                fetchSingleTaskSuccess: null,
                fetchSingleTaskFailure: action.payload,
            };

        case workforceTypes.FETCH_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingTaskList: true,
                fetchTaskListSuccess: null,
                fetchTaskListFailure: null,
            };
        case workforceTypes.FETCH_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingTaskList: false,
                fetchTaskListSuccess: action.payload,
                fetchTaskListFailure: null,
            };
        case workforceTypes.FETCH_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingTaskList: false,
                fetchTaskListSuccess: null,
                fetchTaskListFailure: action.payload,
            };

        case workforceTypes.FETCH_AI_AGENTS_REQUEST:
            return {
                ...state,
                fetchingAiAgents: true,
                fetchAiAgentSuccess: null,
                fetchAiAgentFailure: null,
            };
        case workforceTypes.FETCH_AI_AGENTS_SUCCESS:
            return {
                ...state,
                fetchingAiAgents: false,
                fetchAiAgentSuccess: action.payload,
                fetchAiAgentFailure: null,
            };
        case workforceTypes.FETCH_AI_AGENTS_FAILURE:
            return {
                ...state,
                fetchingAiAgents: false,
                fetchAiAgentSuccess: null,
                fetchAiAgentFailure: action.payload,
            };

        case workforceTypes.FETCH_AI_TEAMMATES_REQUEST:
            return {
                ...state,
                fetchingAiTeammates: true,
                fetchAiTeammatesSuccess: null,
                fetchAiTeammatesFailure: null,
            };
        case workforceTypes.FETCH_AI_TEAMMATES_SUCCESS:
            return {
                ...state,
                fetchingAiTeammates: false,
                fetchAiTeammatesSuccess: action.payload,
                fetchAiTeammatesFailure: null,
            };
        case workforceTypes.FETCH_AI_TEAMMATES_FAILURE:
            return {
                ...state,
                fetchingAiTeammates: false,
                fetchAiTeammatesSuccess: null,
                fetchAiTeammatesFailure: action.payload,
            };

        case workforceTypes.CREATE_AI_TEAMMATE_REQUEST:
            return {
                ...state,
                creatingAiTeammate: true,
                createAiTeammateSuccess: null,
                createAiTeammateFailure: null,
            };
        case workforceTypes.CREATE_AI_TEAMMATE_SUCCESS:
            return {
                ...state,
                creatingAiTeammate: false,
                createAiTeammateSuccess: action.payload,
                createAiTeammateFailure: null,
            };
        case workforceTypes.CREATE_AI_TEAMMATE_FAILURE:
            return {
                ...state,
                creatingAiTeammate: false,
                createAiTeammateSuccess: null,
                createAiTeammateFailure: action.payload,
            };
        default:
            return state;
    }
};
